import {
  useTranslate,
  IResourceComponentsProps,
  useNavigation,
  CrudFilters,
  HttpError,
} from "@pankod/refine-core"
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  TagField,
  ShowButton,
  EmailField,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Card,
} from "@pankod/refine-antd"
import { green } from "@ant-design/colors"
import { ICustomer, ICustomerFilterVariables } from "interfaces"

const { Title } = Typography

export const CustomerList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { tableProps, sorter, searchFormProps } = useTable<
    ICustomer,
    HttpError,
    ICustomerFilterVariables
  >({
    initialSorter: [
      {
        field: "updated_at",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { q, email, phone } = params

      filters.push(
        {
          field: "q",
          operator: "eq",
          value: q,
        },
        {
          field: "phone",
          operator: "eq",
          value: phone,
        },
        {
          field: "email",
          operator: "eq",
          value: email,
        }
      )

      return filters
    },
  })

  const { show } = useNavigation()

  return (
    <List>
      <Row gutter={[12, 0]}>
        <Col span={5}>
          <Card size="small">
            <Title level={5}>Filters</Title>
            <Form {...searchFormProps}>
              <Form.Item name="q">
                <Input placeholder="Customer name" />
              </Form.Item>
              <Form.Item name="phone">
                <Input placeholder="Phone" />
              </Form.Item>
              <Form.Item name="email">
                <Input placeholder="Email" />
              </Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                style={{ width: "100%" }}
              >
                Search
              </Button>
            </Form>
          </Card>
        </Col>
        <Col span={19}>
          <Table {...tableProps} rowKey="id" size="small">
            <Table.Column<ICustomer>
              dataIndex="name"
              key="name"
              title={t("customers.fields.name")}
              render={(value: string, record) => (
                <TextField
                  value={value}
                  onClick={() => show("customers", record.id)}
                  style={{ color: green[4], cursor: "pointer" }}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("name", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="email"
              key="email"
              title={t("customers.fields.email")}
              render={(value) => <EmailField value={value} />}
              defaultSortOrder={getDefaultSortOrder("email", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="currency"
              key="currency"
              title={t("customers.fields.currency")}
              render={(value) => <TagField value={value} />}
              defaultSortOrder={getDefaultSortOrder("currency", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="created_at"
              key="created_at"
              title={t("customers.fields.created_at")}
              render={(value) => <DateField value={value} format="LLL" />}
              defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
              sorter
            />
            <Table.Column<ICustomer>
              title={t("table.actions")}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                  <ShowButton hideText size="small" recordItemId={record.id} />
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
    </List>
  )
}
