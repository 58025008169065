import { IResourceComponentsProps, useShow } from "@pankod/refine-core"
import { Col, Row, Show, Space, Typography } from "@pankod/refine-antd"
import { IEmployee } from "interfaces"
import { formatCurrency } from "utils/format-currency"
import moment from "moment"
import { Pie } from "@ant-design/plots"

const { Title, Text } = Typography

export const EmployeeShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IEmployee>()
  const { data, isLoading } = queryResult

  const record = data?.data

  const attendance = record?.attendance

  const attendanceData = [
    { type: "Present", value: attendance?.totalPresentDays ?? 0 },
    { type: "Absent", value: attendance?.totalAbsentDays ?? 0 },
    // { type: "Total", value: attendance?.totalWorkingDays ?? 0 },
  ]

  return (
    <Show isLoading={isLoading} title={record?.name}>
      <Row gutter={[16, 16]}>
        <Col xs={8}>
          <Space direction="vertical" size={25}>
            <Space direction="vertical">
              <Text strong>Basic Salary</Text>
              <Text>{formatCurrency(record?.basic_salary, "NPR")}</Text>
            </Space>

            <Space direction="vertical">
              <Text strong>Birth Date</Text>
              <Text>{moment(record?.date_of_birth).format("YYYY MMM DD")}</Text>
            </Space>

            <Space direction="vertical">
              <Text strong>Department</Text>
              <Text>{record?.department}</Text>
            </Space>
          </Space>
        </Col>
        <Col xs={16}>
          <Title level={5}>Attendance</Title>
          <Text>
            From: <strong>{attendance?.start_bs}</strong>, To:{" "}
            <strong>{attendance?.end_bs}</strong>{" "}
          </Text>
          <Pie
            data={attendanceData}
            angleField="value"
            colorField="type"
            radius={150}
          />
        </Col>
      </Row>
    </Show>
  )
}
