import { IAccountType, ICoa } from "interfaces"

import {
  Checkbox,
  Col,
  Edit,
  Form,
  Input,
  Row,
  Select,
  useForm,
} from "@pankod/refine-antd"
import {
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@pankod/refine-core"
import { humanize } from "utils/humanize"

export const CoaEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<ICoa>()

  const { data: accountTypes } = useList<IAccountType>({
    resource: "account-types",
  })

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        labelAlign="left"
      >
        <Row>
          <Col xs={24} md={12} lg={10}>
            <Form.Item
              label={t("coas.fields.account_type")}
              name="account_type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select showSearch>
                {accountTypes?.data.map((accountType) => (
                  <>
                    <Select.OptGroup label={humanize(accountType.group_name)}>
                      {accountType.account_types.map((accType) => (
                        <Select.Option value={accType.id}>
                          {accType.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("coas.fields.name")}
              name="name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={t("coas.fields.description")} name="description">
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              label={t("coas.fields.is_enabled")}
              name="is_enabled"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  )
}
