import { Button } from "@pankod/refine-antd"
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"
import { green, red } from "@ant-design/colors"

export const StatusButton: React.FC<{ status: boolean }> = ({ status }) => {
  return (
    <Button
      type="text"
      icon={
        status ? (
          <CheckCircleOutlined style={{ color: green[4] }} />
        ) : (
          <CloseCircleOutlined style={{ color: red[4] }} />
        )
      }
      style={{
        color: status ? green[4] : red[4],
        backgroundColor: status ? green[0] : red[0],
        cursor: "default",
      }}
    >
      Server
    </Button>
  )
}
