import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core"
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  EmailField,
  Card,
} from "@pankod/refine-antd"
import { IMail } from "interfaces"

export const MailList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { tableProps, sorter } = useTable<IMail>({
    resource: "notifications/mails",
    initialSorter: [
      {
        field: "updated_at",
        order: "desc",
      },
    ],
    syncWithLocation: true,
  })

  return (
    <List title={t("mails.titles.list")}>
      <Table
        {...tableProps}
        size="small"
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ margin: "0 3rem" }}>
              <Card>
                <div dangerouslySetInnerHTML={{ __html: record.html }} />
              </Card>
            </div>
          ),
          rowExpandable: (record) => record.html !== "",
        }}
      >
        {/* <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value: string) => <TextField value={value?.slice(19)} />}
          fixed="left"
        /> */}

        <Table.Column
          dataIndex="subject"
          key="subject"
          title={t("mails.fields.subject")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("subject", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="from"
          key="from"
          title={t("mails.fields.from")}
          render={(value) => <EmailField value={value} />}
          defaultSortOrder={getDefaultSortOrder("from", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="to"
          key="to"
          title={t("mails.fields.to")}
          render={(value) => <EmailField value={value} />}
          defaultSortOrder={getDefaultSortOrder("to", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title={t("mails.fields.created_at")}
          render={(value) => (
            <DateField value={value} format={"hh:mm A, DD/MM/YYYY"} />
          )}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />
      </Table>
    </List>
  )
}
