import { RegisterEmployeeButton } from "components/attendance"
import { AttendanceDeviceSettingButton } from "components/attendance/AttendanceDeviceSettingButton"
import { StatusButton } from "components/attendance/StatusButton"
import useMqtt from "hooks/useMqtt"

import { blue, green, red } from "@ant-design/colors"
import {
  BooleanField,
  Card,
  Col,
  DateField,
  DatePicker,
  Form,
  List,
  Row,
  Select,
  Space,
  Statistic,
  Table,
  TextField,
  getDefaultSortOrder,
  useSelect,
  useTable,
} from "@pankod/refine-antd"
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useGetIdentity,
  useInvalidate,
  useNavigation,
  useNotification,
  useTranslate,
} from "@pankod/refine-core"

import { EmployeeDeleteButton } from "components/attendance/EmployeeDeleteButton"
import dayjs from "dayjs"
import { useEffect } from "react"
import { dateStringify } from "utils/date"
import constant, { mqttConfig } from "../../../constants/mqtt"
import { IAttendance, IEmployee } from "../../../interfaces"

import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"

export const AttendanceList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { show } = useNavigation()

  const _date = new Date()
  const date = dateStringify(_date.toISOString())

  const { data: identity } = useGetIdentity()

  const client_id = identity?.organization?.client_id

  const { tableProps, sorter, searchFormProps } = useTable<
    IAttendance,
    HttpError,
    { date: string }
  >({
    resource: "attendances",
    initialSorter: [
      {
        field: "updated_at",
        order: "desc",
      },
    ],
    initialFilter: [
      {
        field: "date",
        operator: "eq",
        value: date,
      },
    ],
    onSearch: ({ date }) => {
      const attendanceFilters: CrudFilters = []

      const _date = new Date(date).toISOString()

      attendanceFilters.push({
        field: "date",
        operator: "eq",
        value: date ? _date : undefined,
      })

      return attendanceFilters
    },
  })

  const {
    tableProps: attendanceTableProps,
    searchFormProps: attendanceSearchFormProps,
  } = useTable<
    IAttendance,
    HttpError,
    { employee_id: string; fromDate: string; toDate: string }
  >({
    resource: "attendances",
    initialSorter: [
      {
        field: "updated_at",
        order: "desc",
      },
    ],
    initialFilter: [
      {
        field: "date",
        operator: "eq",
        value: date,
      },
    ],
    onSearch: ({ employee_id, fromDate, toDate }) => {
      const attendanceFilters: CrudFilters = []

      const _fromDate = new Date(fromDate).toISOString()
      const _toDate = new Date(toDate).toISOString()

      attendanceFilters.push({
        field: "fromDate",
        operator: "eq",
        value: fromDate ? _fromDate : undefined,
      })

      attendanceFilters.push({
        field: "toDate",
        operator: "eq",
        value: toDate ? _toDate : undefined,
      })

      attendanceFilters.push({
        field: "employee_id",
        operator: "eq",
        value: employee_id ? employee_id : undefined,
      })

      return attendanceFilters
    },
  })

  const invalidate = useInvalidate()

  const { tableProps: regEmployeeTableProps, sorter: regEmployeeSorter } =
    useTable<IEmployee>({
      resource: "employees",
      initialSorter: [
        {
          field: "updated_at",
          order: "desc",
        },
      ],
    })

  const { selectProps: employeeSelectProps } = useSelect<IEmployee>({
    resource: "employees",
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
    fetchSize: 10,
  })

  const apiUrl = useApiUrl()

  const { data: stats, isLoading } = useCustom<any>({
    url: `${apiUrl}/employees/statistics`,
    method: "get",
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  })

  const notification = useNotification()

  const { isConnected, message, publish } = useMqtt(
    mqttConfig.url,
    mqttConfig.options,
    constant.TOPIC
  )

  useEffect(() => {
    if (message) {
      if (message.id === client_id) {
        switch (message.st) {
          case "SUCCESS":
            if (message.t === "INFO") {
              notification?.open?.({
                type: "success",
                message: "Info",
                description: message.msg,
              })
            } else if (message.t === "D") {
              if (message.loc !== "AT_T" && message.loc !== "AT_C") {
                notification?.open?.({
                  type: "success",
                  message: "Data",
                  description: message.msg,
                })
              } else {
                invalidate({
                  resource: "attendances",
                  invalidates: ["list"],
                })
              }
            } else {
              notification?.open?.({
                type: "success",
                message: "Success",
                description: message.msg,
              })
            }
            break
          case "FAIL":
            notification?.open?.({
              type: "error",
              message: "Error",
              description: message.msg,
            })
            break

          case "ATTENTION":
            notification?.open?.({
              type: "error",
              message: "Attention",
              description: message.msg,
            })
            break

          default:
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message])

  return (
    <>
      <List
        headerButtons={() => (
          <>
            <StatusButton status={isConnected} />
            <AttendanceDeviceSettingButton
              status={isConnected}
              publish={publish}
            />
          </>
        )}
      >
        {stats?.data && (
          <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
            <Col span={8}>
              <Card
                loading={isLoading}
                style={{ backgroundColor: blue[1] }}
                hoverable
              >
                <Statistic
                  title="Total Employees"
                  value={stats.data.totalEmployees}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card
                loading={isLoading}
                style={{ backgroundColor: green[1] }}
                hoverable
              >
                <Statistic
                  title="Total Present Employees"
                  value={stats.data.totalPresentEmployees}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card
                loading={isLoading}
                style={{ backgroundColor: red[1] }}
                hoverable
              >
                <Statistic
                  title="Total Absent Employees"
                  value={stats.data.totalAbsentEmployees}
                />
              </Card>
            </Col>
          </Row>
        )}
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Table
              {...tableProps}
              title={() => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Quick Look</p>
                  <Form {...searchFormProps} initialValues={{ date }}>
                    <Form.Item
                      name="date"
                      getValueProps={(value) => {
                        return {
                          value: value ? dayjs(value) : "",
                        }
                      }}
                      noStyle
                    >
                      <DatePicker
                        onChange={() => {
                          searchFormProps.form?.submit()
                        }}
                      />
                    </Form.Item>
                  </Form>
                </div>
              )}
              size="small"
              rowKey="id"
            >
              <Table.Column<IAttendance>
                dataIndex={["employee", "name"]}
                key="name"
                title={t("attendances.fields.employee.name")}
                render={(value: string, record) => (
                  <TextField
                    value={value}
                    onClick={() => show("attendances", record.employee.id)}
                    style={{ cursor: "pointer", color: "#1890ff" }}
                  />
                )}
                defaultSortOrder={getDefaultSortOrder("name", sorter)}
                sorter
              />
              <Table.Column<IAttendance>
                dataIndex="date"
                key="date"
                title={t("attendances.fields.date")}
                render={(value: string) => <DateField value={value} />}
                defaultSortOrder={getDefaultSortOrder("date", sorter)}
                sorter
              />
              <Table.Column<IAttendance>
                dataIndex="check_in"
                key="check_in"
                title={t("attendances.fields.check_in")}
                render={(value: string, record) => (
                  <span>
                    <DateField value={value} format="hh:mm A" />{" "}
                    <TextField value={record.check_in_through} />
                  </span>
                )}
                defaultSortOrder={getDefaultSortOrder("check_in", sorter)}
                sorter
              />
              <Table.Column<IAttendance>
                dataIndex="check_out"
                key="check_out"
                title={t("attendances.fields.check_out")}
                render={(value: string, record) => (
                  <span>
                    <DateField value={value} format="hh:mm A" />{" "}
                    <TextField value={record.check_out_through} />
                  </span>
                )}
                defaultSortOrder={getDefaultSortOrder("check_out", sorter)}
                sorter
              />
            </Table>
          </Col>
          <Col xs={24} sm={12}>
            <Table
              {...regEmployeeTableProps}
              title={() => <p>Employees</p>}
              size="small"
              rowKey="id"
            >
              <Table.Column<IEmployee>
                dataIndex="name"
                key="name"
                title={t("employees.fields.name")}
                render={(value: string, record) => (
                  <TextField
                    value={value}
                    onClick={() => show("employees", record.id)}
                    style={{ cursor: "pointer" }}
                  />
                )}
                defaultSortOrder={getDefaultSortOrder(
                  "name",
                  regEmployeeSorter
                )}
                sorter
              />
              <Table.Column<IEmployee>
                dataIndex="is_thumb_registered"
                key="is_thumb_registered"
                title={t("employees.fields.is_thumb_registered")}
                render={(value: string) => (
                  <BooleanField
                    value={value}
                    trueIcon={
                      <CheckCircleOutlined style={{ color: green[6] }} />
                    }
                    falseIcon={
                      <CloseCircleOutlined style={{ color: red[6] }} />
                    }
                  />
                )}
                align="center"
                defaultSortOrder={getDefaultSortOrder(
                  "is_thumb_registered",
                  regEmployeeSorter
                )}
                sorter
              />
              <Table.Column<IEmployee>
                dataIndex="is_card_registered"
                key="is_card_registered"
                title={t("employees.fields.is_card_registered")}
                render={(value: string) => (
                  // <TextField value={value ? "Yes" : "No"} />
                  <BooleanField
                    value={value}
                    trueIcon={
                      <CheckCircleOutlined style={{ color: green[6] }} />
                    }
                    falseIcon={
                      <CloseCircleOutlined style={{ color: red[6] }} />
                    }
                  />
                )}
                align="center"
                defaultSortOrder={getDefaultSortOrder(
                  "is_card_registered",
                  regEmployeeSorter
                )}
                sorter
              />
              <Table.Column<IEmployee>
                title="Action"
                key="action"
                align="center"
                render={(value: string, record) => (
                  <Space>
                    <RegisterEmployeeButton
                      record={record}
                      status={isConnected}
                      message={message}
                      publish={publish}
                    />

                    <EmployeeDeleteButton
                      record={record}
                      status={isConnected}
                      message={message}
                      publish={publish}
                    />
                  </Space>
                )}
              />
            </Table>
          </Col>
        </Row>
        <Table
          {...attendanceTableProps}
          title={() => (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Attendance</p>
              <Form {...attendanceSearchFormProps} initialValues={{ date }}>
                <Space>
                  <Form.Item name="employee_id" noStyle>
                    <Select
                      showSearch
                      {...employeeSelectProps}
                      onChange={() => {
                        attendanceSearchFormProps.form?.submit()
                      }}
                      placeholder="Select Employee"
                    />
                  </Form.Item>
                  <Form.Item
                    name="fromDate"
                    getValueProps={(value) => {
                      return {
                        value: value ? dayjs(value) : "",
                      }
                    }}
                    noStyle
                  >
                    <DatePicker
                      onChange={() => {
                        attendanceSearchFormProps.form?.submit()
                      }}
                      placeholder="From Date"
                    />
                  </Form.Item>
                  <Form.Item
                    name="toDate"
                    getValueProps={(value) => {
                      return {
                        value: value ? dayjs(value) : "",
                      }
                    }}
                    noStyle
                  >
                    <DatePicker
                      onChange={() => {
                        attendanceSearchFormProps.form?.submit()
                      }}
                      placeholder="To Date"
                    />
                  </Form.Item>
                </Space>
              </Form>
            </div>
          )}
          size="small"
          rowKey="id"
        >
          <Table.Column<IAttendance>
            dataIndex={["employee", "name"]}
            key="name"
            title={t("attendances.fields.employee.name")}
            render={(value: string, record) => (
              <TextField
                value={value}
                onClick={() => show("attendances", record.employee.id)}
                style={{ cursor: "pointer", color: "#1890ff" }}
              />
            )}
            defaultSortOrder={getDefaultSortOrder("name", sorter)}
            sorter
          />
          <Table.Column<IAttendance>
            dataIndex="date"
            key="date"
            title={t("attendances.fields.date")}
            render={(value: string) => <DateField value={value} />}
            defaultSortOrder={getDefaultSortOrder("date", sorter)}
            sorter
          />
          <Table.Column<IAttendance>
            dataIndex="check_in"
            key="check_in"
            title={t("attendances.fields.check_in")}
            render={(value: string, record) => (
              <span>
                <DateField value={value} format="hh:mm A" />{" "}
                <TextField value={record.check_in_through} />
              </span>
            )}
            defaultSortOrder={getDefaultSortOrder("check_in", sorter)}
            sorter
          />
          <Table.Column<IAttendance>
            dataIndex="check_out"
            key="check_out"
            title={t("attendances.fields.check_out")}
            render={(value: string, record) => (
              <span>
                <DateField value={value} format="hh:mm A" />{" "}
                <TextField value={record.check_out_through} />
              </span>
            )}
            defaultSortOrder={getDefaultSortOrder("check_out", sorter)}
            sorter
          />
        </Table>
      </List>
    </>
  )
}
