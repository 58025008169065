import {
  Button,
  Col,
  Edit,
  Form,
  Input,
  InputNumber,
  RefreshButton,
  Row,
  Typography,
  useForm,
} from "@pankod/refine-antd"
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core"
import { IProvidentFund } from "interfaces"

const { Title } = Typography

export const ProvidentFundEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<IProvidentFund>({
    redirect: false,
  })

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      headerButtons={({ defaultButtons: _ }) => <RefreshButton />}
    >
      <Form {...formProps} layout="vertical">
        <Row>
          <Col xs={24} md={18} lg={16}>
            <Row gutter={[32, 0]}>
              <Col xs={24} md={12}>
                <Form.Item label={t("tds.fields.name")} name="name">
                  <Input placeholder="Enter name" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("tds.fields.description")}
                  name="description"
                >
                  <Input placeholder="Enter description" />
                </Form.Item>
              </Col>
            </Row>
            <Title level={5}>Settings</Title>
            <Form.List name="settings">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Row gutter={[16, 0]} key={field.key}>
                      <Col xs={8}>
                        <Form.Item
                          label={t("tds.fields.from_amount")}
                          name={[field.name, "from_amount"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter amount",
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            placeholder="Enter amount"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={8}>
                        <Form.Item
                          label={t("tds.fields.to_amount")}
                          name={[field.name, "to_amount"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter amount",
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            placeholder="Enter amount"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={4}>
                        <Form.Item
                          label={t("tds.fields.percentage")}
                          name={[field.name, "percentage"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter percentage",
                            },
                          ]}
                        >
                          <Input placeholder="Enter percentage" />
                        </Form.Item>
                      </Col>
                      <Col xs={4}>
                        <Form.Item
                          label={t("tds.fields.action")}
                          name={[field.name, "action"]}
                        >
                          <Button
                            type="primary"
                            danger
                            onClick={() => remove(field.name)}
                          >
                            Delete
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Button onClick={() => add()}>Add</Button>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </Form>
    </Edit>
  )
}
