import { Button, Drawer, Icons } from "@pankod/refine-antd"
import dayjs from "dayjs"
import { IPayroll } from "interfaces"
import { TransactionCreate } from "pages/banking/transactions"
import { useState } from "react"

interface IPayrollPaymentButtonProps {
  record?: IPayroll | undefined
}

const PayrollPaymentButton: React.FC<IPayrollPaymentButtonProps> = ({
  record,
}) => {
  // const { modalProps, show, close } = useModal()
  const [open, setOpen] = useState(false)

  // const { show } = useNavigation()

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  if (!record) return null

  return (
    <>
      <Button
        icon={<Icons.DollarOutlined style={{ fontSize: 18 }} />}
        onClick={showDrawer}
      >
        Record Payment
      </Button>
      <Drawer title="Payment" visible={open} onClose={onClose} width={"40vw"}>
        <TransactionCreate
          // redirect={show("payrolls", record.id)}
          resource="payrolls/payment"
          initialValues={{
            ...record,
            payroll_ids: [record.id],
            transaction_type: "Expense",
            amount: record.total_payable,
            date: record.payment_date,
            description: `Payment for payroll of ${dayjs(
              record.from_date
            ).format("YYYY-MM-DD")} to ${dayjs(record.to_date).format(
              "YYYY-MM-DD"
            )}`,
            account_id: "",
            category_id: "",
          }}
          breadcrumb={false}
          title="Add Payment"
          fullWidth={true}
          // title={false}
          // goBack={false}
        />
      </Drawer>
    </>
  )
}

export default PayrollPaymentButton
