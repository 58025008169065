import { Col, Row, Typography } from "@pankod/refine-antd"
import { CashFlow } from "./CashFlow"
import { ExpenseByCategory } from "./ExpenseByCategory"
import { IncomeByCategory } from "./IncomeByCategory"

import { Payable } from "./Payable"
import { Receivable } from "./Receivable"

const { Title } = Typography

export const Dashboard = () => {
  return (
    <div style={{ margin: "1em" }}>
      <Title level={4} style={{ marginLeft: "0.6em" }}>
        Dashboard
      </Title>
      <Row gutter={[24, 8]} style={{ margin: "0" }}>
        <Col xs={24} sm={12}>
          <Receivable />
        </Col>
        <Col xs={24} sm={12}>
          <Payable />
        </Col>
      </Row>
      <Row gutter={[24, 18]} style={{ margin: "0" }}>
        <Col xs={24}>
          <CashFlow />
        </Col>
      </Row>
      <Row gutter={[24, 8]} style={{ margin: "0" }}>
        <Col xs={24} sm={12}>
          <ExpenseByCategory />
        </Col>
        <Col xs={24} sm={12}>
          <IncomeByCategory />
        </Col>
      </Row>
    </div>
  )
}
