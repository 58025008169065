import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core"
import {
  Col,
  Edit,
  Form,
  Input,
  RefreshButton,
  Row,
  Select,
  useForm,
  useSelect,
  Typography,
  Checkbox,
} from "@pankod/refine-antd"

import { IAccount, ICategory, IDefault, ITax } from "interfaces"

const { Title } = Typography

export const DefaultEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps, queryResult } = useForm<IDefault>({
    redirect: false,
  })

  const { selectProps: accountSelectProps } = useSelect<IAccount>({
    resource: "accounts",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data?.account_id,
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
  })

  const { selectProps: taxSelectProps } = useSelect<ITax>({
    resource: "tax",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data?.tax_id,
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
  })

  const { selectProps: incomeCategorySelectProps } = useSelect<ICategory>({
    resource: "categories",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data?.income_category_id,
    filters: [
      {
        field: "type",
        operator: "eq",
        value: "income",
      },
    ],
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
  })

  const { selectProps: expenseCategorySelectProps } = useSelect<ICategory>({
    resource: "categories",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data?.expense_category_id,
    filters: [
      {
        field: "type",
        operator: "eq",
        value: "expense",
      },
    ],
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
  })

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      headerButtons={({ defaultButtons: _ }) => <RefreshButton />}
    >
      <Form {...formProps} layout="vertical">
        <Row>
          <Col xs={24} md={18} lg={16}>
            <Title level={5}>General</Title>
            <Row gutter={[32, 0]}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("default.fields.account_id")}
                  name="account_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select an account",
                    },
                  ]}
                >
                  <Select
                    {...accountSelectProps}
                    showSearch
                    allowClear
                    placeholder="Select Account"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("default.fields.currency")}
                  name="currency"
                  rules={[
                    {
                      required: true,
                      message: "Please select currency!",
                    },
                  ]}
                >
                  <Select
                    options={[
                      { label: "American Dollar", value: "USD" },
                      { label: "European Dollar", value: "EUR" },
                      { label: "Nepali Rupees", value: "NPR" },
                    ]}
                    placeholder="Select currency"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("default.fields.tax")}
                  name="tax_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select tax!",
                    },
                  ]}
                >
                  <Select {...taxSelectProps} placeholder="Select tax" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("default.fields.payment_method")}
                  name="payment_method"
                  rules={[
                    {
                      required: true,
                      message: "Please select payment method!",
                    },
                  ]}
                >
                  <Select
                    options={[
                      { label: "Bank Transfer", value: "bank_transfer" },
                      { label: "Cash", value: "cash" },
                      { label: "Chequee", value: "chequee" },
                    ]}
                    placeholder="Select payment_method"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("default.fields.pagination")}
                  name="pagination"
                  rules={[
                    {
                      required: true,
                      message: "Please select pagination number!",
                    },
                  ]}
                >
                  <Select
                    options={[
                      { label: "10", value: 10 },
                      { label: "25", value: 25 },
                      { label: "50", value: 50 },
                      { label: "100", value: 100 },
                    ]}
                    placeholder="Select pagination"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Title level={5}>Categories</Title>
            <Row gutter={[32, 0]}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("default.fields.income_category_id")}
                  name="income_category_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select income category!",
                    },
                  ]}
                >
                  <Select
                    {...incomeCategorySelectProps}
                    placeholder="Select income category"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("default.fields.expense_category_id")}
                  name="expense_category_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select expense category!",
                    },
                  ]}
                >
                  <Select
                    {...expenseCategorySelectProps}
                    placeholder="Select expense category"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Title level={5}>Inventories</Title>
            <Row gutter={[32, 0]}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("default.fields.manage_inventory")}
                  name="manage_inventory"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Edit>
  )
}
