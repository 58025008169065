import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
  useApiUrl,
  useUpdate,
  useRouterContext,
} from "@pankod/refine-core"
import {
  Show,
  Typography,
  Tag,
  Button,
  Icons,
  Card,
  Space,
  Row,
  Col,
  Timeline,
  Table,
} from "@pankod/refine-antd"
import Helmet from "react-helmet"
import { ISaleReturn, ILedgerEntry } from "interfaces"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import { humanize } from "utils/humanize"
import moment from "moment"
import SaleReturnPaymentButton from "components/sale/SaleReturnPaymentButton"
import { Attachments } from "components/Attachments"
import { StatusTagField } from "components/status/StatusTagField"

// import SaleReturnPaymentButton from "components/sale/SaleReturnPaymentButton"

const { Title, Text } = Typography

export const SaleReturnShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { queryResult } = useShow<ISaleReturn>()

  const { data, isLoading } = queryResult
  const record = data?.data

  const apiUrl = useApiUrl()

  const { mutate } = useUpdate<ISaleReturn>()

  const handleConvertSent = () => {
    mutate({
      resource: "sale-returns",
      id: record?.id!,
      values: {
        status: "sent",
        events: [
          ...record?.events!,
          {
            status: "sent",
          },
        ],
      },
    })
  }

  const { Link } = useRouterContext()

  const ledgerEntries: ILedgerEntry[] = record?.ledger_entries
    ? [
        ...record?.ledger_entries,
        {
          id: "total",
          debit: record?.ledger_entries?.reduce(
            (acc, cur) => acc + cur.debit,
            0
          ),
          credit: record?.ledger_entries?.reduce(
            (acc, cur) => acc + cur.credit,
            0
          ),
        },
      ]
    : []

  return (
    <>
      <Helmet>
        <title>{`Sale Return: SR-${record?.invoice_number}`}</title>
      </Helmet>
      <Show
        isLoading={isLoading}
        headerButtons={({ defaultButtons }) => (
          <>
            <StatusTagField status={record?.status} />
            {defaultButtons}
            <Button
              href={`${apiUrl}/sale-returns/pdf/${record?.id}`}
              icon={<Icons.PrinterOutlined style={{ fontSize: 18 }} />}
            >
              Print
            </Button>
          </>
        )}
        title={`Sale Return: SR-${record?.sale_return_number}`}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            {record?.status === "draft" && (
              <Card
                style={{
                  width: "100%",
                  marginBottom: 18,
                }}
              >
                <Space size="middle" wrap={true}>
                  <div>
                    <Title level={4}>Mark as Sent</Title>
                    <Text>
                      Sale return has been created. Convert the invoice to the
                      sent status to record payment
                    </Text>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <Button onClick={handleConvertSent}>Mark as sent.</Button>
                  </div>
                </Space>
              </Card>
            )}
            {record?.status === "sent" && (
              <Card
                style={{
                  width: "100%",
                  marginBottom: 18,
                }}
              >
                <Space size="middle" wrap={true}>
                  <div>
                    <Title level={4}>Record Payment</Title>
                    <Text>
                      This sale return invoice is in the sent status. You can
                      now record payment for this sale return invoice.
                    </Text>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <SaleReturnPaymentButton record={record} />
                  </div>
                </Space>
              </Card>
            )}

            {record?.status === "partially_paid" && (
              <Card
                style={{
                  width: "100%",
                  marginBottom: 18,
                }}
              >
                <Space size="middle" wrap={true}>
                  <div>
                    <Title level={4}>Make Due Payment</Title>
                    <Text>
                      This sale return invoice is in the paritially paid status.
                      You can now record payment for this sale return invoice.
                    </Text>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <SaleReturnPaymentButton record={record} />
                  </div>
                </Space>
              </Card>
            )}

            <section>
              <Space size="small" direction="vertical">
                <Title level={5}>Events</Title>
                <Timeline>
                  {record?.events?.map((event, index) => (
                    <Timeline.Item key={index}>
                      <Tag color="blue">{humanize(event.status)}</Tag>
                      <Text>
                        {moment(event.created_at).format("DD MMM YYYY")} -{" "}
                        {event.status === "draft" && (
                          <Text>
                            A sale return invoice is created by{" "}
                            <Text strong>
                              {record?.created_by?.name ?? "--"}
                            </Text>
                            {"."}
                          </Text>
                        )}
                        {event.status === "sent" && (
                          <Text>A sale return invoice is sent.</Text>
                        )}
                        {event.status === "payment" && (
                          <Text>A sale return invoice is paid.</Text>
                        )}
                      </Text>
                    </Timeline.Item>
                  ))}
                </Timeline>
              </Space>
            </section>
            <section>
              <Space size="small" direction="vertical">
                <Title level={5}>Payment</Title>
                <Text>
                  Due Amount: <Text strong>NPR {record?.due_amount}</Text>
                </Text>
                <Timeline>
                  {record?.payments?.map((payment, index) => (
                    <Timeline.Item
                      key={index}
                      dot={<Icons.CheckCircleOutlined />}
                    >
                      <Text>
                        {moment(payment.payment_date).format("DD MMM YYYY")} - A
                        payment for{" "}
                        <Text strong>NPR {payment.total_amount}</Text> was made
                        using{" "}
                        <Text strong>{payment.paid_through_account_name}</Text>.
                      </Text>
                      <br />
                      <Link
                        to={`/purchases/invoice-payments/edit/${payment.id}`}
                      >
                        Edit Payment
                      </Link>
                    </Timeline.Item>
                  ))}
                </Timeline>
              </Space>
            </section>
            <section>
              <Title level={5}>Journal</Title>
              <Table
                dataSource={ledgerEntries}
                columns={[
                  {
                    title: t("sale_returns.fields.coa_name"),
                    dataIndex: "coa",
                    key: "coa",
                    render: (_text, record, index) => (
                      <>
                        <Text>{record?.coa?.name}</Text>
                      </>
                    ),
                  },
                  {
                    title: t("sale_returns.fields.debit"),
                    dataIndex: "debit",
                    key: "debit",
                    render: (_text, record, index) => (
                      <>
                        <Text> {record?.debit?.toFixed(2)}</Text>
                      </>
                    ),
                  },
                  {
                    title: t("sale_returns.fields.credit"),
                    dataIndex: "credit",
                    key: "credit",
                    render: (_text, record, index) => (
                      <>
                        <Text> {record?.credit?.toFixed(2)}</Text>
                      </>
                    ),
                  },
                ]}
                pagination={false}
                size="small"
              />
            </section>
            <section>
              <br />
              <Attachments attachments={record?.attachments} />
            </section>
          </Col>
          <Col xs={24} sm={12}>
            {record?.id && (
              <div
                style={{
                  backgroundColor: "#f7f7f7",
                  display: "flex",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <Document
                  file={{
                    url: `${apiUrl}/sale-returns/pdf/${record?.id}`,
                    withCredentials: true,
                  }}
                  renderMode="canvas"
                  className="payment-pdf-section"
                >
                  <Page pageNumber={1} renderMode="canvas" width={560} />
                </Document>
              </div>
              // <div dangerouslySetInnerHTML={{ __html: record?.html }}></div>
            )}
          </Col>
        </Row>
      </Show>
    </>
  )
}
