import constant from "constants/mqtt"
import { useState } from "react"

import {
  DeleteOutlined,
  SettingOutlined,
  UndoOutlined,
  WifiOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons"
import {
  Button,
  Drawer,
  message,
  Popconfirm,
  Space,
  Tooltip,
  Typography,
} from "@pankod/refine-antd"

import { StatusButton } from "./StatusButton"
import { useGetIdentity } from "@pankod/refine-core"

const { Title } = Typography

interface PropType {
  status: boolean
  publish: (topic: string, message: string) => void
}

export const AttendanceDeviceSettingButton: React.FC<PropType> = ({
  status,
  publish,
}) => {
  const { data: identity } = useGetIdentity()

  const client_id = identity?.organization?.client_id

  const [drawerOpen, setDrawerOpen] = useState(false)

  const showDrawer = () => {
    setDrawerOpen(true)
  }

  const onClose = () => {
    setDrawerOpen(false)
  }

  return (
    <>
      <Tooltip title="Device Settings">
        <Button type="link" icon={<SettingOutlined />} onClick={showDrawer}>
          Settings
        </Button>
      </Tooltip>
      <Drawer
        title="Device Settings"
        placement="right"
        width={500}
        onClose={onClose}
        visible={drawerOpen}
        extra={
          <Space>
            <StatusButton status={status} />
          </Space>
        }
      >
        <Title level={5}>Basic Settings</Title>
        <Popconfirm
          title="Are you sure to reset wifi?"
          onConfirm={() => {
            if (client_id) {
              message.success("Resetting wifi")
              publish(constant.TOPIC, `${client_id}-RESET`)
            }
          }}
          onCancel={() => {
            message.error("Resetting wifi cancelled")
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            icon={<WifiOutlined />}
            style={{ width: "100%", textAlign: "left" }}
          >
            Reset Wifi
          </Button>
        </Popconfirm>
        <Popconfirm
          title="Are you sure to update device time?"
          onConfirm={() => {
            const date = new Date()
            // get hrs, mins, secs
            const hrs = date.getHours()
            const mins = date.getMinutes()
            const secs = date.getSeconds()

            // day, month, year, day of week
            const day = date.getDate()
            const month = date.getMonth() + 1
            let year: string | number = date.getFullYear()
            const dayOfWeek = date.getDay() + 1

            year = year.toString().slice(-2)

            const msg = `TIME-${hrs}-${mins}-${secs}-${day}-${month}-${year}-${dayOfWeek}`
            if (client_id) {
              publish(constant.TOPIC, `${client_id}-${msg}`)
            }
          }}
          onCancel={() => {
            message.error("Update time cancelled")
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            icon={<ClockCircleOutlined />}
            style={{ width: "100%", textAlign: "left", marginTop: 10 }}
          >
            Update Time
          </Button>
        </Popconfirm>
        <Popconfirm
          title="Are you sure to restart device?"
          onConfirm={() => {
            if (client_id) {
              message.success("Restart Device")
              publish(constant.TOPIC, `${client_id}-RESTART`)
            }
          }}
          onCancel={() => {
            message.error("Restart device cancelled")
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            icon={<UndoOutlined />}
            style={{ width: "100%", textAlign: "left", marginTop: 10 }}
          >
            Restart Device
          </Button>
        </Popconfirm>
        <Title
          level={5}
          style={{
            marginTop: 10,
          }}
        >
          Danger Zone
        </Title>
        <Popconfirm
          title="Are you sure to format device? This will delete all data."
          onConfirm={() => {
            if (client_id) {
              message.success("Format device")
              publish(constant.TOPIC, `${client_id}-FORMAT`)
            }
          }}
          onCancel={() => {
            message.error("Format device cancelled")
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            danger
            icon={<DeleteOutlined />}
            style={{ width: "100%", textAlign: "left" }}
          >
            Format
          </Button>
        </Popconfirm>
        <Title level={5} style={{ marginTop: 20 }}>
          Device Info
        </Title>
        <pre style={{}}>
          {`Device Name: Attendance Device\nMQTT URL: ${process.env.REACT_APP_MQTT_URL}\nMQTT Username: ${process.env.REACT_APP_MQTT_USERNAME}\nMQTT Password: ${process.env.REACT_APP_MQTT_PASSWORD}\nMQTT Port: ${process.env.REACT_APP_MQTT_PORT}\nMQTT Topic: ${constant.TOPIC}\n `}
        </pre>
      </Drawer>
    </>
  )
}
