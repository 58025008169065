import React, { useState } from "react";
import { useRouterContext } from "@pankod/refine-core";
import { Button, Image } from "@pankod/refine-antd";

export default function Header() {
  const { Link } = useRouterContext();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#fff",
        padding: "1rem 2rem",
      }}
    >
      <Link to="/" style={{ flex: 1 }}>
        <Image src="./refine.svg" preview={false} height={40} />
      </Link>
      {/* <div className="headerRight" style={{ display: "flex", gap: 10 }}>
        <div>
          <Button href="/#contact-us">Contact Us</Button>
        </div>
        <div>
          <Link to="/register">
            <Button type="default">Register</Button>
          </Link>
        </div>
        <div>
          <Button href="/login" type="primary">
            Login
          </Button>
        </div>
      </div> */}
      <div className="header-right">
        <div className="header-right__buttons">
          <Button href="/#contact-us">Contact Us</Button>
          <Link to="/register">
            <Button type="default">Register</Button>
          </Link>
          <Button href="/login" type="primary">
            Login
          </Button>
        </div>

        <div className="header-right__dropdown">
          <button
            className="header-right__toggle"
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg
              className="header-right__icon"
              fill="#000000"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>
            </svg>
          </button>
          {isOpen && (
            <div className="header-right__menu">
              <Button href="/#contact-us" type="link">
                Contact Us
              </Button>
              <Link to="/register">
                <Button type="link">Register</Button>
              </Link>
              <Button href="/login" type="link">
                Login
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
