import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
  useApiUrl,
  useUpdate,
  useRouterContext,
} from "@pankod/refine-core"
import {
  Show,
  Typography,
  Tag,
  Button,
  Icons,
  Card,
  Space,
  Row,
  Col,
  Timeline,
  Table,
  EditButton,
  RefreshButton,
} from "@pankod/refine-antd"
import Helmet from "react-helmet"
import { IBill, ILedgerEntry } from "interfaces"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import { humanize } from "utils/humanize"
import moment from "moment"
import PurchaseReturnButton from "components/purchase/PurchaseReturnButton"
import { Attachments } from "components/Attachments"
import BillPaymentButton from "components/purchase/PurchasePaymentButton"
import { StatusTagField } from "components/status/StatusTagField"

const { Title, Text } = Typography

export const BillShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { queryResult } = useShow<IBill>()

  const { data, isLoading } = queryResult
  const record = data?.data

  const apiUrl = useApiUrl()

  const { mutate } = useUpdate<IBill>()

  const handleConvertOpen = () => {
    mutate({
      resource: "bills",
      id: record?.id!,
      values: {
        status: "open",
        events: [
          ...record?.events!,
          {
            status: "open",
          },
        ],
      },
    })
  }

  const { Link } = useRouterContext()

  const ledgerEntries: ILedgerEntry[] = record?.ledger_entries
    ? [
        ...record?.ledger_entries,
        {
          id: "total",
          debit: record?.ledger_entries?.reduce(
            (acc, cur) => acc + cur.debit,
            0
          ),
          credit: record?.ledger_entries?.reduce(
            (acc, cur) => acc + cur.credit,
            0
          ),
        },
      ]
    : []

  return (
    <>
      <Helmet>
        <title>{`Bill: BILL-${record?.bill_number}`}</title>
      </Helmet>
      <Show
        isLoading={isLoading}
        headerButtons={({ defaultButtons }) => (
          <>
            <StatusTagField status={record?.status} />
            <EditButton />
            <RefreshButton />
            <PurchaseReturnButton record={record} />
            <Button
              href={`${apiUrl}/bills/pdf/${record?.id}`}
              icon={<Icons.PrinterOutlined style={{ fontSize: 18 }} />}
            >
              Print
            </Button>
          </>
        )}
        title={`Bill: Bill-${record?.bill_number}`}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            {record?.status === "draft" && (
              <Card
                style={{
                  width: "100%",
                  marginBottom: 18,
                }}
              >
                <Space size="middle" wrap={true}>
                  <div>
                    <Title level={4}>Convert to Open</Title>
                    <Text>
                      Bill has been created. Convert the bill to the open status
                      to record payment
                    </Text>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <Button onClick={handleConvertOpen}>
                      Convert to Open.
                    </Button>
                  </div>
                </Space>
              </Card>
            )}
            {record?.status === "open" && (
              <Card
                style={{
                  width: "100%",
                  marginBottom: 18,
                }}
              >
                <Space size="middle" wrap={true}>
                  <div>
                    <Title level={4}>Record Payment</Title>
                    <Text>
                      This bill is in the open status. You can now record
                      payment for this bill.
                    </Text>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <BillPaymentButton record={record} />
                  </div>
                </Space>
              </Card>
            )}
            {record?.status === "partially_paid" && (
              <Card
                style={{
                  width: "100%",
                  marginBottom: 18,
                }}
              >
                <Space size="middle" wrap={true}>
                  <div>
                    <Title level={4}>Make Due Payment</Title>
                    <Text>
                      This bill is partially paid. You can now record payment
                      for this bill.
                    </Text>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <BillPaymentButton record={record} />
                  </div>
                </Space>
              </Card>
            )}

            <section>
              <Space size="small" direction="vertical">
                <Title level={5}>Events</Title>
                <Timeline>
                  {record?.events?.map((event) => (
                    <Timeline.Item>
                      <Tag color="blue">{humanize(event.status)}</Tag>
                      <Text>
                        {moment(event.created_at).format("DD MMM YYYY")} -{" "}
                        {event.status === "draft" && (
                          <Text>
                            A bill created by{" "}
                            <Text strong>
                              {record?.created_by?.name ?? "--"}
                            </Text>
                            {"."}
                          </Text>
                        )}
                        {event.status === "open" && (
                          <Text>A bill is received.</Text>
                        )}
                        {event.status === "payment" && (
                          <Text>A bill is paid.</Text>
                        )}
                      </Text>
                    </Timeline.Item>
                  ))}
                </Timeline>
              </Space>
            </section>
            <section>
              <Space size="small" direction="vertical">
                <Title level={5}>Payment</Title>
                <Text>
                  Due Amount: <Text strong>NPR {record?.due_amount}</Text>
                </Text>
                <Timeline>
                  {record?.payments?.map((payment, index) => (
                    <Timeline.Item
                      key={index}
                      dot={<Icons.CheckCircleOutlined />}
                    >
                      <Text>
                        {moment(payment.payment_date).format("DD MMM YYYY")} - A
                        payment for{" "}
                        <Text strong>NPR {payment.total_amount}</Text> was made
                        using{" "}
                        <Text strong>{payment.paid_through_account_name}</Text>.
                      </Text>
                      <br />
                      <Link to={`/purchases/bill-payments/edit/${payment.id}`}>
                        Edit Payment
                      </Link>
                    </Timeline.Item>
                  ))}
                </Timeline>
              </Space>
            </section>
            <section>
              <Title level={5}>Journal</Title>
              <Table
                dataSource={ledgerEntries}
                columns={[
                  {
                    title: t("invoices.fields.coa_name"),
                    dataIndex: "coa",
                    key: "coa",
                    render: (_text, record, index) => (
                      <>
                        <Text>{record?.coa?.name}</Text>
                      </>
                    ),
                  },
                  {
                    title: t("invoices.fields.debit"),
                    dataIndex: "debit",
                    key: "debit",
                    render: (_text, record, index) => (
                      <>
                        <Text> {record?.debit?.toFixed(2)}</Text>
                      </>
                    ),
                  },
                  {
                    title: t("invoices.fields.credit"),
                    dataIndex: "credit",
                    key: "credit",
                    render: (_text, record, index) => (
                      <>
                        <Text> {record?.credit?.toFixed(2)}</Text>
                      </>
                    ),
                  },
                ]}
                pagination={false}
                size="small"
              />
            </section>
            <section>
              <br />
              <Attachments attachments={record?.attachments} />
            </section>
          </Col>
          <Col xs={24} sm={12}>
            {record?.id && (
              <div
                style={{
                  backgroundColor: "#f7f7f7",
                  display: "flex",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <Document
                  file={{
                    url: `${apiUrl}/bills/pdf/${record?.id}`,
                    withCredentials: true,
                  }}
                  renderMode="canvas"
                  className="payment-pdf-section"
                >
                  <Page pageNumber={1} renderMode="canvas" width={560} />
                </Document>
              </div>
              // <div dangerouslySetInnerHTML={{ __html: record?.html }}></div>
            )}
          </Col>
        </Row>
      </Show>
    </>
  )
}
