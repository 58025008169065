import React from "react"

import {
  AntdLayout,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography,
} from "@pankod/refine-antd"
import { useCreate } from "@pankod/refine-core"

const { Text, Title, Link } = Typography

export interface IForgotPasswordForm {
  username: string
}

export const ForgotPassword: React.FC = () => {
  const [form] = Form.useForm<IForgotPasswordForm>()

  const { mutate: reset } = useCreate<IForgotPasswordForm>()

  const onFinish = (values: IForgotPasswordForm) => {
    reset({
      resource: "users/forgot-password",
      values: {
        username: values.username,
      },
      successNotification(_data, _values, _resource) {
        return {
          type: "success",
          message: "Password reset link sent to your email",
        }
      },
    })
  }

  const CardTitle = (
    <Title level={3} className="title">
      Forgot Password
    </Title>
  )

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <img src="./refine.svg" alt="Refine Logo" height={30} />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<IForgotPasswordForm>
                layout="vertical"
                form={form}
                onFinish={(values) => onFinish(values)}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}
              >
                <Form.Item
                  name="username"
                  label="Phone/ Email"
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="Phone or Email" />
                </Form.Item>
                <Button type="primary" size="large" htmlType="submit" block>
                  Submit
                </Button>
              </Form>
              <div style={{ marginTop: 8 }}>
                <Text style={{ fontSize: 12 }}>
                  Don’t have an account?{" "}
                  <Link href="/register" style={{ fontWeight: "bold" }}>
                    Sign up
                  </Link>
                </Text>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  )
}
