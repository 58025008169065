import { IEmployee } from "interfaces"

import {
  Col,
  DatePicker,
  Edit,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Tabs,
  Typography,
  useForm,
} from "@pankod/refine-antd"
import dayjs from "dayjs"

import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core"

const { Title } = Typography

export const EmployeeEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<IEmployee>({
    successNotification: () => ({
      message: "Employee updated successfully",
      description: "Successful",
      type: "success",
    }),
  })

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Tabs>
          <Tabs.TabPane tab="Personal Information" key="1">
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={14}>
                <Title level={5}>Personal Information</Title>
                <Form.Item
                  label={t("employees.fields.name")}
                  name="name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Enter Name" />
                </Form.Item>
                <Row gutter={[16, 0]}>
                  <Col xs={12}>
                    <Form.Item
                      label={t("employees.fields.phone")}
                      name="phone"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Enter Phone" />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item label={t("employees.fields.email")} name="email">
                      <Input placeholder="Enter Email" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 0]}>
                  <Col xs={12}>
                    <Form.Item
                      label={t("employees.fields.tax_number")}
                      name="tax_number"
                    >
                      <Input placeholder="Enter Tax Number" />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      label={t("employees.fields.currency")}
                      name="currency"
                      rules={[{ required: true }]}
                    >
                      <Select
                        options={[{ id: "NPR", value: "Nepali Rupees" }]}
                        placeholder="Select Currency"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 0]}>
                  <Col xs={12}>
                    <Form.Item
                      label={t("employees.fields.gender")}
                      name="gender"
                    >
                      <Select
                        options={[
                          { value: "male", label: "Male" },
                          { value: "female", label: "Female" },
                          { value: "other", label: "Other" },
                        ]}
                        placeholder="Select gender"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      label={t("employees.fields.date_of_birth")}
                      name="date_of_birth"
                      getValueProps={(value) => {
                        return {
                          value: value ? dayjs(value) : "",
                        }
                      }}
                    >
                      <DatePicker style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>

                {/* Address */}
                <Title level={5}>Address</Title>
                <Form.Item label={t("employees.fields.address")} name="address">
                  <Input.TextArea placeholder="Enter Address" />
                </Form.Item>
                <Row gutter={[16, 0]}>
                  <Col xs={24} sm={12}>
                    <Form.Item label={t("employees.fields.city")} name="city">
                      <Input placeholder="Enter City" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label={t("employees.fields.postal_code")}
                      name="postal_code"
                    >
                      <Input placeholder="Enter Postal Code" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item label={t("employees.fields.state")} name="state">
                      <Input placeholder="Enter State" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label={t("employees.fields.country")}
                      name="country"
                    >
                      <Input placeholder="Enter Country" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Salary and Benefits" key="2">
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={14}>
                {/* Salary */}
                <Title level={5}>Salary</Title>
                <Row gutter={[16, 0]}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label={t("employees.fields.department")}
                      name="department"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter department" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label={t("employees.fields.basic_salary")}
                      name="basic_salary"
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        placeholder="Enter basic salary"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label={t("employees.fields.bank_account_number")}
                      name="bank_account_number"
                    >
                      <Input placeholder="Enter bank acc number" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label={t("employees.fields.bank_name")}
                      name="bank_name"
                    >
                      <Input placeholder="Enter bank name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label={t("employees.fields.bank_branch")}
                      name="bank_branch"
                    >
                      <Input placeholder="Enter bank branch" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label={t("employees.fields.bank_address")}
                      name="bank_address"
                    >
                      <Input placeholder="Enter bank address" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label={t("employees.fields.hire_date")}
                      name="hire_date"
                      getValueProps={(value) => {
                        return {
                          value: value ? dayjs(value) : "",
                        }
                      }}
                    >
                      <DatePicker style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Edit>
  )
}
