import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core"
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
} from "@pankod/refine-antd"
import { IIntegration } from "interfaces"

export const IntegrationList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { tableProps, sorter } = useTable<IIntegration>({
    initialSorter: [
      {
        field: "updated_at",
        order: "desc",
      },
    ],
  })

  return (
    <List>
      <Table {...tableProps} rowKey="id" size="small">
        <Table.Column
          dataIndex="name"
          key="name"
          title={t("integrations.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="description"
          key="description"
          title={t("integrations.fields.description")}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title={t("integrations.fields.created_at")}
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />

        <Table.Column<IIntegration>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              {/* <ShowButton hideText size="small" recordItemId={record.id} /> */}
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
