import { useState } from "react"
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core"
import {
  Edit,
  Form,
  Input,
  useForm,
  Typography,
  Row,
  Col,
  useSelect,
  Select,
} from "@pankod/refine-antd"

import { IIntegration, IRole } from "interfaces"

const { Paragraph } = Typography

export const IntegrationEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<IIntegration>()

  const { selectProps: roleSelectProps } = useSelect<IRole>({
    resource: "admin/roles",
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
    defaultValue: formProps.initialValues?.role_ids,
  })

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row>
          <Col xs={24} sm={16} md={12}>
            <Form.Item
              label={t("integrations.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Enter integrations name" />
            </Form.Item>
            <Form.Item
              label={t("integrations.fields.description")}
              name="description"
            >
              <Input.TextArea
                placeholder="Description"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              label={t("integrations.fields.url")}
              name="url"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="URL" />
            </Form.Item>
            <Form.Item
              label={t("integrations.fields.api_key")}
              name="api_key"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Password placeholder="API Key" style={{ width: "100%" }} />
            </Form.Item>
            <Paragraph
              copyable={{ text: formProps?.form?.getFieldValue("api_key") }}
            >
              Copy API Key.
            </Paragraph>
            <Form.Item name="role_ids" label={t("users.fields.roles")}>
              <Select
                mode="multiple"
                {...roleSelectProps}
                placeholder={t("users.fields.roles")}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  )
}
