import mqtt from "mqtt"
import { useEffect, useState } from "react"
import { MqttMessage } from "types"
import isJson from "utils/is-json"

const useMqtt = (
  url: string,
  options: mqtt.IClientOptions,
  initialTopic: string
) => {
  const [client, setClient] = useState<mqtt.MqttClient | null>(null)
  const [message, setMessage] = useState<MqttMessage | null>(null)
  const [isConnected, setIsConnected] = useState(false)

  useEffect(() => {
    const newClient = mqtt.connect(url, options)

    newClient.on("connect", () => {
      newClient.subscribe(initialTopic)
      setIsConnected(true)
    })

    newClient.on("message", (topic, message) => {
      const msg = message.toString()

      if (isJson(msg)) {
        const parsed = JSON.parse(msg)
        setMessage(parsed)
      } else {
        // setMessage({ topic, message: message.toString() })
      }
    })

    setClient(newClient)

    return () => {
      if (client) {
        client.end()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const publish = (topic: string, message: string) => {
    if (client) {
      client.publish(topic, message)
    }
  }

  const subscribe = (topic: string) => {
    if (client) {
      client.subscribe(topic)
    }
  }

  const unsubscribe = (topic: string) => {
    if (client) {
      client.unsubscribe(topic)
    }
  }

  return { client, isConnected, message, publish, subscribe, unsubscribe }
}

export default useMqtt
