import { Button, Drawer, Icons } from "@pankod/refine-antd"
import { IBill } from "interfaces"
import { PurchaseReturnCreate } from "pages/purchases/purchase-return"
import { useState } from "react"

interface IPurchaseReturnButtonProps {
  record: IBill | undefined
}

const PurchaseReturnButton: React.FC<IPurchaseReturnButtonProps> = ({
  record,
}) => {
  // const { modalProps, show, close } = useModal()
  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  if (!record) return null

  return (
    <>
      <Button
        icon={<Icons.RollbackOutlined style={{ fontSize: 18 }} />}
        onClick={showDrawer}
      >
        Return
      </Button>
      <Drawer
        title="Purchase Return"
        visible={open}
        onClose={onClose}
        width={"70vw"}
      >
        <PurchaseReturnCreate
          initialValues={{
            ...record,
            category_id: "",
            bill_date: undefined,
          }}
          breadcrumb={false}
          title="Create Purchase Return"
          // title={false}
          // goBack={false}
        />
      </Drawer>
    </>
  )
}

export default PurchaseReturnButton
