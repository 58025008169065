import { Button, Drawer, Icons } from "@pankod/refine-antd"
import { ITransaction } from "interfaces"
import { TransactionCreate } from "pages/banking/transactions"
import { useState } from "react"

interface IAddIncomeButtonProps {
  record?: ITransaction | undefined
}

const AddIncomeButton: React.FC<IAddIncomeButtonProps> = () => {
  // const { modalProps, show, close } = useModal()
  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        icon={<Icons.DollarOutlined style={{ fontSize: 18 }} />}
        onClick={showDrawer}
      >
        Add Income
      </Button>
      <Drawer title="Income" visible={open} onClose={onClose} width={"40vw"}>
        <TransactionCreate
          initialValues={{
            transaction_type: "Income",
            amount: 0,
            date: new Date(),
            description: "",
            account_id: "",
            category_id: "",
          }}
          breadcrumb={false}
          title="Add Income"
          fullWidth={true}
          // title={false}
          // goBack={false}
        />
      </Drawer>
    </>
  )
}

export default AddIncomeButton
