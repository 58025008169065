import {
  useTranslate,
  IResourceComponentsProps,
  useNavigation,
  useRouterContext,
  HttpError,
  CrudFilters,
} from "@pankod/refine-core"
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  ShowButton,
  NumberField,
  DateField,
  Row,
  Col,
  Card,
  Form,
  DatePicker,
  Input,
  Select,
  useSelect,
  Button,
  Typography,
} from "@pankod/refine-antd"
import {
  ICustomer,
  IInvoice,
  IInvoicePayment,
  IInvoicePaymentFilterVariables,
} from "interfaces"
import { green } from "@ant-design/colors"
import { dateStringify } from "utils/date"
import { makeFilterable } from "utils/filter"

const { Title } = Typography

export const InvoicePaymentList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { show } = useNavigation()

  const { tableProps, sorter, searchFormProps } = useTable<
    IInvoicePayment,
    HttpError,
    IInvoicePaymentFilterVariables
  >({
    initialSorter: [
      {
        field: "updated_at",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = []
      const {
        payment_date,
        payment_method,
        payment_number,
        customer_id,
        invoice_number,
      } = params

      filters.push(
        {
          field: "payment_date",
          operator: "eq",
          value: payment_date ? dateStringify(payment_date) : undefined,
        },
        {
          field: "payment_method",
          operator: "eq",
          value: payment_method,
        },
        {
          field: "payment_number",
          operator: "eq",
          value: makeFilterable(payment_number),
        },
        {
          field: "customer_id",
          operator: "eq",
          value: customer_id,
        },
        {
          field: "invoice_number",
          operator: "eq",
          value: makeFilterable(invoice_number),
        }
      )

      return filters
    },
  })

  const { selectProps: customerSelectProps } = useSelect<ICustomer>({
    resource: "customers",
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
  })

  const { Link } = useRouterContext()

  return (
    <List>
      <Row gutter={[12, 0]}>
        <Col span={5}>
          <Card size="small">
            <Title level={5}>Filters</Title>
            <Form {...searchFormProps}>
              <Form.Item name="payment_date">
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Payment Date"
                />
              </Form.Item>
              <Form.Item name="payment_number">
                <Input placeholder="Payment Number" />
              </Form.Item>
              <Form.Item name="invoice_number">
                <Input placeholder="Invoice Number" />
              </Form.Item>
              <Form.Item name="customer_id">
                <Select
                  allowClear
                  {...customerSelectProps}
                  placeholder="Customer"
                />
              </Form.Item>

              <Form.Item name="payment_method">
                <Select
                  allowClear
                  placeholder="Payment Method"
                  options={[
                    { label: "Cash", value: "cash" },
                    { label: "Cheque", value: "cheque" },
                    { label: "Bank Transfer", value: "bank_transfer" },
                  ]}
                />
              </Form.Item>

              <Button
                htmlType="submit"
                type="primary"
                style={{ width: "100%" }}
              >
                Search
              </Button>
            </Form>
          </Card>
        </Col>
        <Col span={19}>
          <Table {...tableProps} size="small" rowKey="id">
            <Table.Column
              dataIndex="payment_date"
              key="payment_date"
              title={t("invoices.fields.payment_date")}
              render={(value: string) => (
                <DateField value={value} format="DD MMM YYYY" />
              )}
              defaultSortOrder={getDefaultSortOrder("payment_date", sorter)}
              sorter
            />

            <Table.Column<IInvoicePayment>
              dataIndex="payment_number"
              key="payment_number"
              title={t("invoices.fields.payment_number")}
              render={(value: string, record) => (
                <TextField
                  value={value}
                  onClick={() => show("invoice-payments", record.id)}
                  style={{ color: green[4], cursor: "pointer" }}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("payment_number", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="reference"
              key="reference"
              title={t("invoices.fields.reference")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("reference", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="customer_name"
              key="customer_name"
              title={t("invoices.fields.customer_name")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("customer_name", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="invoices"
              key="invoices"
              title={t("invoices.fields.invoice_number")}
              render={(value) => (
                // <TextField
                //   value={value?.map((invoice: IInvoice) => invoice?.invoice_number).join(", ")}
                // />
                <Space split=",">
                  {value?.map((invoice: IInvoice) => (
                    <Link to={`/purchases/invoices/show/${invoice.id}`}>
                      {invoice?.invoice_number}
                    </Link>
                  ))}
                </Space>
              )}
              sorter
            />
            <Table.Column
              dataIndex="payment_method"
              key="payment_method"
              title={t("invoices.fields.payment_method")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("payment_method", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="amount"
              key="amount"
              title={t("invoices.fields.payment_amount")}
              render={(value) => (
                <NumberField
                  value={value}
                  options={{
                    style: "currency",
                    currency: "NPR",
                  }}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("amount", sorter)}
              sorter
            />
            <Table.Column<IInvoicePayment>
              title={t("table.actions")}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  {/* <EditButton hideText size="small" recordItemId={record.id} /> */}
                  <ShowButton hideText size="small" recordItemId={record.id} />
                  {/* <DeleteButton hideText size="small" recordItemId={record.id} /> */}
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
    </List>
  )
}
