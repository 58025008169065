import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core"
import {
  Checkbox,
  Col,
  Edit,
  Form,
  Input,
  InputNumber,
  Row,
  useForm,
} from "@pankod/refine-antd"

import "react-mde/lib/styles/css/react-mde-all.css"

import { ITax } from "interfaces"

export const TaxEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<ITax>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row>
          <Col xs={24} sm={16} md={12}>
            <Form.Item
              label={t("tax.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Enter tax name" />
            </Form.Item>
            <Form.Item
              label={t("tax.fields.rate")}
              name="rate"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                placeholder="Enter tax rate"
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item label={t("tax.fields.description")} name="description">
              <Input.TextArea placeholder="Enter tax description" />
            </Form.Item>
            <Form.Item
              label={t("tax.fields.is_enabled")}
              name="is_enabled"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  )
}
