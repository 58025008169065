import { ISaleReturn, ISaleReturnPayment, ICoa, IVendor } from "interfaces"

import {
  Card,
  Checkbox,
  Col,
  Create,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  Typography,
  useForm,
  useSelect,
} from "@pankod/refine-antd"
import axios from "axios"
import {
  IResourceComponentsProps,
  useApiUrl,
  useTranslate,
} from "@pankod/refine-core"
import dayjs from "dayjs"
import { orange } from "@ant-design/colors"

interface IProps extends IResourceComponentsProps {
  initialValues?: ISaleReturnPayment
  breadcrumb?: React.ReactNode
  title?: React.ReactNode
  goBack?: React.ReactNode
}

export const SaleReturnPaymentCreate: React.FC<IProps> = (props) => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<ISaleReturnPayment>({
    resource: "sale-return-payments",
    action: "create",
  })

  const { selectProps: customerSelectProps } = useSelect<IVendor>({
    resource: "customers",
    optionLabel: "name",
    optionValue: "id",
  })

  const {
    selectProps: saleReturnSelectProps,
    queryResult: saleReturnQueryResult,
  } = useSelect<ISaleReturn>({
    resource: "sale-returns",
    optionLabel: "sale_return_number",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
  })

  const { selectProps: paidToSelectProps, queryResult: paidToQueryResult } =
    useSelect<ICoa>({
      resource: "accounts",
      optionLabel: "name",
      optionValue: "id",
    })

  const apiUrl = useApiUrl()

  const handleSaleReturnIdChange = async (value: string) => {
    const saleReturn = saleReturnQueryResult?.data?.data.find(
      (v) => v.id === value
    )

    try {
      const { data } = await axios.get(
        `${apiUrl}/sale-returns?sale_return_number=${saleReturn?.sale_return_number}&status=sent&status=partially_paid`,
        { withCredentials: true }
      )
      const saleReturns = data?.docs?.map(
        (saleReturn: ISaleReturn, index: number) => ({
          invoice_date: saleReturn.invoice_date,
          invoice_number: saleReturn.invoice_number,
          id: saleReturn.id,
          sale_return_amount: saleReturn.total,
          due_amount: saleReturn.total - saleReturn.paid_amount,
          payment_amount: 0,
          account_id: saleReturn?.items[index]?.account_id,
          category_id: saleReturn?.category_id,
          created_at: saleReturn.created_at,
          sale_return_number: saleReturn.sale_return_number,
        })
      )
      formProps?.form?.setFieldsValue({
        sale_returns: saleReturns,
        customer_id: saleReturn?.customer_id,
        customer_name: saleReturn?.customer_name,
      })
    } catch (err) {}
  }

  const handlePaymentThroughChange = (value: string) => {
    const account = paidToQueryResult?.data?.data.find(
      (c) => c.id === value?.toString()
    )
    if (account) {
      formProps?.form?.setFieldsValue({
        paid_through_account_name: account.name,
      })
    }
  }

  const handlePayfullItemClick = (index: number) => {
    const saleReturns = formProps?.form?.getFieldValue("sale_returns")
    const newSaleReturns = saleReturns?.map(
      (invoice: ISaleReturn, idx: number) => {
        if (idx === index) {
          return {
            ...invoice,
            payment_amount: invoice?.due_amount,
          }
        }
        return invoice
      }
    )
    formProps?.form?.setFieldsValue({
      sale_returns: newSaleReturns,
    })
    return
  }

  const handlePayFullCheck = (checked: boolean) => {
    if (checked) {
      const saleReturns = formProps?.form?.getFieldValue("sale_returns")
      const newSaleReturns = saleReturns?.map((saleReturn: ISaleReturn) => {
        return {
          ...saleReturn,
          payment_amount: saleReturn?.due_amount,
        }
      })
      formProps?.form?.setFieldsValue({
        sale_returns: newSaleReturns,
        amount: saleReturns?.reduce(
          (acc: number, invoice: ISaleReturn) =>
            acc + (invoice?.due_amount ?? 0),
          0
        ),
      })
    } else {
      const saleReturns = formProps?.form?.getFieldValue("sale_returns")
      const newSaleReturns = saleReturns?.map((invoice: ISaleReturn) => {
        return {
          ...invoice,
          payment_amount: 0,
        }
      })
      formProps?.form?.setFieldsValue({
        sale_returns: newSaleReturns,
        amount: 0,
      })
    }
  }

  let initialValues = {
    payment_date: dayjs().format("YYYY-MM-DD"),
    payment_method: "cash",
    // invoices: [{ sale_return_amount: 0, payment_amount: 0 }],
    note: "Thanks for your business.",
  }

  return (
    <Create saveButtonProps={saveButtonProps} {...props}>
      <Form
        {...formProps}
        layout="vertical"
        // labelCol={{ span: 10 }}
        // wrapperCol={{ span: 14 }}
        // labelAlign="left"
        initialValues={props?.initialValues || initialValues}
      >
        <Row gutter={[32, 0]}>
          <Col xs={24} sm={16} md={14} lg={20}>
            <Form.Item
              label={t("sale_returns.fields.sale_return_id")}
              name="sale_return_id"
              rules={[
                {
                  required: true,
                  message: t("sale_returns.errors.sale_return_id"),
                },
              ]}
            >
              <Select
                {...saleReturnSelectProps}
                showSearch
                allowClear
                onChange={(value) => {
                  handleSaleReturnIdChange(value?.toString())
                }}
                placeholder="Select Sale Return"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={16} md={14} lg={10}>
            <Form.Item
              label={t("sale_returns.fields.customer_name")}
              name="customer_id"
              rules={[
                {
                  required: true,
                  message: t("sale_returns.errors.customer_name"),
                },
              ]}
            >
              <Select {...customerSelectProps} showSearch disabled />
            </Form.Item>
            <Form.Item name="customer_name" hidden>
              <Input />
            </Form.Item>
            <Row gutter={[8, 0]}>
              <Col xs={24} lg={16}>
                <Form.Item
                  label={t("sale_returns.fields.payment_amount")}
                  name="amount"
                  // labelCol={{ span: 6 }}
                  // wrapperCol={{ span: 10 }}
                >
                  <InputNumber
                    addonBefore="NPR"
                    placeholder="Enter payment amount"
                    // style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  name="full_check"
                  labelCol={{ span: 6 }}
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={(e) => {
                      handlePayFullCheck(e.target.checked)
                    }}
                  >
                    {t("sale_returns.fields.payment_full_check")}
                  </Checkbox>
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={12}>
                <Form.Item
                  name="charge_amount"
                  label={t("sale_returns.fields.charge_amount")}
                  // labelCol={{ span: 6 }}
                  // wrapperCol={{ span: 10 }}
                >
                  <InputNumber
                    placeholder="Enter charge amount"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col> */}
            </Row>

            <Form.Item
              label={t("sale_returns.fields.payment_reference")}
              name="reference"
            >
              <Input placeholder="Enter payment reference" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={16} md={14} lg={10}>
            <Form.Item
              label={t("sale_returns.fields.payment_date")}
              name="payment_date"
              getValueProps={(value) => {
                return {
                  value: value ? dayjs(value) : "",
                }
              }}
              rules={[{ required: true }]}
              // labelCol={{ span: 6 }}
              // wrapperCol={{ span: 10 }}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label={t("sale_returns.fields.payment_method")}
              name="payment_method"
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  { value: "bank_transfer", label: "Bank Transfer" },
                  { value: "cash", label: "Cash" },
                  { value: "cheque", label: "Cheque" },
                  { value: "credit", label: "Credit" },
                ]}
              />
            </Form.Item>
            <Form.Item
              label={t("sale_returns.fields.payment_through_account_name")}
              name="paid_through_account_id"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                allowClear
                {...paidToSelectProps}
                onChange={(value) => {
                  handlePaymentThroughChange(value?.toString())
                }}
                placeholder="Select Account"
              />
            </Form.Item>
            <Form.Item name="paid_through_account_name" hidden>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={22} md={20} lg={20}>
            <Form.List name="sale_returns">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    <Table
                      columns={[
                        {
                          title: t("sale_returns.fields.sale_return_date"),
                          dataIndex: "sale_return_date",
                          key: "sale_return_date",
                          render: (value, record, index) => {
                            return (
                              <>
                                <Typography.Title
                                  level={5}
                                  style={{ margin: 0 }}
                                >
                                  {dayjs(value).format("DD MMM YYYY")}
                                </Typography.Title>

                                {/* <Typography.Text>
                                  Return date:{" "}
                                  <strong>
                                    {dayjs(record[index]?.created_at).format(
                                      "DD MMM YYYY"
                                    )}
                                  </strong>
                                </Typography.Text> */}
                              </>
                            )
                          },
                        },
                        {
                          title: t("sale_returns.fields.sale_return_number"),
                          dataIndex: "sale_return_number",
                          key: "sale_return_number",
                        },
                        {
                          title: t("sale_returns.fields.invoice_number"),
                          dataIndex: "invoice_number",
                          key: "invoice_number",
                        },
                        {
                          title: t("sale_returns.fields.sale_return_amount"),
                          dataIndex: "sale_return_amount",
                          key: "sale_return_amount",
                          render: (value) => <strong>{value}</strong>,
                        },
                        {
                          title: t("sale_returns.fields.due_amount"),
                          dataIndex: "due_amount",
                          key: "due_amount",
                          render: (value) => <strong>{value}</strong>,
                        },
                        {
                          title: t("sale_returns.fields.payment_amount"),
                          dataIndex: "payment_amount",
                          key: "payment_amount",
                          render: (value, record, index) => {
                            return (
                              <>
                                <Form.Item
                                  name={[index, "payment_amount"]}
                                  rules={[{ required: true }]}
                                  shouldUpdate={(prevValues, currentValues) => {
                                    return (
                                      prevValues?.saleReturns?.length !==
                                      currentValues?.saleReturns?.length
                                    )
                                  }}
                                  noStyle
                                >
                                  <InputNumber />
                                </Form.Item>
                                <br />
                                <Form.Item noStyle>
                                  <Typography.Link
                                    onClick={() =>
                                      handlePayfullItemClick(index)
                                    }
                                  >
                                    Pay in Full
                                  </Typography.Link>
                                </Form.Item>
                              </>
                            )
                          },
                        },
                        {
                          title: "",
                          dataIndex: "action",
                          key: "action",
                          render: (value, record, index) => {
                            return (
                              <Typography.Link
                                type="danger"
                                onClick={() => {
                                  remove(index)
                                }}
                              >
                                Remove
                              </Typography.Link>
                            )
                          },
                        },
                      ]}
                      dataSource={
                        formProps?.form?.getFieldValue("sale_returns") || []
                      }
                      pagination={false}
                      rowKey="id"
                    />
                  </div>
                )
              }}
            </Form.List>
          </Col>
        </Row>
        <br />
        <Row gutter={[64, 0]}>
          <Col xs={24} md={{ span: 10, offset: 5 }}>
            <Card
              style={{
                backgroundColor: orange[0],
                lineHeight: 2,
                maxWidth: 450,
              }}
            >
              <Row justify="space-between">
                <Col>
                  <Typography.Text style={{ color: orange[5] }}>
                    Amount used for payment
                  </Typography.Text>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues?.saleReturns !== currentValues?.saleReturns
                    }
                    noStyle
                  >
                    {({ getFieldValue }) => {
                      const saleReturns = getFieldValue("sale_returns") || []
                      const total = saleReturns.reduce(
                        (acc: number, cur: ISaleReturn) =>
                          acc + (cur?.payment_amount ?? 0),
                        0
                      )
                      return (
                        <Typography.Text style={{ color: orange[5] }}>
                          {total.toFixed(2)}
                        </Typography.Text>
                      )
                    }}
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row justify="space-between">
                <Col>
                  <Typography.Text style={{ color: orange[5] }}>
                    Additional Charges
                  </Typography.Text>
                </Col>
                <Col>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues?.charge_amount !== currentValues?.charge_amount
                    }
                    noStyle
                  >
                    {({ getFieldValue }) => {
                      const chargeAmount = getFieldValue("charge_amount") || 0
                      return (
                        <Typography.Text style={{ color: orange[5] }}>
                          {chargeAmount.toFixed(2)}
                        </Typography.Text>
                      )
                    }}
                  </Form.Item>
                </Col>
              </Row> */}
            </Card>
          </Col>
        </Row>
        <Form.Item
          labelAlign="left"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 10 }}
          label={t("sale_returns.fields.note")}
          name="note"
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Create>
  )
}
