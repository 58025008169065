import {
  useTranslate,
  IResourceComponentsProps,
  useApiUrl,
} from "@pankod/refine-core"
import {
  Create,
  Form,
  Input,
  Select,
  useSelect,
  useForm,
  Typography,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Table,
  Button,
  Icons,
  Tooltip,
  Space,
  getValueFromEvent,
  Upload,
} from "@pankod/refine-antd"
import { red, blue } from "@ant-design/colors"
import dayjs from "dayjs"
// import { NepaliDatePicker } from "nepali-datepicker-reactjs"
// import "nepali-datepicker-reactjs/dist/index.css"
// import NepaliDate from "nepali-date-converter"

import "react-mde/lib/styles/css/react-mde-all.css"

import {
  IInvoice,
  ICategory,
  IItem,
  IInvoiceItem,
  ITax,
  ICustomer,
} from "interfaces"
import { calcDiscount, calcTax } from "utils/calculator"

const { Title, Text } = Typography

export const InvoiceCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const apiUrl = useApiUrl()

  const { formProps, saveButtonProps } = useForm<IInvoice>({
    redirect: "show",
  })

  const { selectProps: customersSelectProps } = useSelect<ICustomer>({
    resource: "customers",
    optionLabel: "name",
    optionValue: "id",
  })

  const { selectProps: itemSelectProps, queryResult: itemQueryResult } =
    useSelect<IItem>({
      resource: "items",
      optionLabel: "name",
      optionValue: "id",
      onSearch: (value) => [
        {
          field: "q",
          operator: "eq",
          value,
        },
      ],
    })

  const { selectProps: taxSelectProps, queryResult: taxQueryResult } =
    useSelect<ITax>({
      resource: "tax",
      optionLabel: "name",
      optionValue: "id",
    })

  const {
    selectProps: categoriesSelectProps,
    queryResult: categoriesQueryResult,
  } = useSelect<ICategory>({
    resource: "categories",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "type",
        operator: "eq",
        value: "income",
      },
    ],
  })

  const selectAfter = (name: string | (string | number)[]) => (
    <Form.Item name={name} noStyle>
      <Select style={{ width: 60 }}>
        <Select.Option value="percentage">%</Select.Option>
        <Select.Option value="flat">NRS</Select.Option>
      </Select>
    </Form.Item>
  )

  const handleItemIdChange = (value: string) => {
    const itemData = itemQueryResult?.data?.data.find(
      (item) => item.id === value
    )
    const items = formProps?.form?.getFieldsValue(["items"])

    const updatedItems = items.items.map((item: IInvoiceItem, idx: number) => {
      if (item.item_id === value) {
        const tax_rate = itemData?.sales_tax_rate ?? 0
        const item_total = itemData?.sales_price! * 1
        const tax = calcTax(tax_rate, item_total)
        const item_total_inclusive_of_tax = item_total + tax
        return {
          ...item,
          item_id: itemData?.id,
          item_order: idx,
          account_id: itemData?.sales_account_id,
          rate: itemData?.sales_price,
          item_unit: itemData?.unit,
          tax_id: itemData?.sales_tax_id,
          tax_name: itemData?.sales_tax_name,
          tax_rate,
          tax,
          discount: 0,
          discount_setting: "percentage",
          discount_value: 0,
          item_total,
          item_total_with_discount: item_total,
          item_total_tax: tax,
          item_total_inclusive_of_tax,
        }
      }
      return item
    })
    const sub_total = updatedItems.reduce(
      (acc: number, item: IInvoiceItem) => acc + item.item_total_with_discount,
      0
    )
    formProps?.form?.setFieldsValue({
      items: updatedItems,
      sub_total,
    })
  }

  const handleItemQuantityChange = (value: string, index: number) => {
    const { items } = formProps?.form?.getFieldsValue(["items"])
    const updatedItems = items.map((item: IInvoiceItem, idx: number) => {
      if (index === idx) {
        const discountSetting = item.discount_setting
        const discount = item?.discount ?? 0
        const item_total = Number(value) * item.rate
        const tax_rate = item.tax_rate ?? 0
        const discount_value = calcDiscount(
          item_total,
          discountSetting,
          discount
        )
        const item_total_with_discount = item_total - discount_value

        const item_total_tax = calcTax(tax_rate, item_total_with_discount)

        const item_total_inclusive_of_tax =
          item_total_with_discount + item_total_tax

        return {
          ...item,
          item_total,
          discount_value,
          item_total_with_discount,
          item_total_tax,
          item_total_inclusive_of_tax,
        }
      }
      return item
    })
    formProps?.form?.setFieldsValue({
      items: updatedItems,
    })
  }

  const handleItemRateChange = (value: string, index: number) => {
    const { items } = formProps?.form?.getFieldsValue(["items"])
    const updatedItems = items.map((item: IInvoiceItem, idx: number) => {
      if (index === idx) {
        const discountSetting =
          formProps?.form?.getFieldValue("discount_setting") ?? "percentage"
        const discount = formProps?.form?.getFieldValue("discount") ?? 0
        const item_total = Number(value) * item.quantity
        const tax_rate = item.tax_rate ?? 0
        const discount_value = calcDiscount(
          item_total,
          discountSetting,
          discount
        )
        const item_total_with_discount = item_total - discount_value

        const item_total_tax = calcTax(tax_rate, item_total_with_discount)

        const item_total_inclusive_of_tax =
          item_total_with_discount + item_total_tax

        return {
          ...item,
          item_total,
          discount: discount_value,
          item_total_with_discount,
          item_total_tax,
          item_total_inclusive_of_tax,
        }
      }
      return item
    })
    const sub_total = updatedItems.reduce(
      (acc: number, item: IInvoiceItem) => acc + item.item_total_with_discount,
      0
    )
    formProps?.form?.setFieldsValue({
      items: updatedItems,
      sub_total,
    })
  }

  const handleItemTaxIdChange = (value: string, index: number) => {
    const taxData = taxQueryResult?.data?.data.find((tax) => tax.id === value)
    const items = formProps?.form?.getFieldsValue(["items"])
    const updatedItems = items.items.map((item: IInvoiceItem, idx: number) => {
      if (index === idx) {
        if (!value) {
          return {
            ...item,
            tax_id: null,
            tax_rate: 0,
            tax_name: null,
            item_total_tax: 0,
            item_total_inclusive_of_tax: item.item_total_with_discount,
          }
        } else {
          const tax_rate = taxData?.rate ?? 0
          const tax = calcTax(tax_rate, item.item_total_with_discount)
          const item_total_inclusive_of_tax =
            item.item_total_with_discount + tax
          return {
            ...item,
            tax_id: taxData?.id,
            tax_name: taxData?.name,
            tax_rate: taxData?.rate,
            item_total_tax: tax,
            item_total_inclusive_of_tax,
          }
        }
      }
      return item
    })
    const sub_total = updatedItems.reduce(
      (acc: number, item: IInvoiceItem) => acc + item.item_total_with_discount,
      0
    )
    formProps?.form?.setFieldsValue({
      items: updatedItems,
      sub_total,
    })
  }

  const handleItemDiscountChange = (value: string, index: number) => {
    const { items } = formProps?.form?.getFieldsValue(["items"])
    const updatedItems = items.map((item: IInvoiceItem, idx: number) => {
      if (index === idx) {
        const discountSetting = item?.discount_setting ?? "percentage"
        const discount_value = calcDiscount(
          item.item_total,
          discountSetting,
          Number(value)
        )
        const item_total_with_discount = item.item_total - discount_value
        const item_total_tax = calcTax(item.tax_rate, item_total_with_discount)
        const item_total_inclusive_of_tax =
          Number((item_total_with_discount + item_total_tax).toFixed(2)) ?? 0
        return {
          ...item,
          discount_value,
          item_total_with_discount,
          item_total_tax,
          item_total_inclusive_of_tax,
        }
      }
      return item
    })
    const sub_total = updatedItems.reduce(
      (acc: number, item: IInvoiceItem) => acc + item.item_total_with_discount,
      0
    )
    formProps?.form?.setFieldsValue({
      items: updatedItems,
      sub_total,
    })
  }

  const _taxTotal = (items: IInvoiceItem[]): number => {
    const taxTotal = items.reduce((acc: number, curr: IInvoiceItem) => {
      return acc + (curr?.item_total_tax ?? 0)
    }, 0)
    return taxTotal
  }

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          invoice_date: dayjs().format("YYYY-MM-DD"),
          due_date: dayjs().add(15, "days").format("YYYY-MM-DD"),
          discount_setting: "percentage",
          category_id: categoriesQueryResult?.data?.data.find(
            (cat) => cat.access_name === "sale"
          )?.id,
          items: [
            {
              quantity: 1,
              rate: 0,
              item_total: 0,
              item_total_inclusive_of_tax: 0,
              discount: 0,
              discount_setting: "percentage",
              item_total_with_discount: 0,
            },
          ],
          shipping_charge: 0,
          adjustment: 0,
          note: "Thanks for your business.",
        }}
        // labelCol={{ span: 10 }}
        // wrapperCol={{ span: 16 }}
        // labelAlign="left"
      >
        <Row gutter={[32, 0]}>
          <Col xs={24} md={12} lg={10}>
            <Form.Item
              label={t("invoices.fields.customer_name")}
              name="customer_id"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select {...customersSelectProps} />
            </Form.Item>
            <Form.Item
              label={t("invoices.fields.order_number")}
              name="order_number"
            >
              <Input />
            </Form.Item>
            {/* <Form.Item
              label={t("invoices.fields.invoice_number")}
              name="invoice_number"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item> */}
            <Form.Item
              label={t("invoices.fields.category_id")}
              name="category_id"
              rules={[{ required: true }]}
            >
              <Select {...categoriesSelectProps} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={10}>
            <Form.Item
              label={t("invoices.fields.invoice_date")}
              name="invoice_date"
              getValueProps={(value) => {
                return {
                  value: value ? dayjs(value) : "",
                }
              }}
              rules={[{ required: true }]}
            >
              {/* <div className="ant-picker" style={{ width: "100%" }}> */}
              {/* <NepaliDatePicker
                className="ant-picker ant-picker-input"
                options={{
                  calenderLocale: "ne",
                  valueLocale: "en",
                  closeOnSelect: false,
                }}
              /> */}
              {/* </div> */}
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label={t("invoices.fields.due_date")}
              name="due_date"
              getValueProps={(value) => {
                return {
                  value: value ? dayjs(value) : "",
                }
              }}
              rules={[{ required: true }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Title level={4}>{t("invoices.item_list")}</Title>
        <Row>
          <Form.List name="items">
            {(fields, { add, remove }) => {
              return (
                <div>
                  <Table
                    bordered
                    columns={[
                      {
                        title: t("invoices.fields.item_name"),
                        dataIndex: "item_id",
                        key: "item_id",
                        width: "40%",
                        render: (_text, _record, index) => (
                          <>
                            <Space size="small" direction="vertical">
                              <Form.Item
                                name={[index, "item_id"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Item is required.",
                                  },
                                ]}
                                noStyle
                              >
                                <Select
                                  showSearch
                                  allowClear
                                  {...itemSelectProps}
                                  placeholder="Item or service name"
                                  style={{ width: 240 }}
                                  onChange={(value) => {
                                    handleItemIdChange(value?.toString())
                                  }}
                                />
                              </Form.Item>

                              <Form.Item name={[index, "description"]} noStyle>
                                <Input.TextArea placeholder="Description" />
                              </Form.Item>
                            </Space>
                          </>
                        ),
                      },
                      {
                        title: t("invoices.fields.item_quantity"),
                        dataIndex: "quantity",
                        key: "quantity",
                        width: "20%",
                        render: (_text, _record, index) => (
                          <Form.Item
                            name={[index, "quantity"]}
                            rules={[
                              {
                                required: true,
                                message: "Quantity is required",
                              },
                            ]}
                            noStyle
                          >
                            <InputNumber
                              placeholder="Qty."
                              style={{ width: 80 }}
                              onChange={(value) => {
                                handleItemQuantityChange(
                                  value?.toString(),
                                  index
                                )
                              }}
                            />
                          </Form.Item>
                        ),
                      },
                      {
                        title: t("invoices.fields.item_unit_price"),
                        dataIndex: "rate",
                        key: "rate",
                        width: "25%",
                        render: (_text, _record, index) => (
                          <Form.Item
                            name={[index, "rate"]}
                            rules={[
                              {
                                required: true,
                                message: "Unit price is required",
                              },
                            ]}
                            shouldUpdate={(prevValues, currentValues) => {
                              return (
                                prevValues.items[index]?.item_id !==
                                currentValues.items[index]?.item_id
                              )
                            }}
                            noStyle
                          >
                            <InputNumber
                              // addonBefore="NRS"
                              placeholder="Unit Price"
                              onChange={(value) => {
                                handleItemRateChange(value?.toString(), index)
                              }}
                              style={{ width: 150 }}
                            />
                          </Form.Item>
                        ),
                      },
                      {
                        title: t("invoices.fields.discount"),
                        dataIndex: "discount",
                        key: "discount",
                        width: "25%",
                        render: (_text, _record, index) => (
                          <Form.Item
                            name={[index, "discount"]}
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                              return (
                                prevValues.items[index]?.item_id !==
                                currentValues.items[index]?.item_id
                              )
                            }}
                          >
                            <InputNumber
                              placeholder="Discount"
                              addonAfter={selectAfter([
                                index,
                                "discount_setting",
                              ])}
                              onChange={(value) => {
                                handleItemDiscountChange(
                                  value?.toString(),
                                  index
                                )
                              }}
                              style={{ width: "120px" }}
                            />
                          </Form.Item>
                        ),
                      },
                      {
                        title: t("invoices.fields.item_tax"),
                        dataIndex: "tax_id",
                        key: "tax_id",
                        width: "40%",
                        render: (_text, _record, index) => (
                          <Form.Item
                            name={[index, "tax_id"]}
                            // rules={[{ required: true, message: "Tax is required." }]}
                            noStyle
                          >
                            <Select
                              showSearch
                              allowClear
                              {...taxSelectProps}
                              placeholder="Tax"
                              style={{ width: 150 }}
                              onChange={(value) => {
                                handleItemTaxIdChange(value?.toString(), index)
                              }}
                            />
                          </Form.Item>
                        ),
                      },
                      {
                        title: t("invoices.fields.amount"),
                        dataIndex: "item_total_with_discount",
                        key: "item_total_with_discount",
                        width: "20%",
                      },
                      {
                        title: "",
                        dataIndex: "",
                        key: "action",
                        width: "10%",
                        render: (_text, _record, index) => (
                          <Tooltip
                            title={t("invoices.fields.remove_item")}
                            color={red[4]}
                          >
                            <Icons.MinusCircleOutlined
                              style={{ fontSize: 16, color: red[5] }}
                              onClick={() => remove(index)}
                            />
                          </Tooltip>
                        ),
                      },
                    ]}
                    dataSource={formProps?.form?.getFieldValue("items") || []}
                    pagination={false}
                    rowKey="id"
                    size="small"
                  />
                  <Form.Item style={{ marginTop: "0.4rem" }}>
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: "#f7f7f7",
                        color: "#000",
                      }}
                      onClick={() => {
                        add({
                          name: "",
                          quantity: 1,
                          rate: 0,
                          item_total: 0,
                        })
                      }}
                    >
                      <Icons.PlusCircleFilled
                        style={{ color: blue[5], fontSize: 17 }}
                      />{" "}
                      {t("invoices.fields.add_item")}
                    </Button>
                  </Form.Item>
                </div>
              )
            }}
          </Form.List>
        </Row>
        <Row gutter={[64, 0]}>
          <Col xs={24} md={2} lg={4}></Col>
          <Col xs={24} md={16} lg={10} className="total-section">
            {/* TOTAL SECTION */}
            {/* SUB TOTAL */}
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.items.length !== curValues.items.length
              }
              noStyle
            >
              {({ getFieldValue }) => {
                const items = getFieldValue("items") || []
                const subTotal: number = items.reduce(
                  (acc: number, curr: IInvoiceItem) => {
                    return acc + (curr.item_total_with_discount ?? 0)
                  },
                  0
                )
                return (
                  <Row>
                    <Col xs={12}>
                      <Title level={5}>Sub total</Title>
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      <Text>NRS. {subTotal.toFixed(2)}</Text>
                    </Col>
                  </Row>
                )
              }}
            </Form.Item>
            {/* Shipping Charge */}
            <Row>
              <Col xs={8}>
                <Title level={5}>{t("invoices.fields.shipping_charge")}</Title>
              </Col>
              <Col xs={8}>
                <Form.Item
                  name="shipping_charge"
                  // label={t("bills.fields.discount")}
                  noStyle
                  style={{ width: "200px" }}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Shipping Charge"
                  />
                </Form.Item>
              </Col>
              <Col xs={8} style={{ textAlign: "right" }}>
                <Form.Item
                  shouldUpdate={(prevValues, currValues) =>
                    prevValues?.shipping_charge !== currValues?.shipping_charge
                  }
                  noStyle
                >
                  {({ getFieldValue }) => {
                    const shippingCharge = getFieldValue("shipping_charge") || 0
                    return <Text>NRS. {shippingCharge?.toFixed(2)}</Text>
                  }}
                </Form.Item>
              </Col>
            </Row>
            <br />
            {/* Adjustment */}
            <Row>
              <Col xs={8}>
                <Title level={5}>{t("invoices.fields.adjustment")}</Title>
              </Col>
              <Col xs={8}>
                <Form.Item
                  name="adjustment"
                  // label={t("bills.fields.discount")}
                  noStyle
                  style={{ width: "200px" }}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Adjustment"
                  />
                </Form.Item>
              </Col>
              <Col xs={8} style={{ textAlign: "right" }}>
                <Form.Item
                  shouldUpdate={(prevValues, currValues) =>
                    prevValues?.adjustment !== currValues?.adjustment
                  }
                  noStyle
                >
                  {({ getFieldValue }) => {
                    const adjustment = getFieldValue("adjustment") || 0
                    return <Text>NRS. {adjustment?.toFixed(2)}</Text>
                  }}
                </Form.Item>
              </Col>
            </Row>
            {/* TAX */}
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.items.length !== curValues.items.length
              }
              noStyle
            >
              {({ getFieldValue }) => {
                const items = getFieldValue("items") || []
                const taxTotal: number = _taxTotal(items)
                return (
                  <Row>
                    <Col xs={12}>
                      <Title level={5}>Tax Total:</Title>
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      <Title level={5}>NRS. {taxTotal.toFixed(2)}</Title>
                    </Col>
                  </Row>
                )
              }}
            </Form.Item>
            {/* TOTAL */}
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.items.length !== curValues.items.length ||
                prevValues.shipping_charge !== curValues.shipping_charge ||
                prevValues.adjustment !== curValues.adjustment
              }
              noStyle
            >
              {({ getFieldValue }) => {
                const items = getFieldValue("items") || []
                let total = items.reduce((acc: number, curr: IInvoiceItem) => {
                  return acc + (curr.item_total_inclusive_of_tax ?? 0)
                }, 0)
                const shippingCharge = getFieldValue("shipping_charge")
                const adjustment = getFieldValue("adjustment")
                total += shippingCharge ?? 0
                total += adjustment ?? 0
                return (
                  <Row>
                    <Col xs={12}>
                      <Title level={4}>Total:</Title>
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      <Form.Item name="sub_total_inclusive_of_tax" noStyle>
                        <Title level={4}>NRS. {total.toFixed(2)}</Title>
                      </Form.Item>
                    </Col>
                  </Row>
                )
              }}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Attachments">
          <Form.Item
            name="files"
            valuePropName="attachments"
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/files/upload`}
              listType="picture"
              maxCount={5}
              multiple
              withCredentials
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Text>{t("invoices.fields.note")}</Text>
        <Form.Item name="note" labelAlign="left">
          <Input.TextArea placeholder="Note here." />
        </Form.Item>
      </Form>
    </Create>
  )
}
