import { IBill, IBillPayment, ICoa, IVendor } from "interfaces"

import {
  Card,
  Checkbox,
  Col,
  Create,
  DatePicker,
  Form,
  getValueFromEvent,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  Typography,
  Upload,
  useForm,
  useSelect,
} from "@pankod/refine-antd"
import axios from "axios"
import {
  IResourceComponentsProps,
  useApiUrl,
  useTranslate,
} from "@pankod/refine-core"
import dayjs from "dayjs"
import { orange } from "@ant-design/colors"

interface IProps extends IResourceComponentsProps {
  initialValues?: Omit<IBillPayment, "id">
  breadcrumb?: React.ReactNode
  title?: React.ReactNode
  goBack?: React.ReactNode
}

export const BillPaymentCreate: React.FC<IProps> = (props) => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<IBillPayment>({
    resource: "bill-payments",
    action: "create",
  })

  const { selectProps: vendorSelectProps, queryResult: vendorQueryResult } =
    useSelect<IVendor>({
      resource: "vendors",
      optionLabel: "name",
      optionValue: "id",
    })

  const {
    selectProps: paidThroughSelectProps,
    queryResult: paidThroughQueryResult,
  } = useSelect<ICoa>({
    resource: "accounts",
    optionLabel: "name",
    optionValue: "id",
  })

  const apiUrl = useApiUrl()

  const handleVendorChange = async (value: string) => {
    const vendor = vendorQueryResult?.data?.data.find((v) => v.id === value)
    if (vendor) {
      formProps?.form?.setFieldsValue({
        vendor_name: vendor.name,
      })

      try {
        const { data } = await axios.get(
          `${apiUrl}/bills?vendor_id=${value}&status=open&status=partially_paid`,
          { withCredentials: true }
        )
        const bills = data?.docs?.map((bill: IBill, index: number) => ({
          bill_date: bill.bill_date,
          bill_number: bill.bill_number,
          id: bill.id,
          bill_amount: bill.total,
          due_amount: bill.total - bill.paid_amount,
          payment_amount: 0,
          account_id: bill?.items[index]?.account_id,
          category_id: bill?.category_id,
        }))
        formProps?.form?.setFieldsValue({ bills })
      } catch (err) {}
    }
  }

  const handlePaymentThroughChange = (value: string) => {
    const account = paidThroughQueryResult?.data?.data.find(
      (c) => c.id === value?.toString()
    )
    if (account) {
      formProps?.form?.setFieldsValue({
        paid_through_account_name: account.name,
      })
    }
  }

  const handlePayfullItemClick = (index: number) => {
    const bills = formProps?.form?.getFieldValue("bills")
    const newBills = bills?.map((bill: IBill, idx: number) => {
      if (idx === index) {
        return {
          ...bill,
          payment_amount: bill?.due_amount,
        }
      }
      return bill
    })
    formProps?.form?.setFieldsValue({
      bills: newBills,
    })
    return
  }

  const handlePayFullCheck = (checked: boolean) => {
    if (checked) {
      const bills = formProps?.form?.getFieldValue("bills")
      const newBills = bills?.map((bill: IBill) => {
        return {
          ...bill,
          payment_amount: bill?.due_amount,
        }
      })
      formProps?.form?.setFieldsValue({
        bills: newBills,
        amount: bills?.reduce(
          (acc: number, bill: IBill) => acc + (bill?.due_amount ?? 0),
          0
        ),
      })
    } else {
      const bills = formProps?.form?.getFieldValue("bills")
      const newBills = bills?.map((bill: IBill) => {
        return {
          ...bill,
          payment_amount: 0,
        }
      })
      formProps?.form?.setFieldsValue({
        bills: newBills,
        amount: 0,
      })
    }
  }

  const initialValues = {
    payment_date: dayjs().format("YYYY-MM-DD"),
    payment_method: "cash",
    // bills: [{ bill_amount: 0, payment_amount: 0 }],
    note: "Thanks for your business.",
  }

  if (props?.initialValues) {
    Object.assign(initialValues, props.initialValues)
  }

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        // labelCol={{ span: 10 }}
        // wrapperCol={{ span: 14 }}
        // labelAlign="left"
        initialValues={initialValues}
      >
        <Row gutter={[32, 0]}>
          <Col xs={24} sm={16} md={14} lg={10}>
            <Form.Item
              label={t("bills.fields.vendor_name")}
              name="vendor_id"
              rules={[
                { required: true, message: t("bills.errors.vendor_name") },
              ]}
            >
              <Select
                {...vendorSelectProps}
                showSearch
                allowClear
                onChange={(value) => {
                  handleVendorChange(value?.toString())
                }}
                placeholder="Select Vendor"
              />
            </Form.Item>
            <Form.Item name="vendor_name" hidden>
              <Input />
            </Form.Item>
            <Row gutter={[8, 0]}>
              <Col xs={24} lg={16}>
                <Form.Item
                  label={t("bills.fields.payment_amount")}
                  name="amount"
                  // labelCol={{ span: 6 }}
                  // wrapperCol={{ span: 10 }}
                >
                  <InputNumber
                    addonBefore="NPR"
                    placeholder="Payment amount."
                  />
                </Form.Item>
                <Form.Item name="full_check" labelCol={{ span: 6 }}>
                  <Checkbox
                    onChange={(e) => {
                      handlePayFullCheck(e.target.checked)
                    }}
                  >
                    {t("bills.fields.payment_full_check")}
                  </Checkbox>
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={12}>
                <Form.Item
                  name="charge_amount"
                  label={t("bills.fields.charge_amount")}
                  // labelCol={{ span: 6 }}
                  // wrapperCol={{ span: 10 }}
                >
                  <InputNumber addonBefore="NPR" />
                </Form.Item>
              </Col> */}
            </Row>

            <Form.Item
              label={t("bills.fields.payment_reference")}
              name="reference"
            >
              <Input placeholder="Reference" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={16} md={14} lg={10}>
            <Form.Item
              label={t("bills.fields.payment_date")}
              name="payment_date"
              getValueProps={(value) => {
                return {
                  value: value ? dayjs(value) : "",
                }
              }}
              rules={[{ required: true }]}
              // labelCol={{ span: 6 }}
              // wrapperCol={{ span: 10 }}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label={t("bills.fields.payment_method")}
              name="payment_method"
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  { value: "bank_transfer", label: "Bank Transfer" },
                  { value: "cash", label: "Cash" },
                  { value: "cheque", label: "Cheque" },
                  { value: "credit", label: "Credit" },
                ]}
              />
            </Form.Item>
            <Form.Item
              label={t("bills.fields.payment_through_account_name")}
              name="paid_through_account_id"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                allowClear
                {...paidThroughSelectProps}
                onChange={(value) => {
                  handlePaymentThroughChange(value?.toString())
                }}
                placeholder="Select Account"
              />
            </Form.Item>
            <Form.Item name="paid_through_account_name" hidden>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={22} md={20} lg={18}>
            <Form.List name="bills">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    <Table
                      columns={[
                        {
                          title: t("bills.fields.bill_date"),
                          dataIndex: "bill_date",
                          key: "bill_date",
                          render: (value, record, index) => {
                            return (
                              <>
                                <Typography.Title
                                  level={5}
                                  style={{ margin: 0 }}
                                >
                                  {dayjs(value).format("DD MMM YYYY")}
                                </Typography.Title>

                                <Typography.Text>
                                  Due date:{" "}
                                  <strong>
                                    {dayjs(record[index]?.due_date).format(
                                      "DD MMM YYYY"
                                    )}
                                  </strong>
                                </Typography.Text>
                              </>
                            )
                          },
                        },
                        {
                          title: t("bills.fields.bill_number"),
                          dataIndex: "bill_number",
                          key: "bill_number",
                        },
                        {
                          title: t("bills.fields.bill_amount"),
                          dataIndex: "bill_amount",
                          key: "bill_amount",
                          render: (value) => <strong>{value}</strong>,
                        },
                        {
                          title: t("bills.fields.due_amount"),
                          dataIndex: "due_amount",
                          key: "due_amount",
                          render: (value) => <strong>{value}</strong>,
                        },
                        {
                          title: t("bills.fields.payment_amount"),
                          dataIndex: "payment_amount",
                          key: "payment_amount",
                          render: (value, record, index) => {
                            return (
                              <>
                                <Form.Item
                                  name={[index, "payment_amount"]}
                                  rules={[{ required: true }]}
                                  shouldUpdate={(prevValues, currentValues) => {
                                    return (
                                      prevValues?.bills?.length !==
                                      currentValues?.bills?.length
                                    )
                                  }}
                                  noStyle
                                >
                                  <InputNumber addonBefore="NPR" />
                                </Form.Item>
                                <br />
                                <Form.Item noStyle>
                                  <Typography.Link
                                    onClick={() =>
                                      handlePayfullItemClick(index)
                                    }
                                  >
                                    Pay in Full
                                  </Typography.Link>
                                </Form.Item>
                              </>
                            )
                          },
                        },
                        {
                          title: "",
                          dataIndex: "action",
                          key: "action",
                          render: (value, record, index) => {
                            return (
                              <Typography.Link
                                type="danger"
                                onClick={() => {
                                  remove(index)
                                }}
                              >
                                Remove
                              </Typography.Link>
                            )
                          },
                        },
                      ]}
                      dataSource={formProps?.form?.getFieldValue("bills") || []}
                      pagination={false}
                      rowKey="id"
                    />
                  </div>
                )
              }}
            </Form.List>
          </Col>
        </Row>
        <br />
        <Row gutter={[64, 0]}>
          <Col xs={24} md={{ span: 10, offset: 5 }}>
            <Card
              style={{
                backgroundColor: orange[0],
                lineHeight: 2,
                maxWidth: 450,
              }}
            >
              <Row justify="space-between">
                <Col>
                  <Typography.Text style={{ color: orange[5] }}>
                    Amount used for payment
                  </Typography.Text>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues?.bills !== currentValues?.bills
                    }
                    noStyle
                  >
                    {({ getFieldValue }) => {
                      const bills = getFieldValue("bills") || []
                      const total = bills.reduce(
                        (acc: number, cur: IBill) =>
                          acc + (cur?.payment_amount ?? 0),
                        0
                      )
                      return (
                        <Typography.Text style={{ color: orange[5] }}>
                          {total.toFixed(2)}
                        </Typography.Text>
                      )
                    }}
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row justify="space-between">
                <Col>
                  <Typography.Text style={{ color: orange[5] }}>
                    Additional Charges
                  </Typography.Text>
                </Col>
                <Col>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues?.charge_amount !== currentValues?.charge_amount
                    }
                    noStyle
                  >
                    {({ getFieldValue }) => {
                      const chargeAmount = getFieldValue("charge_amount") || 0
                      return (
                        <Typography.Text style={{ color: orange[5] }}>
                          {chargeAmount.toFixed(2)}
                        </Typography.Text>
                      )
                    }}
                  </Form.Item>
                </Col>
              </Row> */}
            </Card>
          </Col>
          <Col xs={24} sm={16} md={14} lg={10}>
            <Form.Item label="Attachments">
              <Form.Item
                name="files"
                valuePropName="attachments"
                getValueFromEvent={getValueFromEvent}
                noStyle
              >
                <Upload.Dragger
                  name="file"
                  action={`${apiUrl}/files/upload`}
                  listType="picture"
                  maxCount={5}
                  multiple
                  withCredentials
                >
                  <p className="ant-upload-text">
                    Drag & drop a file in this area
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          labelAlign="left"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 10 }}
          label={t("bills.fields.note")}
          name="note"
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Create>
  )
}
