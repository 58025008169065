import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core"
import { Create, Form, Input, useForm, Row, Col } from "@pankod/refine-antd"

import "react-mde/lib/styles/css/react-mde-all.css"

import { IIntegration } from "interfaces"

export const IntegrationCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<IIntegration>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row>
          <Col xs={24} sm={16} md={12}>
            <Form.Item
              label={t("integrations.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Enter integrations name" />
            </Form.Item>
            <Form.Item
              label={t("integrations.fields.description")}
              name="description"
            >
              <Input.TextArea
                placeholder="Description"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              label={t("integrations.fields.url")}
              name="url"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="URL" />
            </Form.Item>
            <Form.Item
              label={t("integrations.fields.api_key")}
              name="api_key"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Password placeholder="API Key" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  )
}
