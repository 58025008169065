import { IEmployee } from "interfaces"
import { MqttMessage } from "types"

import { DeleteOutlined } from "@ant-design/icons"
import { Button, Col, Modal, Row, Tooltip, useModal } from "@pankod/refine-antd"
import { useGetIdentity, useInvalidate, useUpdate } from "@pankod/refine-core"

import constant from "../../constants/mqtt"
import { useEffect } from "react"

interface PropTypes {
  record: IEmployee | undefined
  status: boolean
  message: MqttMessage | null
  publish: (topic: string, message: string) => void
}

export const EmployeeDeleteButton: React.FC<PropTypes> = ({
  record,
  message,
  status,
  publish,
}) => {
  const { data: identity } = useGetIdentity()

  const client_id = identity?.organization?.client_id

  const invalidate = useInvalidate()

  const { modalProps, show } = useModal()

  const { mutate } = useUpdate()

  useEffect(() => {
    if (message) {
      if (message.id === client_id) {
        if (message?.st === "SUCCESS") {
          if (message?.loc === "ENROLLDELETE") {
            if (record) {
              mutate(
                {
                  resource: "employees",
                  id: record.id,
                  values: {
                    is_thumb_registered: false,
                    attendance_thumb: { user_id: null },
                  },
                },
                {
                  onSuccess: () => {
                    invalidate({
                      resource: "employees",
                      invalidates: ["all"],
                    })
                  },
                }
              )
            }
          }
          if (message?.loc === "REGISTERREMOVE") {
            if (record) {
              mutate(
                {
                  resource: "employees",
                  id: record.id,
                  values: {
                    is_thumb_registered: false,
                    attendance_card: { user_id: null },
                  },
                },
                {
                  onSuccess: () => {
                    invalidate({
                      resource: "employees",
                      invalidates: ["all"],
                    })
                  },
                }
              )
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message])

  return (
    <>
      <Tooltip title="Delete" color="red">
        <Button
          danger
          size="small"
          onClick={() => show()}
          disabled={!status}
          icon={<DeleteOutlined />}
        />
      </Tooltip>
      <Modal
        {...modalProps}
        title={
          <span
            style={{
              textAlign: "center",
              display: "block",
            }}
          >
            Delete Employee from Device
          </span>
        }
        // width={700}
        footer={null}
      >
        <Row gutter={[16, 18]}>
          <Col span={12}>
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                if (record) {
                  if (record.attendance_thumb?.user_id) {
                    if (client_id) {
                      publish(
                        constant.TOPIC,
                        `${client_id}-DELETE-${record.attendance_thumb?.user_id}`
                      )
                    }
                  }
                }
              }}
              style={{ width: "100%" }}
            >
              Delete Thumb
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                if (record) {
                  if (record.attendance_card?.user_id) {
                    if (client_id) {
                      publish(
                        constant.TOPIC,
                        `${client_id}-REMOVE-${record.attendance_card?.user_id}`
                      )
                    }
                  }
                }
              }}
              style={{ width: "100%" }}
            >
              Delete Card
            </Button>
          </Col>
        </Row>
        <br />
        <pre>
          Thumb ID: {record?.attendance_thumb?.user_id ?? "null"}
          <br />
          Thumb User Name: {record?.attendance_thumb?.user_name ?? "null"}
          <br />
          Card ID: {record?.attendance_card?.user_id ?? "null"}
          <br />
          Card User Name: {record?.attendance_card?.user_name ?? "null"}
        </pre>
      </Modal>
    </>
  )
}
