import { green } from "@ant-design/colors"
import {
  DeleteButton,
  getDefaultSortOrder,
  List,
  ShowButton,
  Space,
  Table,
  TextField,
  useTable,
  NumberField,
  DateField,
  Row,
  Col,
  Card,
  Statistic,
} from "@pankod/refine-antd"
import {
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useGetIdentity,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core"
import { humanize } from "utils/humanize"
import { formatCurrency } from "utils/format-currency"

import { IPayroll, IPayrollStatistics } from "../../../interfaces"

export const PayrollList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { show } = useNavigation()

  const { tableProps, sorter } = useTable<IPayroll>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  })

  const apiUrl = useApiUrl()

  const { data: stats, isLoading } = useCustom<IPayrollStatistics[]>({
    url: `${apiUrl}/payrolls/statistics`,
    method: "get",
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  })

  const { data: identity } = useGetIdentity()

  const currency = identity?.default?.currency

  return (
    <>
      <List>
        <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
          {stats?.data?.map((item) => {
            return (
              <Col>
                <Card
                  bordered={false}
                  loading={isLoading}
                  style={{ backgroundColor: green[1] }}
                  hoverable
                >
                  <Statistic
                    // prefix="NRS"
                    title={humanize(item.status)}
                    value={formatCurrency(item.total, "NRS")}
                  />
                </Card>
              </Col>
            )
          })}
        </Row>
        <Table {...tableProps} size="small" rowKey="id">
          <Table.Column<IPayroll>
            dataIndex="payroll_number"
            key="payroll_number"
            title={t("payrolls.fields.payroll_number")}
            render={(value: string, record) => (
              <div
                onClick={() => show("payrolls", record.id)}
                style={{ cursor: "pointer" }}
              >
                <NumberField value={value} style={{ color: green[4] }} />
              </div>
            )}
            defaultSortOrder={getDefaultSortOrder("payroll_number", sorter)}
            sorter
          />
          <Table.Column<IPayroll>
            dataIndex="from_date"
            key="from_date"
            title={t("payrolls.fields.from_date")}
            render={(value: string, record) => (
              <DateField
                value={value}
                // onClick={() => show("payrolls", record.id)}
                // style={{ cursor: "pointer" }}
              />
            )}
            defaultSortOrder={getDefaultSortOrder("from_date", sorter)}
            sorter
          />
          <Table.Column<IPayroll>
            dataIndex="to_date"
            key="to_date"
            title={t("payrolls.fields.to_date")}
            render={(value: string, record) => (
              <DateField
                value={value}
                // onClick={() => show("payrolls", record.id)}
                // style={{ cursor: "pointer" }}
              />
            )}
            defaultSortOrder={getDefaultSortOrder("to_date", sorter)}
            sorter
          />
          <Table.Column<IPayroll>
            dataIndex="items"
            key="items"
            title={t("payrolls.fields.employees")}
            render={(value: string, record) => (
              <TextField value={value?.length} />
            )}
          />
          <Table.Column<IPayroll>
            dataIndex="status"
            key="status"
            title={t("payrolls.fields.status")}
            render={(value: string, record) => (
              <TextField value={value ? humanize(value) : ""} />
            )}
          />
          <Table.Column<IPayroll>
            dataIndex="total_payable"
            key="total_payable"
            title={t("payrolls.fields.total_payable")}
            render={(value: string, record) => (
              <NumberField
                value={value}
                // onClick={() => show("payrolls", record.id)}
                // style={{ cursor: "pointer" }}
                options={{
                  style: "currency",
                  currency,
                }}
              />
            )}
            defaultSortOrder={getDefaultSortOrder("total_payable", sorter)}
            sorter
          />
          <Table.Column<IPayroll>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                {/* <EditButton hideText size="small" recordItemId={record.id} /> */}
                <ShowButton hideText size="small" recordItemId={record.id} />
                <DeleteButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </List>
    </>
  )
}
