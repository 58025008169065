import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
  useApiUrl,
} from "@pankod/refine-core"
import { Show, Button, Icons, Row, Col } from "@pankod/refine-antd"

import { ISaleReturnPayment } from "interfaces"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import { Attachments } from "components/Attachments"

export const SaleReturnPaymentShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { queryResult } = useShow<ISaleReturnPayment>()

  const { data, isLoading } = queryResult
  const record = data?.data

  const apiUrl = useApiUrl()

  return (
    <Show
      isLoading={isLoading}
      headerButtons={({ defaultButtons }) => (
        <>
          {defaultButtons}
          <Button
            href={`${apiUrl}/sale-return-payments/pdf/${record?.id}`}
            icon={<Icons.PrinterOutlined style={{ fontSize: 18 }} />}
          >
            Print
          </Button>
        </>
      )}
    >
      <Row gutter={[64, 16]}>
        <Col xs={24} sm={16}>
          {record?.id && (
            <div
              style={{
                backgroundColor: "#f7f7f7",
                display: "flex",
                alignContent: "center",
                textAlign: "center",
              }}
            >
              <Document
                file={{
                  url: `${apiUrl}/sale-return-payments/pdf/${record?.id}`,
                  withCredentials: true,
                }}
                renderMode="canvas"
                className="payment-pdf-section"
              >
                <Page pageNumber={1} renderMode="canvas" width={750} />
              </Document>
            </div>
          )}
        </Col>
        <Col xs={24} sm={8}>
          <Attachments attachments={record?.attachments} />
        </Col>
      </Row>
    </Show>
  )
}
