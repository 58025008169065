import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
  useGetIdentity,
} from "@pankod/refine-core"
import { Show, Typography, MarkdownField, Row, Col } from "@pankod/refine-antd"

import { IItem } from "interfaces"
import { formatCurrency } from "utils/format-currency"

const { Title, Text } = Typography

export const ItemShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { queryResult } = useShow<IItem>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const { data: identity } = useGetIdentity()

  const currency = identity?.default?.currency ?? "NPR"

  return (
    <Show isLoading={isLoading}>
      <Row gutter={16}>
        <Col span={12}>
          <Title level={5}>{t("items.fields.name")}</Title>
          <Text>{record?.name}</Text>

          <Title level={5}>{t("items.fields.unit.title")}</Title>
          <Text>
            <Text>{record?.unit}</Text>
          </Text>

          <Title level={5}>{t("items.fields.sales_description")}</Title>
          <MarkdownField value={record?.sales_description} />
        </Col>
        <Col span={12}>
          <Title level={5}>{t("items.fields.purchase_price")}</Title>
          <Text>{formatCurrency(record?.purchase_price, currency)}</Text>
          <Title level={5}>{t("items.fields.sales_price")}</Title>
          <Text>{formatCurrency(record?.sales_price, currency)}</Text>
        </Col>
      </Row>
    </Show>
  )
}
