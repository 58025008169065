import { Card, Typography } from "@pankod/refine-antd"
import { Progress } from "@ant-design/plots"
import { useGetIdentity, useOne } from "@pankod/refine-core"
import { orange, red } from "@ant-design/colors"
import { formatCurrency } from "utils/format-currency"

const { Title, Text } = Typography

interface IPayable {
  id: string
  open_percent: number
  partially_paid_percent: number
  overdue_percent: number
  open: number
  partially_paid: number
  overdue: number
  total: number
}

export const Payable = () => {
  const { data } = useOne<IPayable>({
    resource: "dashboard/payables",
    id: "",
  })

  const { data: identity } = useGetIdentity()

  const currency = identity?.default?.currency ?? "NPR"

  const percent =
    (data?.data?.open_percent ?? 0) +
      (data?.data?.partially_paid_percent ?? 0) || 0

  const config = {
    autoFit: true,
    height: 20,
    percent,
    color: [orange[2], red[4]],
  }

  return (
    <Card>
      <Title level={5}>Payables</Title>
      <Text>
        Total unpaid invoices:{" "}
        <strong>{formatCurrency(data?.data?.total, currency)}</strong>
      </Text>
      <Progress {...config} />
      <div style={{ display: "flex", gap: 8 }}>
        <div>
          <div style={{ color: orange[4] }}>Open</div>
          <div>{formatCurrency(data?.data?.open, currency)}</div>
        </div>
        <div>
          <div style={{ color: orange[4] }}>Partially Paid</div>
          <div>{formatCurrency(data?.data?.partially_paid, currency)}</div>
        </div>
        <div>
          <div style={{ color: red[4] }}>Overdue</div>
          <div>{formatCurrency(data?.data?.overdue, currency)}</div>
        </div>
      </div>
    </Card>
  )
}
