import { Pie } from "@ant-design/plots"
import { useSimpleList, Typography } from "@pankod/refine-antd"
import { IExpenseSummary } from "interfaces"

const { Title } = Typography

export const ExpenseByCategory: React.FC = () => {
  const { listProps } = useSimpleList<IExpenseSummary>({
    resource: "transactions/reports/expense-summary",

    initialSorter: [
      {
        field: "updated_at",
        order: "desc",
      },
    ],
  })

  return (
    <div style={{ padding: "2em" }}>
      <Title level={5}>Expense By Category</Title>
      <br />
      <Pie
        data={listProps?.dataSource ?? []}
        angleField="total"
        colorField="category"
        radius={1}
        innerRadius={0.6}
        label={{
          type: "inner",
          offset: "-50%",
          content: "{value}",
          style: {
            textAlign: "center",
            fontSize: 14,
          },
          autoRotate: false,
        }}
        autoFit
        height={300}
      />
    </div>
  )
}
