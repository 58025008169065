import { Card, List } from "antd"
import axios from "axios"
import { IItem } from "interfaces"
import { useState } from "react"
import { Input, Modal, Typography, useModal } from "@pankod/refine-antd"
import { useApiUrl, useNotification } from "@pankod/refine-core"

interface Props {
  onItemOk: (item: IItem) => void
}

const { Title } = Typography

export const ItemSearch: React.FC<Props> = ({ onItemOk }) => {
  const apiUrl = useApiUrl()
  const { modalProps, show, close } = useModal()
  const [items, setItems] = useState<IItem[]>([])

  const notification = useNotification()

  const onSearch = async (value: string) => {
    try {
      const { data } = await axios.get(`${apiUrl}/items?q=${value}`, {
        withCredentials: true,
      })
      setItems(data.docs)

      if (data.docs.length === 1) {
        onItemOk(data.docs[0])
        close()
        const message = `${data.docs[0].name} added.`
        notification?.open?.({
          type: "success",
          message,
        })
        return
      }

      if (data.docs.length > 0) {
        show()
      }
    } catch (error) {}
  }

  return (
    <div style={{ marginBottom: "8px" }}>
      <Input.Search
        placeholder="Item name or Barcode"
        enterButton="Search"
        onSearch={onSearch}
      />
      <Modal {...modalProps} width={600}>
        <Title level={4}>Items</Title>
        <List
          dataSource={items}
          renderItem={(item) => (
            <Card
              hoverable
              style={{
                margin: "5px 0",
                cursor: "pointer",
              }}
              onClick={() => {
                onItemOk(item)
                const message = `${item.name} added.`
                notification?.open?.({
                  type: "success",
                  message,
                })
                close()
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>{item.name}</div>
                <div>{item?.barcode}</div>
              </div>
            </Card>
          )}
        />
      </Modal>
    </div>
  )
}
