import { IAccount, ICategory, ITransaction } from "interfaces"

import {
  Col,
  Create,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd"
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core"
import dayjs from "dayjs"
import { humanize } from "utils/humanize"

interface IProps extends IResourceComponentsProps {
  resource?: string
  redirect?: any
  initialValues?: ITransaction
  breadcrumb?: React.ReactNode
  title?: React.ReactNode
  goBack?: React.ReactNode
  fullWidth?: boolean
}

export const TransactionCreate: React.FC<IProps> = (props) => {
  const { resource } = props
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<ITransaction>({
    resource: resource ?? "transactions",
    action: "create",
    // redirect: props?.redirect,
  })

  const { selectProps: accountSelectProps } = useSelect<IAccount>({
    resource: "accounts",
    optionLabel: "name",
    optionValue: "id",
  })

  const { queryResult: categoryQueryResult } = useSelect<ICategory>({
    resource: "categories",
    optionLabel: "name",
    optionValue: "id",
  })

  let initialValues = {
    amount: 0,
  }

  return (
    <Create saveButtonProps={saveButtonProps} {...props}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={
          props.initialValues ? props.initialValues : initialValues
        }
      >
        <Form.Item name="payroll_ids">
          <Input type="hidden" />
        </Form.Item>
        <Row gutter={[32, 0]}>
          <Col
            xs={24}
            sm={props?.fullWidth ? 24 : 18}
            md={props?.fullWidth ? 24 : 16}
            lg={props?.fullWidth ? 24 : 14}
          >
            <Form.Item
              name="amount"
              label={t("transactions.fields.amount")}
              rules={[{ required: true }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                // formatter={(value) =>
                //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                // }
                placeholder="Enter amount"
              />
            </Form.Item>

            <Row gutter={[16, 0]}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="date"
                  label={t("transactions.fields.date")}
                  rules={[{ required: true }]}
                  getValueProps={(value) => {
                    return {
                      value: value ? dayjs(value) : "",
                    }
                  }}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="account_id"
                  label={t("transactions.fields.account_id")}
                  rules={[{ required: true }]}
                >
                  <Select
                    {...accountSelectProps}
                    placeholder="Select account"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="transaction_type"
                  label={t("transactions.fields.transaction_type")}
                  rules={[{ required: true }]}
                >
                  <Select
                    options={[
                      { label: "Income", value: "Income" },
                      { label: "Expense", value: "Expense" },
                    ]}
                    placeholder="Select Transaction Type"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="category_id"
                  label={t("transactions.fields.category_id")}
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select category">
                    {categoryQueryResult?.data?.data?.map((category) => (
                      <Select.Option key={category.id} value={category.id}>
                        {category.name} - {humanize(category.type)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="payment_method"
                  label={t("transactions.fields.payment_method")}
                  rules={[{ required: true }]}
                >
                  <Select
                    options={[
                      { label: "Bank Transfer", value: "bank_transfer" },
                      { label: "Cash", value: "cash" },
                      { label: "Cheque", value: "cheque" },
                    ]}
                    placeholder="Select Transaction Type"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={t("invoices.fields.description")}
              name="description"
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <br />
      </Form>
    </Create>
  )
}
