import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core"
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
  NumberField,
  Icons,
} from "@pankod/refine-antd"
import { ITax } from "interfaces"
import { green, red } from "@ant-design/colors"

export const TaxList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { tableProps, sorter } = useTable<ITax>({
    initialSorter: [
      {
        field: "updated_at",
        order: "desc",
      },
    ],
  })

  return (
    <List>
      <Table {...tableProps} rowKey="id" size="small">
        <Table.Column
          dataIndex="name"
          key="name"
          title={t("tax.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="rate"
          key="rate"
          title={t("tax.fields.rate")}
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("rate", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="is_enabled"
          key="is_enabled"
          title={t("coas.fields.is_enabled")}
          render={(value: boolean) =>
            value ? (
              <Icons.CheckCircleOutlined
                style={{ color: green[5], fontSize: "18px" }}
              />
            ) : (
              <Icons.CloseCircleOutlined
                style={{ color: red[5], fontSize: "18px" }}
              />
            )
          }
          defaultSortOrder={getDefaultSortOrder("is_enabled", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title={t("tax.fields.created_at")}
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />

        <Table.Column<ITax>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
