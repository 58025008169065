import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
} from "@pankod/refine-core"
import { Show, Typography, MarkdownField } from "@pankod/refine-antd"
import { blue, green, red } from "@ant-design/colors"

import { IRole } from "interfaces"
import { humanize } from "utils/humanize"

const { Title, Text } = Typography

export const RoleShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { queryResult } = useShow<IRole>()
  const { data, isLoading } = queryResult
  const record = data?.data

  return (
    <Show
      isLoading={isLoading}
      canDelete={false}
      wrapperProps={{
        style: {
          padding: "0px",
          backgroundColor: "#000",
        },
      }}
    >
      {/* <Title level={5}>{t("roles.fields.name")}</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>{t("roles.fields.account_type")}</Title>
      <Text>
        <Text>{record?.account_type ? humanize(record.account_type) : ""}</Text>
      </Text>

      <Title level={5}>{t("roles.fields.description")}</Title>
      <MarkdownField value={record?.description} /> */}
    </Show>
  )
}
