import {
  Card,
  Col,
  DateField,
  getDefaultSortOrder,
  List,
  NumberField,
  Row,
  Show,
  Table,
  Typography,
  useTable,
} from "@pankod/refine-antd"
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from "@pankod/refine-core"

import { IAccount, ITransaction } from "interfaces"
import { humanize } from "utils/humanize"

const { Title, Text } = Typography

export const AccountShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { queryResult } = useShow<IAccount>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const { tableProps, sorter } = useTable<ITransaction>({
    resource: "transactions",
    initialSorter: [
      {
        field: "updated_at",
        order: "desc",
      },
    ],
    initialFilter: [
      {
        field: "account_id",
        operator: "eq",
        value: record?.id,
      },
    ],
  })

  return (
    <Show isLoading={isLoading} title={record?.name} canDelete={false}>
      <Row>
        <Col span={10}>
          <Card>
            <Row gutter={[32, 16]}>
              {/* <Card hoverable style={{ width: "100%" }}>
              <Title level={5}>{record?.account_number}</Title>
            </Card> */}
              <Col span={12}>
                <Title level={5}>{t("accounts.fields.name")}</Title>
                <Text>{record?.name}</Text>
              </Col>
              <Col span={12}>
                <Title level={5}>{t("accounts.fields.type")}</Title>
                <Text>{humanize(record?.type)}</Text>
              </Col>
              <Col span={12}>
                <Title level={5}>{t("accounts.fields.account_number")}</Title>
                <Text>{record?.account_number}</Text>
              </Col>
              <Col span={12}>
                <Title level={5}>{t("accounts.fields.currency")}</Title>
                <Text>{humanize(record?.currency)}</Text>
              </Col>
              <Col span={12}>
                <Title level={5}>{t("accounts.fields.opening_balance")}</Title>
                <NumberField value={record?.opening_balance ?? 0} />
              </Col>
              <Col span={12}>
                <Title level={5}>{t("accounts.fields.current_balance")}</Title>
                <NumberField value={record?.current_balance ?? 0} />
              </Col>
              <Col span={12}>
                <Title level={5}>{t("accounts.fields.bank_details")}</Title>
                <Text>{record?.bank_name}</Text>
                <br />
                <Text>{record?.bank_address}</Text>
                <br />
                <Text>{record?.bank_phone}</Text>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={14}>
          <List breadcrumb={false} canCreate={false} title="Transactions">
            {/* <Title level={5}>Transactions</Title> */}
            <Table {...tableProps} size="small" rowKey="id">
              <Table.Column<ITransaction>
                dataIndex="updated_at"
                key="updated_at"
                title={t("transactions.fields.updated_at")}
                render={(value: string) => <DateField value={value} />}
                defaultSortOrder={getDefaultSortOrder("updated_at", sorter)}
                sorter
              />
              <Table.Column<ITransaction>
                dataIndex="transaction_number"
                key="transaction_number"
                title={t("transactions.fields.transaction_number")}
                render={(value: string) => <NumberField value={value} />}
                defaultSortOrder={getDefaultSortOrder(
                  "transaction_number",
                  sorter
                )}
                sorter
              />
              <Table.Column<ITransaction>
                dataIndex="transaction_type"
                key="transaction_type"
                title={t("transactions.fields.transaction_type")}
                render={(value: string) => <Text>{humanize(value)}</Text>}
                defaultSortOrder={getDefaultSortOrder(
                  "transaction_type",
                  sorter
                )}
                sorter
              />
              <Table.Column<ITransaction>
                dataIndex="amount"
                key="amount"
                title={t("transactions.fields.amount")}
                render={(value: number) => <NumberField value={value} />}
                defaultSortOrder={getDefaultSortOrder("amount", sorter)}
                sorter
              />
              <Table.Column<ITransaction>
                dataIndex="payment_method"
                key="payment_method"
                title={t("transactions.fields.payment_method")}
                render={(value: string) => <Text>{humanize(value)}</Text>}
                defaultSortOrder={getDefaultSortOrder("payment_method", sorter)}
                sorter
              />
            </Table>
          </List>
        </Col>
      </Row>
    </Show>
  )
}
