import {
  EditButton,
  getDefaultSortOrder,
  List,
  ShowButton,
  Space,
  Table,
  TextField,
  useTable,
} from "@pankod/refine-antd"
import {
  IResourceComponentsProps,
  useGetIdentity,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core"

import { IRole } from "../../interfaces"

export const RoleList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { show } = useNavigation()

  const { tableProps, sorter } = useTable<IRole>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  })

  const { data: identity } = useGetIdentity()

  const roles: string[] = identity?.roles?.map((role: any) => role.code)

  return (
    <List canCreate={roles?.includes("admin")}>
      <Table {...tableProps} size="small" rowKey="id">
        <Table.Column<IRole>
          dataIndex="name"
          key="name"
          title={t("roles.fields.name")}
          render={(value: string, record) => (
            <TextField
              value={value}
              onClick={() => show("roles", record.id)}
              style={{ cursor: "pointer" }}
            />
          )}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="code"
          key="code"
          title={t("roles.fields.code")}
          render={(value: string) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("code", sorter)}
          sorter
        />

        <Table.Column<IRole>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {roles?.includes("admin") && (
                <EditButton hideText size="small" recordItemId={record.id} />
              )}
              <ShowButton hideText size="small" recordItemId={record.id} />
              {/* <DeleteButton hideText size="small" recordItemId={record.id} /> */}
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
