import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core"
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Card,
} from "@pankod/refine-antd"
import { ISMS } from "interfaces"

export const SMSList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { tableProps, sorter } = useTable<ISMS>({
    resource: "notifications/sms",
    initialSorter: [
      {
        field: "updated_at",
        order: "desc",
      },
    ],
    syncWithLocation: true,
  })

  return (
    <List title={t("sms.titles.list")}>
      <Table
        {...tableProps}
        size="small"
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ margin: "0 3rem" }}>
              <Card>{record.message}</Card>
            </div>
          ),
          rowExpandable: (record) => record.message !== "",
        }}
      >
        <Table.Column
          dataIndex="to"
          key="to"
          title={t("sms.fields.to")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("to", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title={t("sms.fields.created_at")}
          render={(value) => (
            <DateField value={value} format={"hh:mm A, DD/MM/YYYY"} />
          )}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />
      </Table>
    </List>
  )
}
