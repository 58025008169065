import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core"
import {
  Col,
  Edit,
  Form,
  Input,
  RefreshButton,
  Row,
  useForm,
} from "@pankod/refine-antd"

import "react-mde/lib/styles/css/react-mde-all.css"

import { IOrganization } from "interfaces"

export const OrganizationEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<IOrganization>({
    redirect: false,
  })

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      headerButtons={({ defaultButtons: _ }) => <RefreshButton />}
    >
      <Form {...formProps} layout="vertical">
        <Row>
          <Col xs={24} md={18} lg={16}>
            <Row gutter={[32, 0]}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("organization.fields.name")}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input organization name!",
                    },
                  ]}
                >
                  <Input placeholder="Enter organization name" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("organization.fields.email")}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input organization's email!",
                    },
                    {
                      type: "email",
                      message: "Please input valid email!",
                    },
                  ]}
                >
                  <Input placeholder="Enter organization's email" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("organization.fields.phone")}
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input organization phone!",
                    },
                  ]}
                >
                  <Input placeholder="Enter organization phone" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("organization.fields.tax_number")}
                  name="tax_number"
                >
                  <Input placeholder="Enter organization's tax number" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label={t("organization.fields.address")} name="address">
              <Input.TextArea placeholder="Enter Address" />
            </Form.Item>
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={12}>
                <Form.Item label={t("organization.fields.city")} name="city">
                  <Input placeholder="Enter City" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t("organization.fields.postal_code")}
                  name="postal_code"
                >
                  <Input placeholder="Enter Postal Code" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label={t("organization.fields.state")} name="state">
                  <Input placeholder="Enter State" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t("organization.fields.country")}
                  name="country"
                >
                  <Input placeholder="Enter Country" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="Client Id" name="client_id">
                  <Input placeholder="Enter client id for attendance device." />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Edit>
  )
}
