import { IEmployee } from "interfaces"
import { useEffect } from "react"
import { MqttMessage } from "types"

import { PlusOutlined } from "@ant-design/icons"
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Tooltip,
  Typography,
  useForm,
  useModal,
} from "@pankod/refine-antd"
import {
  useGetIdentity,
  useInvalidate,
  useNotification,
} from "@pankod/refine-core"

import constant from "../../constants/mqtt"

const { Title } = Typography

interface PropTypes {
  record: IEmployee | undefined
  status: boolean
  message: MqttMessage | null
  publish: (topic: string, message: string) => void
}

export const RegisterEmployeeButton: React.FC<PropTypes> = ({
  record,
  message,
  status,
  publish,
}) => {
  const { data: identity } = useGetIdentity()

  const client_id = identity?.organization?.client_id

  const notification = useNotification()

  const invalidate = useInvalidate()

  const { modalProps, show } = useModal()

  const { formProps } = useForm({
    action: "edit",
    resource: `employees/${record?.id}`,
    redirect: false,
    onMutationSuccess: () => {
      invalidate({
        resource: "employees",
        invalidates: ["list"],
      })
    },
    successNotification: () => {
      return {
        message: "Employee updated successfully",
        type: "success",
      }
    },
  })

  useEffect(() => {
    if (message) {
      if (message.id === client_id) {
        console.log("message", message)
        if (message?.t === "D") {
          console.log("message", message)
          if (message?.loc === "REGISTER") {
            formProps?.form?.setFieldsValue({
              attendance_card: {
                user_id: message.msg,
              },
            })
            formProps?.form?.setFieldsValue({
              is_card_registered: true,
            })
          }
        }

        if (
          message?.loc === "attendance_thumb" ||
          message?.loc === "attendance_card"
        ) {
          invalidate({
            resource: "attendance",
            invalidates: ["list"],
          })
        }

        if (message.st === "SUCCESS") {
          if (message?.loc === "REGISTERADD") {
            formProps?.form?.submit()
          }

          if (message?.loc === "ENROLLADD") {
            formProps?.form?.setFieldsValue({
              is_thumb_registered: true,
            })
            formProps?.form?.submit()
          }
        }
      }
    }
  }, [client_id, formProps?.form, invalidate, message])

  // Clean up
  useEffect(() => {
    return () => {
      formProps?.form?.resetFields()
    }
  }, [formProps?.form])

  return (
    <>
      <Tooltip title="Register Employee">
        <Button
          size="small"
          onClick={() => show()}
          disabled={!status}
          icon={<PlusOutlined />}
        />
      </Tooltip>
      <Modal
        {...modalProps}
        title="Register Employee"
        width={700}
        footer={null}
      >
        {/* {renderMessage()} */}
        <Form
          {...formProps}
          layout="vertical"
          initialValues={{
            attendance_card: {
              user_id: record?.attendance_card?.user_id,
              user_name: record?.attendance_card?.user_name || record?.name,
            },
            attendance_thumb: {
              user_id: record?.attendance_thumb?.user_id,
              user_name: record?.attendance_thumb?.user_name || record?.name,
            },
            is_thumb_registered: record?.is_thumb_registered,
            is_card_registered: record?.is_card_registered,
          }}
        >
          <Row gutter={[20, 0]}>
            <Col span={12}>
              <Title level={5}>Card</Title>
              <Button
                onClick={() => {
                  if (client_id) {
                    notification?.open?.({
                      type: "success",
                      message: "Register",
                      description: "Register command sent to device.",
                    })
                    publish(
                      constant.TOPIC,
                      `${client_id}-${constant.REGISTER_PAYLOAD}`
                    )
                  }
                }}
                style={{ width: "100%", marginBottom: "10px" }}
              >
                Register
              </Button>
              <Form.Item label="User ID" name={["attendance_card", "user_id"]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="User Name"
                name={["attendance_card", "user_name"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Registered"
                name="is_card_registered"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Button
                    type="primary"
                    onClick={() => {
                      if (client_id) {
                        notification?.open?.({
                          type: "success",
                          message: "Send to Device",
                          description: "Send to Device command sent to device.",
                        })
                        const user_id = formProps?.form?.getFieldValue([
                          "attendance_card",
                          "user_id",
                        ])
                        const user_name = formProps?.form?.getFieldValue([
                          "attendance_card",
                          "user_name",
                        ])

                        const payload = `ID-${user_name}-${user_id}`

                        formProps?.form?.submit()

                        publish(constant.TOPIC, `${client_id}-${payload}`)
                      }
                    }}
                    style={{ width: "100%" }}
                  >
                    Send to Device & Save
                  </Button>
                </Col>
                {/* <Col span={12}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault()
                      formProps?.form?.submit()
                    }}
                    // type="primary"
                    style={{ width: "100%" }}
                  >
                    Save to Database
                  </Button>
                </Col> */}
              </Row>
            </Col>
            <Col span={12}>
              <Title level={5}>Thumb</Title>
              <Button
                onClick={() => {
                  if (client_id) {
                    notification?.open?.({
                      type: "success",
                      message: "Enroll",
                      description: "Enroll command sent to device.",
                    })
                    return publish(
                      constant.TOPIC,
                      `${client_id}-${constant.ENROLL_PAYLOAD}`
                    )
                  }
                }}
                style={{ width: "100%", marginBottom: "10px" }}
              >
                Enroll
              </Button>
              <Form.Item
                label="User ID"
                name={["attendance_thumb", "user_id"]}
                // rules={[
                //   {
                //     required: true,
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="User Name"
                name={["attendance_thumb", "user_name"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Registered"
                name="is_thumb_registered"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Button
                    type="primary"
                    onClick={() => {
                      if (client_id) {
                        notification?.open?.({
                          type: "success",
                          message: "Send to Device",
                          description: "Send to Device command sent to device.",
                        })
                        const user_id = formProps?.form?.getFieldValue([
                          "attendance_thumb",
                          "user_id",
                        ])
                        const user_name = formProps?.form?.getFieldValue([
                          "attendance_thumb",
                          "user_name",
                        ])

                        const payload = `ID-${user_name}-${user_id}`

                        formProps?.form?.submit()

                        return publish(
                          constant.TOPIC,
                          `${client_id}-${payload}`
                        )
                      }
                    }}
                    style={{ width: "100%" }}
                  >
                    Send to Device & Save
                  </Button>
                </Col>
                {/* <Col span={12}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault()
                      formProps?.form?.submit()
                    }}
                    // type="primary"
                    style={{ width: "100%" }}
                  >
                    Save to Database
                  </Button>
                </Col> */}
              </Row>
            </Col>
          </Row>
          <br />
        </Form>
      </Modal>
    </>
  )
}
