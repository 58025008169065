import { useTranslate } from "@pankod/refine-core"
import {
  DateField,
  List,
  NumberField,
  Table,
  TextField,
} from "@pankod/refine-antd"
import { ISaleReturn } from "interfaces"
import { humanize } from "utils/humanize"

export default function CustomerSaleReturn({
  sale_returns,
}: {
  sale_returns?: ISaleReturn[]
}) {
  const t = useTranslate()

  return (
    <List breadcrumb={false} canCreate={false} title="">
      <Table dataSource={sale_returns} rowKey="id" size="small">
        <Table.Column
          dataIndex="due_date"
          key="due_date"
          title={t("sale_returns.fields.due_date")}
          render={(value: string) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex="invoice_date"
          key="invoice_date"
          title={t("sale_returns.fields.invoice_date")}
          render={(value: string) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex="invoice_number"
          key="invoice_number"
          title={t("sale_returns.fields.invoice_number")}
          render={(value: string) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="status"
          key="status"
          title={t("sale_returns.fields.status.title")}
          render={(value: string) => (
            <TextField value={value ? humanize(value) : ""} />
          )}
        />
        <Table.Column
          dataIndex="total"
          key="total"
          title={t("sale_returns.fields.total")}
          render={(value: string) => (
            <NumberField
              value={value}
              options={{
                style: "currency",
                currency: "NPR",
              }}
            />
          )}
        />
      </Table>
    </List>
  )
}
