import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
  useGetIdentity,
  useUpdate,
  useApiUrl,
} from "@pankod/refine-core"
import {
  Card,
  Col,
  NumberField,
  Row,
  Show,
  Statistic,
  Table,
  Icons,
  Typography,
  Button,
  Space,
  Timeline,
  Tag,
  TagField,
} from "@pankod/refine-antd"
import { green, red, orange } from "@ant-design/colors"

import { ILedgerEntry, IPayroll } from "interfaces"
import { humanize } from "utils/humanize"
import PayrollPaymentButton from "components/payroll/PayrollPaymentButton"
import moment from "moment"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"

const { Title, Text } = Typography

export const PayrollShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { queryResult } = useShow<IPayroll>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const { data: identity } = useGetIdentity()
  const currency = identity?.default?.currency

  const { mutate } = useUpdate<IPayroll>()

  const handleApprove = () => {
    mutate({
      resource: "payrolls",
      id: record?.id!,
      values: {
        status: "approved",
        events: [
          ...record?.events!,
          {
            status: "approved",
          },
        ],
      },
    })
  }

  const apiUrl = useApiUrl()

  const ledgerEntries: ILedgerEntry[] = record?.ledger_entries
    ? [
        ...record?.ledger_entries,
        {
          id: "total",
          debit: record?.ledger_entries?.reduce(
            (acc, cur) => acc + cur.debit,
            0
          ),
          credit: record?.ledger_entries?.reduce(
            (acc, cur) => acc + cur.credit,
            0
          ),
        },
      ]
    : []

  return (
    <Show
      isLoading={isLoading}
      headerButtons={({ defaultButtons }) => (
        <>
          <StatusButton status={record?.status} />
          {defaultButtons}
        </>
      )}
    >
      {/* Statistics Section */}
      <section style={{ marginBottom: "1em" }}>
        <Row gutter={[16, 8]}>
          <Col xs={24} sm={8}>
            <Card>
              <Statistic
                title="Total Payable"
                value={record?.total_payable}
                precision={2}
                valueStyle={{ color: orange[5] }}
                prefix={currency}
              />
            </Card>
          </Col>
          <Col xs={24} sm={8}>
            <Card>
              <Statistic
                title="Total Deduction"
                value={record?.total_deduction}
                precision={2}
                valueStyle={{ color: red[5] }}
                prefix={currency}
              />
            </Card>
          </Col>
          <Col xs={24} sm={8}>
            <Card>
              <Statistic
                title="Total Benefit"
                value={record?.total_benefit}
                precision={2}
                valueStyle={{ color: green[5] }}
                prefix={currency}
              />
            </Card>
          </Col>
        </Row>
      </section>
      <Title level={5}>Employees</Title>
      <Table
        dataSource={record?.items}
        pagination={false}
        size="small"
        style={{ width: "100%" }}
        columns={[
          {
            title: "SN",
            dataIndex: "id",
            fixed: "left",
            render: (_value, _record, index) => index + 1,
          },
          {
            title: t("payrolls.fields.employee"),
            dataIndex: "employee_name",
            fixed: "left",
          },
          {
            title: t("payrolls.fields.department"),
            dataIndex: "department",
          },
          {
            title: t("payrolls.fields.basic_salary"),
            dataIndex: "basic_salary",
            render: (value) => (
              <NumberField
                value={value}
                options={{
                  style: "currency",
                  currency: "NPR",
                }}
              />
            ),
          },
          {
            title: t("payrolls.fields.total_benefit"),
            dataIndex: "total_benefit",
            render: (value) => (
              <NumberField
                value={value}
                options={{
                  style: "currency",
                  currency: "NPR",
                }}
              />
            ),
          },
          {
            title: t("payrolls.fields.total_deduction"),
            dataIndex: "total_deduction",
            render: (value) => (
              <NumberField
                value={value}
                options={{
                  style: "currency",
                  currency: "NPR",
                }}
              />
            ),
          },
          {
            title: t("payrolls.fields.total_payable"),
            dataIndex: "total_payable",
            render: (value) => (
              <NumberField
                value={value}
                options={{
                  style: "currency",
                  currency: "NPR",
                }}
              />
            ),
          },
          {
            title: "print",
            dataIndex: "id",
            render: (value, r) => (
              <Button
                type="link"
                href={`${apiUrl}/payrolls/pdf/${record?.id}/employee/${r.employee_id}`}
                icon={<Icons.PrinterOutlined style={{ fontSize: 18 }} />}
              >
                Print
              </Button>
            ),
          },
        ]}
      />
      <br />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          {/* Status and Payment Section */}
          {/* <Text strong>Status: {humanize(record?.status)}</Text> */}
          <section style={{ marginBottom: "1em" }}>
            <Card>
              {record?.status === "draft" && (
                <>
                  <Title level={5}>Approve</Title>
                  <Text>
                    Approve this payroll to make it available for payment.
                  </Text>
                  <br />
                  <br />
                  <Button onClick={handleApprove}>Approve</Button>
                </>
              )}
              {record?.status === "approved" && (
                <>
                  <Title level={5}>Payment</Title>
                  <Text>Make a payment for this payroll.</Text>
                  <br />
                  <br />
                  {/* <Button onClick={handleApprove}>Approve</Button> */}
                  {record && <PayrollPaymentButton record={record} />}
                </>
              )}
            </Card>
          </section>

          <section>
            <Title level={5}>Journal</Title>
            <Table
              dataSource={ledgerEntries}
              columns={[
                {
                  title: t("invoices.fields.coa_name"),
                  dataIndex: "coa",
                  key: "coa",
                  render: (_text, record, index) => (
                    <>
                      <Text>{record?.coa?.name}</Text>
                    </>
                  ),
                },
                {
                  title: t("invoices.fields.debit"),
                  dataIndex: "debit",
                  key: "debit",
                  render: (_text, record, index) => (
                    <>
                      <Text> {record?.debit?.toFixed(2)}</Text>
                    </>
                  ),
                },
                {
                  title: t("invoices.fields.credit"),
                  dataIndex: "credit",
                  key: "credit",
                  render: (_text, record, index) => (
                    <>
                      <Text> {record?.credit?.toFixed(2)}</Text>
                    </>
                  ),
                },
              ]}
              pagination={false}
              size="small"
            />
          </section>
          <section>
            <Space size="small" direction="vertical">
              <Title level={5}>Events</Title>
              <Timeline>
                {record?.events?.map((event, index) => (
                  <Timeline.Item key={index}>
                    <Tag color="blue">{humanize(event.status)}</Tag>
                    <Text>
                      {moment(event.created_at).format("DD MMM YYYY")} -{" "}
                      {event.status === "draft" && (
                        <Text>
                          A payroll created by{" "}
                          <Text strong>{record?.created_by?.name ?? "--"}</Text>
                          {"."}
                        </Text>
                      )}
                      {event.status === "approved" && (
                        <Text>A payroll is approved.</Text>
                      )}
                      {event.status === "paid" && (
                        <Text>A payroll is paid.</Text>
                      )}
                    </Text>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Space>
          </section>

          <br />
        </Col>
        <Col xs={24} sm={12}>
          {record?.id && (
            <div
              style={{
                backgroundColor: "#f7f7f7",
                display: "flex",
                alignContent: "center",
                textAlign: "center",
              }}
            >
              <Document
                file={{
                  url: `${apiUrl}/payrolls/pdf/${record?.id}`,
                  withCredentials: true,
                }}
                renderMode="canvas"
                className="payment-pdf-section"
              >
                <Page pageNumber={1} renderMode="canvas" width={560} />
              </Document>
            </div>
            // <div dangerouslySetInnerHTML={{ __html: record?.html }}></div>
          )}
        </Col>
      </Row>
    </Show>
  )
}

const StatusButton: React.FC<{
  status:
    | "draft"
    | "approved"
    | "paid"
    | "cancelled"
    | "partially_paid"
    | undefined
}> = ({ status }) => {
  const statusColor = {
    draft: "magenta",
    approved: "blue",
    paid: "green",
    cancelled: "red",
    partially_paid: "purple",
  }

  if (!status) return null

  return (
    <TagField
      color={statusColor[status]}
      value={humanize(status)}
      style={{ fontSize: "0.75rem", padding: "0.3rem" }}
    />
  )
}
