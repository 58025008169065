import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core"
import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  Row,
  Col,
  Checkbox,
  Typography,
} from "@pankod/refine-antd"

import { ICustomer } from "interfaces"

const { Title } = Typography

export const CustomerCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<ICustomer>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 0]}>
          <Col span={14}>
            <Form.Item
              label={t("customers.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
            <Row gutter={[16, 0]}>
              <Col xs={12}>
                <Form.Item label={t("customers.fields.email")} name="email">
                  <Input placeholder="Enter Email" />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label={t("customers.fields.phone")}
                  name="phone"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Enter Phone" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={12}>
                <Form.Item label={t("customers.fields.website")} name="website">
                  <Input placeholder="Enter Website" />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label={t("customers.fields.reference")}
                  name="reference"
                >
                  <Input placeholder="Enter Reference" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 0]}>
              <Col xs={12}>
                <Form.Item
                  label={t("customers.fields.tax_number")}
                  name="tax_number"
                >
                  <Input placeholder="Enter Tax Number" />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label={t("customers.fields.currency")}
                  name="currency"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    options={[{ id: "NPR", value: "Nepali Rupees" }]}
                    placeholder="Select Currency"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label={t("customers.fields.address")} name="address">
              <Input.TextArea placeholder="Enter Address" />
            </Form.Item>
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={12}>
                <Form.Item label={t("customers.fields.city")} name="city">
                  <Input placeholder="Enter City" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t("customers.fields.postal_code")}
                  name="postal_code"
                >
                  <Input placeholder="Enter Postal Code" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label={t("customers.fields.state")} name="state">
                  <Input placeholder="Enter State" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label={t("customers.fields.country")} name="country">
                  <Input placeholder="Enter Country" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="activated"
                  label={t("customers.fields.activated")}
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={["notification", "email"]}
                  label={t("customers.fields.notification_email")}
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={["notification", "sms"]}
                  label={t("customers.fields.notification_sms")}
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Title level={5}>Social Media</Title>
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t("customers.fields.fb")}
                  name={["social", "fb"]}
                >
                  <Input placeholder="Enter Facebook" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t("customers.fields.viber")}
                  name={["social", "viber"]}
                >
                  <Input placeholder="Enter Viber" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t("customers.fields.whatsapp")}
                  name={["social", "whatsapp"]}
                >
                  <Input placeholder="Enter Whatsapp" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Create>
  )
}
