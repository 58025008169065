import { AuthProvider } from "@pankod/refine-core"
import axios from "axios"

export const ORGANIZATION_ID = "organization-id"

const API_URL = process.env.REACT_APP_API_URL!

const axiosInstance = axios.create({
  withCredentials: true,
})

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    try {
      const { data } = await axiosInstance.post(
        `${API_URL}/users/login`,
        {
          username,
          password,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
          },
        }
      )

      localStorage.setItem(ORGANIZATION_ID, data?.organization_id)

      return Promise.resolve()
    } catch (error) {
      return Promise.reject()
    }

    // return Promise.reject(new Error("username: admin, password: admin"))
  },
  logout: async () => {
    try {
      const { data: _ } = await axiosInstance.post(`${API_URL}/users/logout`, {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
      localStorage.removeItem(ORGANIZATION_ID)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject()
    }
  },
  checkError: async () => {
    try {
      const { data } = await axiosInstance.get(`${API_URL}/users/auth`, {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject()
    }
  },
  checkAuth: async () => {
    try {
      const { data } = await axiosInstance.get(`${API_URL}/users/auth`, {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject()
    }
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    try {
      const { data } = await axiosInstance.get(`${API_URL}/users/auth`, {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject()
    }
  },
}
