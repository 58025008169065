import { useTranslate } from "@pankod/refine-core"
import {
  DateField,
  List,
  NumberField,
  Table,
  TextField,
} from "@pankod/refine-antd"
import { IPurchaseReturn } from "interfaces"
import { humanize } from "utils/humanize"

export default function VendorPurchaseReturn({
  purchase_returns,
}: {
  purchase_returns?: IPurchaseReturn[]
}) {
  const t = useTranslate()

  return (
    <List breadcrumb={false} canCreate={false} title="">
      <Table dataSource={purchase_returns} rowKey="id" size="small">
        <Table.Column
          dataIndex="purchase_return_date"
          key="purchase_return_date"
          title={t("purchase_returns.fields.purchase_return_date")}
          render={(value: string) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex="bill_date"
          key="bill_date"
          title={t("purchase_returns.fields.bill_date")}
          render={(value: string) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex="purchase_return_date"
          key="purchase_return_date"
          title={t("purchase_returns.fields.purchase_return_date")}
          render={(value: string) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex="bill_number"
          key="bill_number"
          title={t("purchase_returns.fields.bill_number")}
          render={(value: string) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="status"
          key="status"
          title={t("purchase_returns.fields.status.title")}
          render={(value: string) => (
            <TextField value={value ? humanize(value) : ""} />
          )}
        />
        <Table.Column
          dataIndex="total"
          key="total"
          title={t("purchase_returns.fields.total")}
          render={(value: string) => (
            <NumberField
              value={value}
              options={{
                style: "currency",
                currency: "NPR",
              }}
            />
          )}
        />
      </Table>
    </List>
  )
}
