import { blue } from "@ant-design/colors"
import {
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  List,
  ShowButton,
  Space,
  Table,
  TextField,
  useTable,
  EmailField,
  TagField,
} from "@pankod/refine-antd"
import {
  IResourceComponentsProps,
  useGetIdentity,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core"

import { IOrganization, IRole, IUser } from "../../interfaces"

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { show } = useNavigation()

  const { tableProps, sorter } = useTable<IUser>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  })

  const { data: identity } = useGetIdentity()

  const roles: string[] = identity?.roles?.map((role: any) => role.code)

  return (
    <List canCreate={roles?.includes("admin")}>
      <Table {...tableProps} size="small" rowKey="id">
        <Table.Column<IUser>
          dataIndex="name"
          key="name"
          title={t("users.fields.name")}
          render={(value: string, record) => (
            <TextField
              value={value}
              onClick={() => show("users", record.id)}
              style={{ cursor: "pointer" }}
            />
          )}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="email"
          key="email"
          title={t("users.fields.email")}
          render={(value: string) => <EmailField value={value} />}
          defaultSortOrder={getDefaultSortOrder("email", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="phone"
          key="phone"
          title={t("users.fields.phone")}
          render={(value: string) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("phone", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="roles"
          key="roles"
          title={t("users.fields.roles")}
          render={(value: IRole[]) => (
            <Space split=",">
              {value?.map((role: IRole) => (
                <TagField value={role?.name} color={blue.primary} />
              ))}
            </Space>
          )}
        />
        <Table.Column
          dataIndex="organization"
          key="organization"
          title={t("users.fields.organization")}
          render={(organization: IOrganization) => (
            <TextField value={organization?.name} />
          )}
        />
        <Table.Column<IUser>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {roles?.includes("admin") && (
                <EditButton hideText size="small" recordItemId={record.id} />
              )}
              <ShowButton hideText size="small" recordItemId={record.id} />
              {roles?.includes("admin") && (
                <DeleteButton hideText size="small" recordItemId={record.id} />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
