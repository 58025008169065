import { IResourceComponentsProps, useShow } from "@pankod/refine-core"
import { Show } from "@pankod/refine-antd"

import { IUser } from "interfaces"

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IUser>()
  const { isLoading } = queryResult

  return (
    <Show
      isLoading={isLoading}
      canDelete={false}
      wrapperProps={{
        style: {
          padding: "0px",
          backgroundColor: "#000",
        },
      }}
    ></Show>
  )
}
