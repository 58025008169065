import { IResourceComponentsProps, useShow } from "@pankod/refine-core"
import {
  Show,
  Typography,
  Row,
  Col,
  Avatar,
  Space,
  Divider,
  Collapse,
  Tabs,
} from "@pankod/refine-antd"
import { Icons } from "@pankod/refine-antd"
import millify from "millify"

import { ICustomer } from "interfaces"
import CustomerInvoice from "./customer_invoice"
import CustomerTransaction from "./customer_transaction"
import CustomerSaleReturn from "./customer_sale_return"

const { UserOutlined } = Icons
const { Title, Text, Paragraph, Link } = Typography
const { Panel } = Collapse

export const CustomerShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<ICustomer>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const over_due_amount = record?.invoices
    ?.filter((invoice) => ["sent", "partially_pad"].includes(invoice.status))
    .reduce((acc, invoice) => {
      const unpaid = invoice.due_date < new Date().toISOString()
      return acc + (unpaid ? invoice.total - invoice.paid_amount : 0)
    }, 0)

  const total_open_amount = record?.invoices
    ?.filter((invoice) => ["sent"].includes(invoice.status))
    .reduce((acc, invoice) => acc + (invoice.total - invoice.paid_amount), 0)

  const total_paid = record?.invoices
    ?.filter((invoice) => ["paid"].includes(invoice.status))
    .reduce((acc, invoice) => acc + invoice.paid_amount, 0)

  return (
    <Show isLoading={isLoading} title={record?.name}>
      <Row>
        <Col span={12}>
          <Space size="middle">
            <Avatar size={64} icon={<UserOutlined />} />
            <div>
              <Title level={4} style={{ marginBottom: "2px" }}>
                {record?.name}
              </Title>
              <Space direction="vertical" size={1}>
                <Link href={`mailto:${record?.email}`}>{record?.email}</Link>
                <Link href={`tel:${record?.phone}`}>{record?.phone}</Link>
              </Space>
            </div>
          </Space>
        </Col>
        <Col span={12}>
          <Row justify="space-evenly">
            <Col span={8} style={{ textAlign: "center" }}>
              <Text strong>Overdue</Text>
              <Title level={2} style={{ margin: "0" }}>
                Rs{" "}
                {over_due_amount
                  ? millify(over_due_amount, { precision: 2 })
                  : "0.00"}
              </Title>
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>
              <Text strong>Open</Text>
              <Title level={2} style={{ margin: "0" }}>
                Rs{" "}
                {total_open_amount
                  ? millify(total_open_amount, { precision: 2 })
                  : "0.00"}
              </Title>
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>
              <Text strong>Paid</Text>
              <Title level={2} style={{ margin: "0" }}>
                Rs {total_paid ? millify(total_paid, { precision: 2 }) : "0.00"}
              </Title>
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      <Divider style={{ margin: 0 }} />
      <section style={{ margin: 0 }}>
        <Row>
          <Col
            span={8}
            style={{ backgroundColor: "#f7f7f7", padding: "0.5em" }}
          >
            <Collapse defaultActiveKey={["1", "2"]} ghost>
              <Panel header="Address" key="1">
                <Text strong>Address</Text>
                <Paragraph>{record?.address}</Paragraph>
                <Row>
                  <Col span={12}>
                    <Text strong>City</Text>
                    <Paragraph>{record?.city ?? "--"}</Paragraph>
                  </Col>
                  <Col span={12}>
                    <Text strong>State</Text>
                    <Paragraph>{record?.state ?? "--"}</Paragraph>
                  </Col>
                  <Col span={12}>
                    <Text strong>Country</Text>
                    <Paragraph>{record?.country ?? "--"}</Paragraph>
                  </Col>
                  <Col span={12}>
                    <Text strong>Zip Code</Text>
                    <Paragraph>{record?.postal_code ?? "--"}</Paragraph>
                  </Col>
                </Row>
              </Panel>
              <Panel header="Social Links" key="2">
                <Row>
                  <Col span={12}>
                    <Text strong>Facebook</Text>
                    <Paragraph>{record?.social?.fb ?? "--"}</Paragraph>
                  </Col>
                  <Col span={12}>
                    <Text strong>Whatsapp</Text>
                    <Paragraph>{record?.social?.whatsapp ?? "--"}</Paragraph>
                  </Col>
                  <Col span={12}>
                    <Text strong>Viber</Text>
                    <Paragraph>{record?.social?.viber ?? "--"}</Paragraph>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
          <Col span={16} style={{ padding: "0 0.5em" }}>
            <Tabs>
              <Tabs.TabPane id="invoices" tab="Invoices" key="1">
                <CustomerInvoice invoices={record?.invoices} />
              </Tabs.TabPane>
              <Tabs.TabPane id="sale_returns" tab="Sale Returns" key="2">
                <CustomerSaleReturn sale_returns={record?.sale_returns} />
              </Tabs.TabPane>
              <Tabs.TabPane id="transactions" tab="Transactions" key="3">
                <CustomerTransaction transactions={record?.transactions} />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </section>
    </Show>
  )
}
