import {
  Authenticated,
  useGetIdentity,
  useRouterContext,
} from "@pankod/refine-core"
import { Avatar, Card, Col, Icons, Row, Typography } from "@pankod/refine-antd"
import { red, green, orange } from "@ant-design/colors"

const { Title } = Typography

export default function CustomerServiceList() {
  const { Link } = useRouterContext()

  const { data: identity } = useGetIdentity()

  const customerServices = [
    {
      title: "SMS",
      description: "All SMS messages",
      icon: <Icons.BellOutlined style={{ color: green[5] }} />,
      path: `/customer-service/sms`,
    },
    {
      title: "Mails",
      description: "All mails",
      icon: <Icons.MailOutlined style={{ color: orange[5] }} />,
      path: `/customer-service/mails`,
      bgcolor: orange[1],
    },
  ]

  if (identity?.is_superadmin) {
    customerServices.unshift({
      title: "Contact Us",
      description: "All contact us messages",
      icon: <Icons.CustomerServiceOutlined style={{ color: red[5] }} />,
      path: `/customer-service/contact-us`,
      bgcolor: red[1],
    })
  }

  return (
    <Authenticated>
      <div style={{ margin: "0.5em", marginTop: "1em" }}>
        <section style={{ marginLeft: "1em" }}>
          <Title level={5}>Customer Service</Title>
          <Row gutter={[24, 16]} style={{ margin: "0" }}>
            {customerServices.map((report) => (
              <Col xs={12} sm={6}>
                <Link to={report.path}>
                  <Card size="small" hoverable>
                    <Card.Meta
                      avatar={
                        <Avatar
                          icon={report.icon}
                          size="large"
                          shape="square"
                          style={{
                            backgroundColor: report?.bgcolor ?? green[1],
                          }}
                        />
                      }
                      title={report.title}
                      description={report.description}
                    />
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </section>
      </div>
    </Authenticated>
  )
}
