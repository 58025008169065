import { green } from "@ant-design/colors"
import {
  getDefaultSortOrder,
  List,
  NumberField,
  ShowButton,
  Space,
  Table,
  TextField,
  useTable,
} from "@pankod/refine-antd"
import {
  IResourceComponentsProps,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core"
import { IAccount } from "interfaces"

export const AccountList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { show } = useNavigation()

  const { tableProps, sorter } = useTable<IAccount>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  })

  return (
    <>
      <List>
        {/* <Space style={{ marginBottom: "1rem" }}>
          <Card>
            <Title level={5}>Hi</Title>
          </Card>
          <Card>Hi</Card>
          <Card>Hi</Card>
        </Space> */}
        <Table {...tableProps} size="small" rowKey="id">
          <Table.Column<IAccount>
            dataIndex="name"
            key="name"
            title={t("accounts.fields.name")}
            render={(value: string, record) => (
              <TextField
                value={value}
                onClick={() => show("accounts", record.id)}
                style={{ color: green[4], cursor: "pointer" }}
              />
            )}
            defaultSortOrder={getDefaultSortOrder("name", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="account_number"
            key="account_number"
            title={t("accounts.fields.account_number")}
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("sales_description", sorter)}
            sorter
          />

          <Table.Column
            dataIndex="current_balance"
            key="current_balance"
            title={t("accounts.fields.current_balance")}
            render={(value) => (
              <NumberField
                value={value}
                options={{
                  style: "currency",
                  currency: "NPR",
                }}
              />
            )}
            defaultSortOrder={getDefaultSortOrder("current_balance", sorter)}
            sorter
          />
          <Table.Column<IAccount>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                {/* <EditButton hideText size="small" recordItemId={record.id} /> */}
                <ShowButton hideText size="small" recordItemId={record.id} />
                {/* <DeleteButton hideText size="small" recordItemId={record.id} /> */}
              </Space>
            )}
          />
        </Table>
      </List>
    </>
  )
}
