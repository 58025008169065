import { MarkdownField, Show, Typography } from "@pankod/refine-antd"
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from "@pankod/refine-core"

import { ICoa } from "interfaces"
import { humanize } from "utils/humanize"

const { Title, Text } = Typography

export const CoaShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { queryResult } = useShow<ICoa>()
  const { data, isLoading } = queryResult
  const record = data?.data

  return (
    <Show
      isLoading={isLoading}
      canDelete={false}
      canEdit={record?.is_editable === true}
    >
      <Title level={5}>{t("coas.fields.name")}</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>{t("coas.fields.account_type")}</Title>
      <Text>
        <Text>{record?.account_type ? humanize(record.account_type) : ""}</Text>
      </Text>

      <Title level={5}>{t("coas.fields.description")}</Title>
      <MarkdownField value={record?.description} />
    </Show>
  )
}
