import {
  Col,
  Create,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd"
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core"
import { ITransfer } from "interfaces"

const TransferCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<ITransfer>()
  const t = useTranslate()

  const { selectProps: accountsSelectProps } = useSelect<ITransfer>({
    resource: "accounts",
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
    fetchSize: 500,
  })

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          amount: 0.0,
        }}
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        labelAlign="left"
      >
        <Row>
          <Col xs={24} md={16} lg={14} xl={12}>
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="from_account_id"
                  label={t("transfers.fields.from_account_id")}
                  rules={[{ required: true }]}
                >
                  <Select
                    {...accountsSelectProps}
                    placeholder="Select From Account"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="to_account_id"
                  label={t("transfers.fields.to_account_id")}
                  rules={[{ required: true }]}
                >
                  <Select
                    {...accountsSelectProps}
                    placeholder="Select To Account"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="date"
                  label={t("transfers.fields.date")}
                  rules={[{ required: true }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="amount"
                  label={t("transfers.fields.amount")}
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    prefix="NPR"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="description"
                  label={t("transfers.fields.description")}
                >
                  <Input.TextArea rows={4} placeholder="Description" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="payment_method"
                  label={t("transfers.fields.payment_method")}
                  rules={[{ required: true }]}
                >
                  <Select
                    options={[
                      { label: "Cash", value: "cash" },
                      { label: "Cheque", value: "cheque" },
                      { label: "Bank Transfer", value: "bank_transfer" },
                      { label: "Other", value: "other" },
                    ]}
                    placeholder="Select Payment Method"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="reference"
                  label={t("transfers.fields.reference")}
                >
                  <Input placeholder="Reference" />
                </Form.Item>
              </Col>
              {/* TODO: Attachment */}
            </Row>
          </Col>
        </Row>
      </Form>
    </Create>
  )
}

export { TransferCreate }
