import {
  Card,
  Col,
  Row,
  Avatar,
  Typography,
  Icons,
  Space,
} from "@pankod/refine-antd";
import { blue } from "@ant-design/colors";

const { Meta } = Card;
const { Title, Text } = Typography;
const {
  BranchesOutlined,
  FileOutlined,
  DollarCircleOutlined,
  BankOutlined,
  SecurityScanOutlined,
} = Icons;

const featuresCards = [
  {
    title: "Online",
    description:
      "See your financials online anytime, anywhere on your computer, tablet or phone. Run your business from anywhere and know your cash position.",
    icon: <BankOutlined />,
  },
  {
    title: "Complete",
    description:
      "Manage your business finances with ease. Track your income and expenses, create invoices, and reconcile your bank accounts.",
    icon: <BranchesOutlined />,
  },
  {
    title: "Secure",
    description:
      "Your data is secure and encrypted. We use the latest security technologies to keep your data safe.",
    icon: <SecurityScanOutlined />,
  },
];

const features = [
  {
    title: "Cash Flow",
    description:
      "Stay on top of your Cashflow and make smarter decisions about your business.",
    icon: <BranchesOutlined />,
  },
  {
    title: "Easy Invoicing",
    description:
      "Create and send invoices in seconds. Get paid faster with online payments.",
    icon: <FileOutlined />,
  },
  {
    title: "Expense Tracking",
    description:
      "Track your expenses and stay on top of your business finances.",
    icon: <DollarCircleOutlined />,
  },
  {
    title: "Bank Reconciliation",
    description:
      "Automatically reconcile your bank transactions with your accounting data.",
    icon: <BankOutlined />,
  },
];

export default function FeatureSection() {
  return (
    <section style={{ padding: "4rem 2rem", background: "#f7f7f7" }}>
      <div>
        {/* <div style={{ margin: "0 5rem" }}>
          <Row gutter={[16, 16]} justify="center">
            {featuresCards.map((feature, index) => (
              <Col key={index} xs={24} md={12} lg={8}>
                <Card
                  hoverable
                  style={{
                    // width: 350,
                    height: "100%",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <Space direction="vertical" size="large">
                    <Avatar
                      shape="square"
                      size={60}
                      icon={feature.icon}
                      style={{ backgroundColor: blue[4] }}
                    />
                    <Meta
                      title={<Title level={4}>{feature.title}</Title>}
                      description={
                        <Text type="secondary" style={{ fontSize: "1rem" }}>
                          {feature.description}
                        </Text>
                      }
                    />
                  </Space>
                </Card>
              </Col>
            ))}
          </Row>
        </div> */}

        <div>
          <div style={{ textAlign: "center" }}>
            <Title level={2}>Feature Rich</Title>
            <Text type="secondary">
              Everything you need to manage your web accounting. Keep track of
              all of your payments, invoices, expenses, bills, etc. in one
              place.
            </Text>
          </div>
          <div className="featureWrapper">
            {features.map((feature, index) => (
              <div key={index}>
                <Meta
                  avatar={
                    <Avatar
                      shape="square"
                      size="large"
                      icon={feature.icon}
                      style={{ backgroundColor: blue[4] }}
                    />
                  }
                  title={<Title level={5}>{feature.title}</Title>}
                  description={<Text>{feature.description}</Text>}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
