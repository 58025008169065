import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core"
import {
  Col,
  Edit,
  Form,
  RefreshButton,
  Row,
  Select,
  useForm,
  DatePicker,
} from "@pankod/refine-antd"
import dayjs from "dayjs"
import { ILocalization } from "interfaces"

export const LocalizationEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<ILocalization>({
    redirect: false,
  })

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      headerButtons={({ defaultButtons: _ }) => <RefreshButton />}
    >
      <Form {...formProps} layout="vertical">
        <Row>
          <Col xs={24} md={18} lg={16}>
            <Row gutter={[32, 0]}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("localization.fields.financial_year_start_date")}
                  name="financial_year_start_date"
                  dependencies={["financial_year_end_date"]}
                  getValueProps={(value) => {
                    return {
                      value: value ? dayjs(value) : "",
                    }
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please select an financial year start date",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          dayjs(value).isBefore(
                            getFieldValue("financial_year_end_date")
                          )
                        ) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(
                            "Financial year start date must be less than financial year end date"
                          )
                        )
                      },
                    }),
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("localization.fields.financial_year_end_date")}
                  name="financial_year_end_date"
                  dependencies={["financial_year_start_date"]}
                  getValueProps={(value) => {
                    return {
                      value: value ? dayjs(value) : "",
                    }
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please select an financial year end date",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          dayjs(value).isAfter(
                            getFieldValue("financial_year_start_date")
                          )
                        ) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(
                            "Financial year end date must be greater than financial year start date"
                          )
                        )
                      },
                    }),
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("localization.fields.date_format")}
                  name="date_format"
                  rules={[
                    {
                      required: true,
                      message: "Please select date format!",
                    },
                  ]}
                >
                  <Select
                    options={[
                      { label: "November 17th 2022", value: "MMMM Do YYYY" },
                      { label: "17 Nov 2022", value: "DD MMM YYYY" },
                    ]}
                    placeholder="Select date format"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("localization.fields.timezone")}
                  name="timezone"
                  rules={[
                    {
                      required: true,
                      message: "Please select timezone!",
                    },
                  ]}
                >
                  <Select
                    options={[
                      { label: "America/New_York", value: "America/New_York" },
                      {
                        label: "America/Los_Angeles",
                        value: "America/Los_Angeles",
                      },
                      { label: "Asia/Kathmandu", value: "Asia/Kathmandu" },
                    ]}
                    placeholder="Select timezone"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Edit>
  )
}
