import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core"
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Card,
  EmailField,
} from "@pankod/refine-antd"
import { IContactUs } from "interfaces"

export const ContactUsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { tableProps, sorter } = useTable<IContactUs>({
    resource: "contact-us",
    initialSorter: [
      {
        field: "updated_at",
        order: "desc",
      },
    ],
    syncWithLocation: true,
  })

  return (
    <List title={t("contact.titles.list")}>
      <Table
        {...tableProps}
        size="small"
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ margin: "0 3rem" }}>
              <Card>{record.message}</Card>
            </div>
          ),
          rowExpandable: (record) => record.message !== "",
        }}
      >
        <Table.Column<IContactUs>
          dataIndex="name"
          key="name"
          title={t("contact.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column<IContactUs>
          dataIndex="email"
          key="email"
          title={t("contact.fields.email")}
          render={(value) => <EmailField value={value} />}
          defaultSortOrder={getDefaultSortOrder("email", sorter)}
          sorter
        />
        <Table.Column<IContactUs>
          dataIndex="phone"
          key="phone"
          title={t("contact.fields.phone")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("phone", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title={t("contact.fields.created_at")}
          render={(value) => (
            <DateField value={value} format={"hh:mm A, DD/MM/YYYY"} />
          )}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />
      </Table>
    </List>
  )
}
