import {
  Col,
  Divider,
  NumberField,
  Row,
  Table,
  Typography,
  useSimpleList,
} from "@pankod/refine-antd"
import { useGetIdentity } from "@pankod/refine-core"

const { Title } = Typography

export const ProfitVsLossReport: React.FC = () => {
  const { data: identity } = useGetIdentity()

  const currency = identity?.default?.currency ?? "NPR"

  const { listProps, searchFormProps, filters } = useSimpleList<any>({
    resource: "transactions/reports/profit-vs-loss",

    initialSorter: [
      {
        field: "updated_at",
        order: "desc",
      },
    ],
  })

  const incomes = listProps?.dataSource?.[0]?.incomes
  const expenses = listProps?.dataSource?.[0]?.expenses

  const expenses_total = expenses?.reduce(
    (acc: any, curr: any) => acc + curr.total,
    0
  )

  const income_total = incomes?.reduce(
    (acc: any, curr: any) => acc + curr.total,
    0
  )

  // add total 0 if month is not present to income

  return (
    <div style={{ margin: "1em 2em" }}>
      <section>
        <Title level={5}>Profit vs Loss</Title>
      </section>
      {/* <pre>{JSON.stringify(listProps?.dataSource, null, 2)}</pre> */}
      {/* <Table
        dataSource={incomes}
        columns={[
          {
            title: "Month",
            dataIndex: "month",
            key: "month",
          },
        ]}
      /> */}

      {/* Income */}
      <Title level={5}>Income</Title>

      <Row>
        {incomes?.map((income: any, index: number) => (
          <Col key={index} span={2}>
            {income?.title}
          </Col>
        ))}
      </Row>

      <Row>
        {incomes?.map((income: any, index: any) => (
          <Col key={index} span={2}>
            <NumberField
              value={income?.total ?? 0}
              // options={{
              //   style: "currency",
              //   currency,
              // }}
            />
          </Col>
        ))}
      </Row>
      <Divider />
      <Row style={{ fontWeight: "bold" }}>
        <Col span={2}>Total</Col>
        <Col span={18}></Col>
        <Col span={4}>
          <NumberField
            value={income_total ?? 0}
            options={{
              style: "currency",
              currency,
            }}
          />
        </Col>
      </Row>

      {/* Expense */}
      <Title level={5}>Expense</Title>

      <Row>
        {expenses?.map((expense: any, index: number) => (
          <Col key={index} span={2}>
            {expense?.title}
          </Col>
        ))}
      </Row>

      <Row>
        {expenses?.map((expense: any, index: any) => (
          <Col key={index} span={2}>
            <NumberField
              value={expense?.total ?? 0}
              // options={{
              //   style: "currency",
              //   currency,
              // }}
            />
          </Col>
        ))}
      </Row>
      <Divider />
      <Row style={{ fontWeight: "bold" }}>
        <Col span={2}>Total</Col>
        <Col span={18}></Col>
        <Col span={4}>
          <NumberField
            value={expenses_total ?? 0}
            options={{
              style: "currency",
              currency,
            }}
          />
        </Col>
      </Row>
    </div>
  )
}
