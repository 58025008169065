import { humanize } from "utils/humanize"

import { green, red } from "@ant-design/colors"
import {
  Dropdown,
  getDefaultSortOrder,
  Icons,
  List,
  Menu,
  Table,
  TextField,
  useTable,
} from "@pankod/refine-antd"
import {
  IResourceComponentsProps,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core"

import { ICoa } from "../../interfaces"

const { LockFilled, UnlockFilled, CheckCircleOutlined, CloseCircleOutlined } =
  Icons

export const CoaList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { edit, show } = useNavigation()

  const { tableProps, sorter } = useTable<ICoa>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    initialPageSize: 1000,
  })

  const moreMenu = (record: ICoa) => (
    <Menu
      mode="vertical"
      onClick={({ domEvent }) => domEvent.stopPropagation()}
    >
      <Menu.Item
        key="accept"
        disabled={record.is_editable === false}
        style={{
          fontSize: 15,
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
        }}
        icon={
          <Icons.EditOutlined
            style={{
              color: "#52c41a",
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        onClick={() => {
          edit("coas", record.id)
        }}
      >
        {t("buttons.edit")}
      </Menu.Item>
    </Menu>
  )

  return (
    <List>
      <Table {...tableProps} size="small" rowKey="id" pagination={false}>
        <Table.Column
          dataIndex="is_editable"
          key="is_editable"
          render={(value: boolean) =>
            !value ? (
              <LockFilled style={{ color: red[4], fontSize: "18px" }} />
            ) : (
              <UnlockFilled style={{ color: green[4], fontSize: "18px" }} />
            )
          }
        />
        <Table.Column<ICoa>
          dataIndex="name"
          key="name"
          title={t("coas.fields.name")}
          render={(value: string, record) => (
            <TextField
              value={value}
              onClick={() => show("coas", record.id)}
              style={{ cursor: "pointer" }}
            />
          )}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column<ICoa>
          dataIndex="access_name"
          key="access_name"
          title={t("coas.fields.access_name")}
          render={(value: string, record) => (
            <TextField
              value={value}
              onClick={() => show("coas", record.id)}
              style={{ cursor: "pointer" }}
            />
          )}
          defaultSortOrder={getDefaultSortOrder("access_name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="account_type"
          key="account_type"
          title={t("coas.fields.account_type")}
          render={(value: string) => <TextField value={humanize(value)} />}
          defaultSortOrder={getDefaultSortOrder("account_type", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="is_enabled"
          key="is_enabled"
          title={t("coas.fields.is_enabled")}
          render={(value: boolean) =>
            value ? (
              <CheckCircleOutlined
                style={{ color: green[5], fontSize: "18px" }}
              />
            ) : (
              <CloseCircleOutlined
                style={{ color: red[5], fontSize: "18px" }}
              />
            )
          }
          defaultSortOrder={getDefaultSortOrder("is_enabled", sorter)}
          sorter
        />

        {/* <Table.Column<ICoa>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        /> */}
        <Table.Column<ICoa>
          dataIndex="coas_actions"
          title={t("table.actions")}
          render={(_, record) => (
            <Dropdown overlay={moreMenu(record)} trigger={["click"]}>
              <Icons.MoreOutlined
                onClick={(e) => e.stopPropagation()}
                style={{
                  fontSize: 24,
                }}
              />
            </Dropdown>
          )}
        />
      </Table>
    </List>
  )
}
