import {
  Col,
  Edit,
  Form,
  Input,
  Row,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd"
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core"

import { ICoa, IPayItem } from "interfaces"

export const PayItemEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<IPayItem>()

  const { selectProps: coasSelectProps, queryResult: coasQueryResult } =
    useSelect<ICoa>({
      resource: "coas",
      optionLabel: "name",
      optionValue: "id",
      onSearch: (value) => [
        {
          field: "q",
          operator: "eq",
          value,
        },
      ],
      fetchSize: 500,
    })

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row>
          <Col xs={24} sm={18} md={16}>
            <Form.Item
              label={t("payitems.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Enter payitems name" />
            </Form.Item>
            <Form.Item
              label={t("payitems.fields.description")}
              name="description"
            >
              <Input.TextArea placeholder="Enter payitems description" />
            </Form.Item>
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t("payitems.fields.pay_type")}
                  name="pay_type"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    options={[
                      { label: "Benefit", value: "benefit" },
                      { label: "Deduction", value: "deduction" },
                    ]}
                    placeholder="Enter payitems pay type"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t("payitems.fields.calculation_type")}
                  name="calculation_type"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    options={[
                      { label: "Addition", value: "addition" },
                      { label: "Deduction", value: "deduction" },
                    ]}
                    placeholder="Enter payitems calculation type"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t("payitems.fields.account_id")}
                  name="account_id"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    {...coasSelectProps}
                    placeholder="Enter account"
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      const account = coasQueryResult?.data?.data.find(
                        (item) => item.id === value?.toString()
                      )
                      if (account) {
                        formProps?.form?.setFieldsValue({
                          account_name: account.name,
                          account_access_name: account.access_name,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={t("payitems.fields.account_name")}
                  name="account_name"
                  noStyle
                >
                  <Input hidden />
                </Form.Item>
                <Form.Item
                  label={t("payitems.fields.account_name")}
                  name="account_access_name"
                  noStyle
                >
                  <Input hidden />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Edit>
  )
}
