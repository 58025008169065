import axios from "axios"
import {
  Button,
  Col,
  Create,
  DatePicker,
  Form,
  Input,
  Row,
  Typography,
  Table,
  Tooltip,
  Icons,
  useSelect,
  Select,
  TextField,
  NumberField,
  Space,
  useForm,
  Collapse,
  Tabs,
  InputNumber,
} from "@pankod/refine-antd"
import dayjs from "dayjs"

import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useNotification,
  useTranslate,
} from "@pankod/refine-core"
import { red, blue } from "@ant-design/colors"
import { IEmployee, IPayItem, IPayroll, IPayrollitem } from "interfaces"
import { useState } from "react"

const { Title, Link } = Typography
const { Panel } = Collapse
const { TabPane } = Tabs

export const PayrollCreate: React.FC<IResourceComponentsProps> = () => {
  const notification = useNotification()
  const { saveButtonProps, formProps } = useForm<IPayroll>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const t = useTranslate()

  const { Text } = Typography

  const { selectProps: employeeSelectProps } = useSelect<IEmployee>({
    resource: "employees",
    optionLabel: "name",
    optionValue: "id",
    onSearch: (searchText) => [
      {
        field: "q",
        operator: "eq",
        value: searchText,
      },
    ],
  })

  const {
    selectProps: benefitPayItemSelectProps,
    queryResult: benefitQueryResult,
  } = useSelect<IPayItem>({
    resource: "pay-items",
    optionLabel: "name",
    optionValue: "name",
    filters: [
      {
        field: "pay_type",
        operator: "eq",
        value: "benefit",
      },
    ],
    onSearch: (searchText) => [
      {
        field: "q",
        operator: "eq",
        value: searchText,
      },
    ],
  })

  const {
    selectProps: deductionPayItemSelectProps,
    queryResult: deductionQueryResult,
  } = useSelect<IPayItem>({
    resource: "pay-items",
    optionLabel: "name",
    optionValue: "name",
    filters: [
      {
        field: "pay_type",
        operator: "eq",
        value: "deduction",
      },
    ],
    onSearch: (searchText) => [
      {
        field: "q",
        operator: "eq",
        value: searchText,
      },
    ],
  })

  const { data: identity } = useGetIdentity()

  const currency = identity?.default?.currency

  const apiUrl = useApiUrl()

  const handleEmployeeChange = async (value: string, index: number) => {
    const items = formProps.form?.getFieldValue("items")

    let fromDate = formProps?.form?.getFieldValue("from_date")
    let toDate = formProps?.form?.getFieldValue("to_date")

    if (!fromDate || !toDate) {
      notification?.open?.({
        type: "error",
        message: "Please select from and to date.",
        description: "",
      })
      return
    }

    fromDate = dayjs(fromDate).format("YYYY-MM-DD")
    toDate = dayjs(toDate).format("YYYY-MM-DD")

    try {
      setIsLoading(true)
      const { data } = await axios.get(
        `${apiUrl}/payroll-items/employee/${value}?from=${fromDate}&to=${toDate}`,
        { withCredentials: true }
      )
      items[index] = { ...data, employee_id: data.employee_id }
      formProps.form?.setFieldsValue({
        items,
      })
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={isLoading}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          payment_date: dayjs(),
        }}
      >
        <Row>
          <Col xs={24} sm={20}>
            <Collapse defaultActiveKey={["1"]}>
              <Panel header={t("payrolls.fields.employees")} key="1">
                <Row gutter={[16, 0]}>
                  <Col span={8}>
                    <Form.Item
                      label={t("payrolls.fields.from_date")}
                      name="from_date"
                      getValueProps={(value) => {
                        return {
                          value: value ? dayjs(value) : "",
                        }
                      }}
                      rules={[{ required: true }]}
                    >
                      <DatePicker style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={t("payrolls.fields.to_date")}
                      name="to_date"
                      getValueProps={(value) => {
                        return {
                          value: value ? dayjs(value) : "",
                        }
                      }}
                      rules={[{ required: true }]}
                    >
                      <DatePicker style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={t("payrolls.fields.payment_date")}
                      name="payment_date"
                      getValueProps={(value) => {
                        return {
                          value: value ? dayjs(value) : "",
                        }
                      }}
                      rules={[{ required: true }]}
                    >
                      <DatePicker style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Title level={5}>Employees</Title>
                <Form.List name="items">
                  {(_fields, { add, remove }) => (
                    <>
                      <Table
                        columns={[
                          {
                            title: t("payrolls.fields.employee"),
                            dataIndex: "employee_id",
                            key: "employee_id",
                            render: (_text, _record, index) => (
                              <>
                                <Form.Item
                                  name={[index, "employee_id"]}
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    showSearch
                                    allowClear
                                    {...employeeSelectProps}
                                    placeholder="Select employee"
                                    onChange={(value) =>
                                      handleEmployeeChange(
                                        value?.toString(),
                                        index
                                      )
                                    }
                                  />
                                </Form.Item>
                                <Form.Item name="" noStyle>
                                  {formProps.form?.getFieldValue("items")?.[
                                    index
                                  ]?.phone && (
                                    <Space direction="vertical">
                                      <Link
                                        href={
                                          `tel:` +
                                          formProps.form?.getFieldValue(
                                            "items"
                                          )?.[index]?.phone
                                        }
                                      >
                                        {
                                          formProps.form?.getFieldValue(
                                            "items"
                                          )?.[index]?.phone
                                        }
                                      </Link>
                                      <Link
                                        href={
                                          "mailto:" +
                                          formProps.form?.getFieldValue(
                                            "items"
                                          )?.[index]?.email
                                        }
                                      >
                                        {
                                          formProps.form?.getFieldValue(
                                            "items"
                                          )?.[index]?.email
                                        }
                                      </Link>
                                    </Space>
                                  )}
                                </Form.Item>
                              </>
                            ),
                          },
                          {
                            title: t("payrolls.fields.department"),
                            dataIndex: "department",
                            key: "department",
                            render: (_text, _record, index) => (
                              <>
                                <Form.Item
                                  name={[index, "department"]}
                                  rules={[{ required: true }]}
                                >
                                  <Input hidden />

                                  <TextField
                                    value={
                                      formProps.form?.getFieldValue("items")?.[
                                        index
                                      ]?.department || ""
                                    }
                                  />
                                </Form.Item>
                              </>
                            ),
                          },
                          {
                            title: t("payrolls.fields.basic_salary"),
                            dataIndex: "basic_salary",
                            key: "basic_salary",
                            render: (_text, _record, index) => (
                              <>
                                <Form.Item
                                  name={[index, "basic_salary"]}
                                  rules={[{ required: true }]}
                                >
                                  <Input hidden />
                                  <NumberField
                                    value={
                                      formProps.form?.getFieldValue("items")?.[
                                        index
                                      ]?.basic_salary || 0
                                    }
                                    options={{
                                      style: "currency",
                                      currency: currency,
                                    }}
                                  />
                                </Form.Item>
                              </>
                            ),
                          },
                          {
                            title: t("payrolls.fields.total_benefit"),
                            dataIndex: "total_benefit",
                            key: "total_benefit",
                            render: (_text, _record, index) => (
                              <>
                                <Form.Item
                                  name={[index, "total_benefit"]}
                                  rules={[{ required: true }]}
                                >
                                  <Input hidden />
                                  <NumberField
                                    value={
                                      formProps.form?.getFieldValue("items")?.[
                                        index
                                      ]?.total_benefit || 0
                                    }
                                    options={{
                                      style: "currency",
                                      currency: currency,
                                    }}
                                  />
                                </Form.Item>
                              </>
                            ),
                          },
                          {
                            title: t("payrolls.fields.total_deduction"),
                            dataIndex: "total_deduction",
                            key: "total_deduction",
                            render: (_text, _record, index) => (
                              <>
                                <Form.Item
                                  name={[index, "total_deduction"]}
                                  rules={[{ required: true }]}
                                >
                                  <Input hidden />
                                  <NumberField
                                    value={
                                      formProps.form?.getFieldValue("items")?.[
                                        index
                                      ]?.total_deduction || 0
                                    }
                                    options={{
                                      style: "currency",
                                      currency: currency,
                                    }}
                                  />
                                </Form.Item>
                              </>
                            ),
                          },

                          {
                            title: t("payrolls.fields.total_payable"),
                            dataIndex: "total_payable",
                            key: "total_payable",
                            render: (_text, _record, index) => (
                              <>
                                <Form.Item
                                  name={[index, "total_payable"]}
                                  rules={[{ required: true }]}
                                >
                                  <Input hidden />
                                  <NumberField
                                    value={
                                      formProps.form?.getFieldValue("items")?.[
                                        index
                                      ]?.total_payable || 0
                                    }
                                    options={{
                                      style: "currency",
                                      currency: currency,
                                    }}
                                  />
                                </Form.Item>
                              </>
                            ),
                          },

                          {
                            title: "",
                            dataIndex: "",
                            key: "action",
                            render: (_text, _record, index) => (
                              <Form.Item name="_">
                                <Tooltip
                                  title={t("bills.fields.remove_item")}
                                  color={red[4]}
                                >
                                  <Icons.MinusCircleOutlined
                                    style={{ fontSize: 16, color: red[5] }}
                                    onClick={() => remove(index)}
                                  />
                                </Tooltip>
                              </Form.Item>
                            ),
                          },
                        ]}
                        dataSource={
                          formProps?.form?.getFieldValue("items") || []
                        }
                        pagination={false}
                        rowKey="id"
                        size="small"
                      />
                      <Form.Item style={{ marginTop: "0.4rem" }}>
                        <Button
                          onClick={() => {
                            const fromDate =
                              formProps?.form?.getFieldValue("from_date")
                            const toDate =
                              formProps?.form?.getFieldValue("to_date")

                            if (!fromDate || !toDate) {
                              notification?.open?.({
                                type: "error",
                                message: "Please select from and to date.",
                                description: "",
                              })
                              return
                            }

                            add({
                              name: "",
                              quantity: 1,
                              rate: 0,
                              item_total: 0,
                            })
                          }}
                        >
                          <Icons.PlusCircleFilled
                            style={{ color: blue[5], fontSize: 17 }}
                          />{" "}
                          {t("bills.fields.add_item")}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Panel>
              <Panel header={t("payrolls.fields.benefit_deduction")} key="2">
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) => {
                    return prevValues?.items !== currentValues?.items
                  }}
                  noStyle
                >
                  {({ getFieldValue }) => {
                    const payrollItems = getFieldValue("items") || []
                    return (
                      <Tabs tabPosition="left">
                        {payrollItems[0]?.employee_id &&
                          payrollItems?.map(
                            (item: IPayrollitem, mainIndex: number) => {
                              return (
                                <TabPane
                                  tab={item?.employee_name}
                                  key={`${mainIndex}`}
                                >
                                  <Title level={5}>Benefits</Title>
                                  <Text type="secondary">
                                    Here, you can add different pay items such
                                    as meals, expense reiembursement, or bonus.
                                    If necessary, you can adjust the pay items
                                    on the settings page.
                                  </Text>
                                  <br />
                                  <Form.List
                                    name={["items", mainIndex, "benefits"]}
                                  >
                                    {(
                                      fields,
                                      { add: addBenefit, remove: removeBenefit }
                                    ) => (
                                      <div>
                                        <Table
                                          columns={[
                                            {
                                              title: t("payrolls.fields.name"),
                                              dataIndex: "name",
                                              key: "name",
                                              render: (
                                                _text,
                                                _record,
                                                index
                                              ) => (
                                                <Form.Item
                                                  name={[index, "name"]}
                                                  noStyle
                                                >
                                                  <Select
                                                    {...benefitPayItemSelectProps}
                                                    placeholder="Select a pay item"
                                                    onChange={(value) => {
                                                      const benefitPayItem =
                                                        benefitQueryResult?.data?.data?.find(
                                                          (item) =>
                                                            item.name ===
                                                            value?.toString()
                                                        )
                                                      if (benefitPayItem) {
                                                        const items =
                                                          getFieldValue("items")
                                                        const {
                                                          account_id,
                                                          account_access_name,
                                                          account_name,
                                                          description,
                                                          name,
                                                        } = benefitPayItem
                                                        items[
                                                          mainIndex
                                                        ].benefits[index] = {
                                                          ...items[mainIndex]
                                                            .benefits[index],
                                                          account_id,
                                                          account_access_name,
                                                          account_name,
                                                          description,
                                                          name,
                                                        }

                                                        formProps.form?.setFieldsValue(
                                                          { items }
                                                        )
                                                      }
                                                    }}
                                                    style={{ width: "100%" }}
                                                  />
                                                </Form.Item>
                                              ),
                                            },
                                            {
                                              title: t(
                                                "payrolls.fields.amount"
                                              ),
                                              dataIndex: "amount",
                                              key: "amount",
                                              render: (
                                                _text,
                                                _record,
                                                index
                                              ) => (
                                                <Form.Item
                                                  name={[index, "amount"]}
                                                  noStyle
                                                >
                                                  <InputNumber
                                                    formatter={(value) => {
                                                      return `${value}`.replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      )
                                                    }}
                                                    style={{ width: "100%" }}
                                                    onChange={(value) => {
                                                      const items =
                                                        getFieldValue("items")
                                                      items[
                                                        mainIndex
                                                      ].total_benefit = items[
                                                        mainIndex
                                                      ].benefits.reduce(
                                                        (
                                                          acc: number,
                                                          cur: {
                                                            amount: number
                                                          }
                                                        ) => {
                                                          return (
                                                            acc +
                                                            (cur.amount || 0)
                                                          )
                                                        },
                                                        0
                                                      )

                                                      items[
                                                        mainIndex
                                                      ].total_payable =
                                                        (items[mainIndex]
                                                          .total_benefit || 0) -
                                                        (items[mainIndex]
                                                          .total_deduction ||
                                                          0) +
                                                        (items[mainIndex]
                                                          .basic_salary || 0)

                                                      formProps.form?.setFieldsValue(
                                                        { items }
                                                      )
                                                    }}
                                                    placeholder="Amount"
                                                  />
                                                </Form.Item>
                                              ),
                                            },
                                            {
                                              title: "Description",
                                              dataIndex: "description",
                                              key: "description",
                                              render: (text) => (
                                                <TextField value={text} />
                                              ),
                                            },
                                            {
                                              title: "",
                                              dataIndex: "",
                                              key: "action",
                                              render: (
                                                _text,
                                                _record,
                                                index
                                              ) => (
                                                <Form.Item name="_" noStyle>
                                                  <Tooltip
                                                    title={t(
                                                      "bills.fields.remove_item"
                                                    )}
                                                    color={red[4]}
                                                  >
                                                    <Icons.MinusCircleOutlined
                                                      style={{
                                                        fontSize: 16,
                                                        color: red[5],
                                                      }}
                                                      onClick={() => {
                                                        removeBenefit(index)
                                                      }}
                                                    />
                                                  </Tooltip>
                                                </Form.Item>
                                              ),
                                            },
                                          ]}
                                          pagination={false}
                                          dataSource={item?.benefits}
                                          rowKey="id"
                                          size="small"
                                        />
                                        <Form.Item
                                          name={["action"]}
                                          style={{ marginTop: "0.4rem" }}
                                        >
                                          <Button
                                            onClick={() => {
                                              addBenefit({
                                                name: "",
                                                amount: 0,
                                              })
                                            }}
                                          >
                                            <Icons.PlusCircleFilled
                                              style={{
                                                color: blue[5],
                                                fontSize: 17,
                                              }}
                                            />{" "}
                                            {t("payrolls.fields.add_benefit")}
                                          </Button>
                                        </Form.Item>
                                      </div>
                                    )}
                                  </Form.List>
                                  <Title level={5}>Deductions</Title>
                                  <Text type="secondary">
                                    Here, you can add different pay items such
                                    as advance, leaves, or loan. If necessary,
                                    you can adjust the pay items on the settings
                                    page.
                                  </Text>
                                  <br />
                                  <Form.List
                                    name={["items", mainIndex, "deductions"]}
                                  >
                                    {(
                                      _fields,
                                      {
                                        add: addDeduction,
                                        remove: removeDeduction,
                                      }
                                    ) => (
                                      <div>
                                        <Table
                                          columns={[
                                            {
                                              title: t("payrolls.fields.name"),
                                              dataIndex: "name",
                                              key: "name",
                                              render: (
                                                _text,
                                                _record,
                                                index
                                              ) => (
                                                <Form.Item
                                                  name={[index, "name"]}
                                                  noStyle
                                                >
                                                  <Select
                                                    {...deductionPayItemSelectProps}
                                                    style={{ width: "100%" }}
                                                    placeholder="Select a pay item"
                                                    onChange={(value) => {
                                                      const deductionPayItem =
                                                        deductionQueryResult?.data?.data?.find(
                                                          (item) =>
                                                            item.name ===
                                                            value?.toString()
                                                        )
                                                      if (deductionPayItem) {
                                                        const items =
                                                          getFieldValue("items")
                                                        const {
                                                          account_id,
                                                          account_access_name,
                                                          account_name,
                                                          description,
                                                          name,
                                                        } = deductionPayItem
                                                        items[
                                                          mainIndex
                                                        ].deductions[index] = {
                                                          ...items[mainIndex]
                                                            .deductions[index],
                                                          account_id,
                                                          account_access_name,
                                                          account_name,
                                                          description,
                                                          name,
                                                        }

                                                        formProps.form?.setFieldsValue(
                                                          { items }
                                                        )
                                                      }
                                                    }}
                                                  />
                                                </Form.Item>
                                              ),
                                            },
                                            {
                                              title: t(
                                                "payrolls.fields.amount"
                                              ),
                                              dataIndex: "amount",
                                              key: "amount",
                                              render: (
                                                _text,
                                                _record,
                                                index
                                              ) => (
                                                <Form.Item
                                                  name={[index, "amount"]}
                                                  noStyle
                                                >
                                                  <InputNumber
                                                    formatter={(value) => {
                                                      return `${value}`.replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      )
                                                    }}
                                                    style={{ width: "100%" }}
                                                    placeholder="Amount"
                                                    onChange={(value) => {
                                                      const items =
                                                        getFieldValue("items")
                                                      items[
                                                        mainIndex
                                                      ].total_deduction = items[
                                                        mainIndex
                                                      ].deductions.reduce(
                                                        (
                                                          acc: number,
                                                          cur: {
                                                            amount: number
                                                          }
                                                        ) => {
                                                          return (
                                                            acc +
                                                            (cur.amount || 0)
                                                          )
                                                        },
                                                        0
                                                      )

                                                      items[
                                                        mainIndex
                                                      ].total_payable =
                                                        (items[mainIndex]
                                                          .total_benefit || 0) -
                                                        (items[mainIndex]
                                                          .total_deduction ||
                                                          0) +
                                                        (items[mainIndex]
                                                          .basic_salary || 0)

                                                      formProps.form?.setFieldsValue(
                                                        { items }
                                                      )
                                                    }}
                                                  />
                                                </Form.Item>
                                              ),
                                            },
                                            {
                                              title: "Description",
                                              dataIndex: "description",
                                              key: "description",
                                              render: (text) => (
                                                <TextField value={text} />
                                              ),
                                            },
                                            {
                                              title: "",
                                              dataIndex: "",
                                              key: "action",
                                              render: (
                                                _text,
                                                _record,
                                                index
                                              ) => (
                                                <Form.Item name="_" noStyle>
                                                  <Tooltip
                                                    title={t(
                                                      "bills.fields.remove_item"
                                                    )}
                                                    color={red[4]}
                                                  >
                                                    <Icons.MinusCircleOutlined
                                                      style={{
                                                        fontSize: 16,
                                                        color: red[5],
                                                      }}
                                                      onClick={() => {
                                                        removeDeduction(index)
                                                      }}
                                                    />
                                                  </Tooltip>
                                                </Form.Item>
                                              ),
                                            },
                                          ]}
                                          pagination={false}
                                          dataSource={item?.deductions}
                                          rowKey="id"
                                          size="small"
                                        />
                                        <Form.Item
                                          name={["action"]}
                                          style={{ marginTop: "0.4rem" }}
                                        >
                                          <Button
                                            onClick={() => {
                                              addDeduction({
                                                name: "",
                                                amount: 0,
                                              })
                                            }}
                                          >
                                            <Icons.PlusCircleFilled
                                              style={{
                                                color: blue[5],
                                                fontSize: 17,
                                              }}
                                            />{" "}
                                            {t("payrolls.fields.add_deduction")}
                                          </Button>
                                        </Form.Item>
                                      </div>
                                    )}
                                  </Form.List>
                                </TabPane>
                              )
                            }
                          )}
                      </Tabs>
                    )
                  }}
                </Form.Item>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
    </Create>
  )
}
