import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core"
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  TagField,
  ShowButton,
  NumberField,
  Icons,
} from "@pankod/refine-antd"
import { ICategory } from "interfaces"
import { green, red } from "@ant-design/colors"
import { humanize } from "utils/humanize"

export const CategoryList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { tableProps, sorter } = useTable<ICategory>({
    initialSorter: [
      {
        field: "updated_at",
        order: "desc",
      },
    ],
  })

  return (
    <List>
      <Table {...tableProps} rowKey="id" size="small">
        <Table.Column
          dataIndex="name"
          key="name"
          title={t("categories.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="type"
          key="type"
          title={t("categories.fields.type")}
          render={(value) => (
            <TagField value={value ? humanize(value) : null} />
          )}
          defaultSortOrder={getDefaultSortOrder("type", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title={t("categories.fields.created_at")}
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />

        <Table.Column<ICategory>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
