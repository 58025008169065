import { Button, Drawer, Icons } from "@pankod/refine-antd"
import { IInvoice, IPaymentInvoice } from "interfaces"
import { InvoicePaymentCreate } from "pages/sales/payment-receive"
import { useState } from "react"

interface IInvoicePaymentButtonProps {
  record?: IInvoice | undefined
}

const InvoicePaymentButton: React.FC<IInvoicePaymentButtonProps> = ({
  record,
}) => {
  // const { modalProps, show, close } = useModal()
  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        icon={<Icons.DollarOutlined style={{ fontSize: 18 }} />}
        onClick={showDrawer}
      >
        Record Payment
      </Button>
      <Drawer title="Payment" visible={open} onClose={onClose} width={"60vw"}>
        <InvoicePaymentCreate
          initialValues={{
            ...record,
            amount: record?.due_amount ?? 0,
            charge_amount: 0,
            total_amount: record?.due_amount ?? 0,
            payment_date: new Date().toISOString(),
            invoices: [
              record
                ? {
                    invoice_date: record.invoice_date,
                    invoice_number: record.invoice_number,
                    id: record.id,
                    invoice_amount: record.total,
                    due_amount: record.total - record.paid_amount,
                    payment_amount: 0,
                    account_id: record.items[0]?.account_id ?? "",
                    category_id: record.category_id,
                  }
                : ({} as IPaymentInvoice),
            ],
          }}
          breadcrumb={false}
          title="Create Payment Receive"
          // title={false}
          // goBack={false}
        />
      </Drawer>
    </>
  )
}

export default InvoicePaymentButton
