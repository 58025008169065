import { Avatar, Card, Col, Icons, Row, Typography } from "@pankod/refine-antd"
import {
  Authenticated,
  useGetIdentity,
  useRouterContext,
} from "@pankod/refine-core"
import { red, green, blue, orange, purple } from "@ant-design/colors"

const { Title } = Typography

export const SettingList = () => {
  const { Link } = useRouterContext()
  const { data: identity } = useGetIdentity()

  const generalSettings = [
    {
      title: "Organization",
      description: "Organization settings",
      icon: <Icons.HomeOutlined style={{ color: blue[5] }} />,
      path: `/organizations/edit/${identity?.organization_id}`,
      bgcolor: blue[1],
    },
    {
      title: "Localization",
      description: "Localization settings",
      icon: <Icons.FlagOutlined style={{ color: red[5] }} />,
      path: `/localization/edit/${identity?.organization_id}`,
      bgcolor: red[1],
    },
    {
      title: "Default",
      description: "Default settings",
      icon: <Icons.BarsOutlined style={{ color: green[5] }} />,
      path: `/default/edit/${identity?.organization_id}`,
      bgcolor: green[1],
    },
    {
      title: "Tax",
      description: "Tax settings",
      icon: <Icons.DollarOutlined style={{ color: orange[5] }} />,
      path: "/tax",
      bgcolor: orange[1],
    },
    {
      title: "Categories",
      description: "Categories settings",
      icon: <Icons.TagOutlined style={{ color: purple[5] }} />,
      path: "/categories",
      bgcolor: purple[1],
    },
  ]

  const employeeSettings = [
    {
      title: "TDS",
      description: "TDS settings",
      icon: <Icons.PercentageOutlined style={{ color: red[5] }} />,
      path: `/tds/edit/${identity?.organization_id}`,
      bgcolor: red[1],
    },
    {
      title: "Pay Items",
      description: "Pay Items settings",
      icon: <Icons.DollarOutlined style={{ color: orange[5] }} />,
      path: "/pay-items",
      bgcolor: orange[1],
    },
  ]

  const superAdminSettings = identity?.is_superadmin
    ? [
        {
          title: "Organizations",
          description: "Organization lists",
          icon: <Icons.HomeOutlined style={{ color: blue[5] }} />,
          path: `/organizations`,
          bgcolor: blue[1],
        },
        {
          title: "API",
          description: "API Settings",
          icon: <Icons.SettingOutlined style={{ color: blue[5] }} />,
          path: "/integrations",
          bgcolor: blue[1],
        },
      ]
    : []

  return (
    <Authenticated>
      <div style={{ margin: "0.5em" }}>
        <section style={{ marginLeft: "1em" }}>
          <Title level={5}>General Settings</Title>
        </section>
        <Row gutter={[24, 16]} style={{ margin: "0" }}>
          {generalSettings.map((setting) => (
            <Col xs={12} sm={6}>
              <Link to={setting.path}>
                <Card size="small" hoverable>
                  <Card.Meta
                    avatar={
                      <Avatar
                        icon={setting.icon}
                        size="large"
                        shape="square"
                        style={{
                          backgroundColor: setting?.bgcolor ?? green[1],
                        }}
                      />
                    }
                    title={setting.title}
                    description={setting.description}
                  />
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
        <section style={{ marginLeft: "1em", marginTop: "1em" }}>
          <Title level={5}>Payroll Settings</Title>
        </section>
        <Row gutter={[24, 16]} style={{ margin: "0" }}>
          {employeeSettings.map((setting) => (
            <Col xs={12} sm={6}>
              <Link to={setting.path}>
                <Card size="small" hoverable>
                  <Card.Meta
                    avatar={
                      <Avatar
                        icon={setting.icon}
                        size="large"
                        shape="square"
                        style={{
                          backgroundColor: setting?.bgcolor ?? green[1],
                        }}
                      />
                    }
                    title={setting.title}
                    description={setting.description}
                  />
                </Card>
              </Link>
            </Col>
          ))}
        </Row>

        {identity?.is_superadmin ? (
          <section style={{ marginLeft: "1em", marginTop: "1em" }}>
            <Title level={5}>Settings (Super Admin)</Title>
          </section>
        ) : null}
        <Row gutter={[24, 16]} style={{ margin: "0" }}>
          {superAdminSettings.map((setting) => (
            <Col xs={12} sm={6}>
              <Link to={setting.path}>
                <Card size="small" hoverable>
                  <Card.Meta
                    avatar={
                      <Avatar
                        icon={setting.icon}
                        size="large"
                        shape="square"
                        style={{
                          backgroundColor: setting?.bgcolor ?? green[1],
                        }}
                      />
                    }
                    title={setting.title}
                    description={setting.description}
                  />
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </Authenticated>
  )
}
