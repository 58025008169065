import {
  useTranslate,
  IResourceComponentsProps,
  useGetIdentity,
  useNavigation,
  HttpError,
  CrudFilters,
} from "@pankod/refine-core"
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  ShowButton,
  NumberField,
  Row,
  Col,
  Card,
  Form,
  Input,
  Select,
  Button,
  Typography,
  DatePicker,
  useSelect,
} from "@pankod/refine-antd"
import {
  ICategory,
  ICustomer,
  ITransaction,
  ITransactionFilterVariables,
  IVendor,
} from "interfaces"
import { humanize } from "utils/humanize"
import moment from "moment"
import { green } from "@ant-design/colors"
import AddExpenseButton from "components/transaction/AddExpenseButton"
import AddIncomeButton from "components/transaction/AddIncomeButton"
import { dateStringify } from "utils/date"

const { Title } = Typography

export const TransactionList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { data: identity } = useGetIdentity()

  const pagination = identity?.default?.pagination || 10

  const { tableProps, sorter, searchFormProps } = useTable<
    ITransaction,
    HttpError,
    ITransactionFilterVariables
  >({
    initialSorter: [
      {
        field: "updated_at",
        order: "desc",
      },
    ],
    initialPageSize: pagination,
    onSearch: (params) => {
      const filters: CrudFilters = []
      const {
        q,
        transaction_type,
        created_at,
        payment_method,
        category_id,
        customer_id,
        vendor_id,
      } = params

      filters.push(
        {
          field: "q",
          operator: "eq",
          value: q,
        },
        {
          field: "transaction_type",
          operator: "eq",
          value: transaction_type,
        },
        {
          field: "created_at",
          operator: "eq",
          value: created_at ? dateStringify(created_at) : undefined,
        },
        {
          field: "payment_method",
          operator: "eq",
          value: payment_method,
        },
        {
          field: "category_id",
          operator: "eq",
          value: category_id,
        },
        {
          field: "customer_id",
          operator: "eq",
          value: customer_id,
        },
        {
          field: "vendor_id",
          operator: "eq",
          value: vendor_id,
        }
      )

      return filters
    },
  })

  const { show } = useNavigation()

  const { selectProps: categoriesSelectProps } = useSelect<ICategory>({
    resource: "categories",
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
  })

  const { selectProps: customerSelectProps } = useSelect<ICustomer>({
    resource: "customers",
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
  })

  const { selectProps: vendorSelectProps } = useSelect<IVendor>({
    resource: "vendors",
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
  })

  return (
    <List
      headerButtons={({ defaultButtons }) => (
        <>
          <AddIncomeButton />
          <AddExpenseButton />
          {defaultButtons}
        </>
      )}
    >
      <Row gutter={[12, 0]}>
        <Col span={5}>
          <Card size="small">
            <Title level={5}>Filters</Title>
            <Form {...searchFormProps}>
              <Form.Item name="transaction_number">
                <Input placeholder="Transaction Number" />
              </Form.Item>
              <Form.Item name="transaction_type">
                <Select
                  allowClear
                  options={[
                    { label: "Income", value: "income" },
                    { label: "Expense", value: "expense" },
                  ]}
                  placeholder="Transaction type"
                />
              </Form.Item>
              <Form.Item name="created_at">
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Transaction Date"
                />
              </Form.Item>
              <Form.Item name="payment_method">
                <Select
                  allowClear
                  options={[
                    { label: "Cash", value: "cash" },
                    { label: "Cheque", value: "cheque" },
                    { label: "Bank Transfer", value: "bank_transfer" },
                  ]}
                  placeholder="Payment Method"
                />
              </Form.Item>
              <Form.Item name="category_id">
                <Select
                  allowClear
                  {...categoriesSelectProps}
                  placeholder="Category"
                />
              </Form.Item>

              <Form.Item name="customer_id">
                <Select
                  allowClear
                  {...customerSelectProps}
                  placeholder="Customer"
                />
              </Form.Item>

              <Form.Item name="vendor_id">
                <Select
                  allowClear
                  {...vendorSelectProps}
                  placeholder="Vendor"
                />
              </Form.Item>

              <Button
                htmlType="submit"
                type="primary"
                style={{ width: "100%" }}
              >
                Search
              </Button>
            </Form>
          </Card>
        </Col>
        <Col span={19}>
          <Table {...tableProps} size="small" rowKey="id">
            <Table.Column
              dataIndex="created_at"
              title={t("transactions.fields.created_at")}
              sorter
              sortOrder={getDefaultSortOrder("created_at", sorter)}
              render={(value) => (
                <TextField value={moment(value).format("DD MMM YYYY")} />
              )}
            />
            <Table.Column<ITransaction>
              dataIndex="transaction_number"
              key="transaction_number"
              title={t("transactions.fields.transaction_number")}
              render={(value, record) => (
                <TextField
                  value={value}
                  onClick={() => show("transactions", record?.id ?? "")}
                  style={{ color: green[4], cursor: "pointer" }}
                />
              )}
              defaultSortOrder={getDefaultSortOrder(
                "transaction_number",
                sorter
              )}
              sorter
            />
            <Table.Column
              dataIndex={["account", "name"]}
              key="account"
              title={t("transactions.fields.account")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("account", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="transaction_type"
              key="transaction_type"
              title={t("transactions.fields.transaction_type")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("transaction_type", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="payment_method"
              key="payment_method"
              title={t("transactions.fields.payment_method")}
              render={(value) =>
                value ? <TextField value={humanize(value)} /> : null
              }
              defaultSortOrder={getDefaultSortOrder("payment_method", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="amount"
              key="amount"
              title={t("transactions.fields.amount")}
              render={(value) => (
                <NumberField
                  value={value}
                  options={{ style: "currency", currency: "NPR" }}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("amount", sorter)}
              sorter
            />
            <Table.Column<ITransaction>
              title={t("table.actions")}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  {/* <EditButton hideText size="small" recordItemId={record.id} /> */}
                  <ShowButton hideText size="small" recordItemId={record.id} />
                  {/* <DeleteButton hideText size="small" recordItemId={record.id} /> */}
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
    </List>
  )
}
