import {
  Button,
  Col,
  Form,
  Row,
  Select,
  useTable,
  Typography,
  Icons,
  Table,
  Avatar,
  ExportButton,
} from "@pankod/refine-antd"
import {
  CrudFilters,
  getDefaultFilter,
  HttpError,
  useApiUrl,
  useExport,
  useGetIdentity,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core"
import moment from "moment"
import { green } from "@ant-design/colors"

import React from "react"
import { ILedgerEntry } from "interfaces"

const { Text } = Typography
const { CalendarOutlined } = Icons

export const GeneralLedgerReport: React.FC = () => {
  const { goBack } = useNavigation()

  const { data: identity } = useGetIdentity()

  const organization = identity?.organization
  const currency = identity?.default?.currency ?? "NPR"

  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<
    any,
    HttpError,
    { date: string }
  >({
    resource: "ledger-entries",
    onSearch: ({ date }) => {
      const ledgerEntriesFilters: CrudFilters = []

      ledgerEntriesFilters.push({
        field: "date",
        operator: "eq",
        value: date ? date : undefined,
      })

      return ledgerEntriesFilters
    },
    initialFilter: [
      {
        field: "date",
        operator: "eq",
        value: "today",
      },
    ],
    syncWithLocation: true,
    hasPagination: false,
  })

  const t = useTranslate()

  const { triggerExport, isLoading } = useExport<ILedgerEntry>({
    resourceName: "ledger-entries",
    filters,
    // pageSize: tableQueryResult?.data?.meta?.total,
    mapData: (data) => {
      return {
        name: data.name,
        debit: data.debit,
        credit: data.credit,
        balance: data.balance,
      }
    },
  })

  const apiUrl = useApiUrl()

  // @ts-ignore
  const meta = tableQueryResult?.data?.meta

  return (
    <div style={{ margin: "1em 2em" }}>
      <Form
        {...searchFormProps}
        onValuesChange={() => {
          searchFormProps.form?.submit()
        }}
        initialValues={{
          date: getDefaultFilter("date", filters, "eq"),
        }}
      >
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item name="date" noStyle>
              <Select
                options={[
                  { value: "today", label: "Today" },
                  { value: "yesterday", label: "Yesterday" },
                  { value: "this-week", label: "This Week" },
                  { value: "this-month", label: "This Month" },
                  { value: "last-month", label: "Last Month" },
                  { value: "this-year", label: "This Year" },
                ]}
                style={{ width: "50%" }}
                defaultValue="today"
                suffixIcon={<CalendarOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ display: "flex", justifyContent: "right" }}>
            <ExportButton onClick={triggerExport} loading={isLoading} />
            <Button
              href={`${apiUrl}/ledger-entries/pdf?date=${
                filters?.[0]?.value ?? "today"
              }`}
              style={{ marginLeft: "1em" }}
              icon={<Icons.PrinterOutlined style={{ fontSize: 18 }} />}
            >
              Print
            </Button>
            <Button onClick={goBack} style={{ marginLeft: "1em" }} type="text">
              <Icons.CloseOutlined />
            </Button>
          </Col>
        </Row>
      </Form>
      <br />
      <section style={{ textAlign: "center", marginBottom: "2em" }}>
        <Text style={{ fontSize: "1.5em", color: "#000", fontWeight: "300" }}>
          {organization?.name}
        </Text>
        <br />
        <Text style={{ fontSize: "1.5em", color: "#000", fontWeight: "350" }}>
          General Ledger
        </Text>
        <br />
        <Text>
          From {moment(meta?.from).format("DD MMM YYYY")} To{" "}
          {moment(meta?.to).format("DD MMM YYYY")}
        </Text>
      </section>

      <Row justify="center">
        <Col xs={24} sm={20} md={16} lg={14}>
          <Table
            size="small"
            {...tableProps}
            pagination={false}
            columns={[
              {
                title: t("coas.fields.name"),
                key: "name",
                dataIndex: "name",
                render: (name: string) => <Text>{name}</Text>,
              },
              {
                title: t("coas.fields.debit"),
                key: "debit",
                dataIndex: "debit",
                render: (debit: number) => <Text>{debit?.toFixed(2)}</Text>,
                align: "right",
              },
              {
                title: t("coas.fields.credit"),
                key: "credit",
                dataIndex: "credit",
                render: (credit: number) => <Text>{credit?.toFixed(2)}</Text>,
                align: "right",
              },
              {
                title: t("coas.fields.balance"),
                key: "balance",
                dataIndex: "balance",
                render: (balance: number) => <Text>{balance?.toFixed(2)}</Text>,
                align: "right",
              },
            ]}
          />
        </Col>
      </Row>

      <br />
      <Text>
        ***Amount is displayed in your base currency{" "}
        <Avatar
          shape="square"
          size="small"
          style={{ backgroundColor: green[5] }}
        >
          {currency}
        </Avatar>
      </Text>
      <br />
    </div>
  )
}
