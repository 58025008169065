import {
  Button,
  Card,
  Col,
  EditButton,
  Icons,
  RefreshButton,
  Row,
  Show,
  Statistic,
  Table,
  Typography,
} from "@pankod/refine-antd"
import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useShow,
  useTranslate,
} from "@pankod/refine-core"
import { ILedgerEntry, ITransfer } from "interfaces"
import Helmet from "react-helmet"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"

const { Title, Text } = Typography
const { ArrowUpOutlined, ArrowDownOutlined } = Icons

export const TransferShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { queryResult } = useShow<ITransfer>()

  const { data, isLoading } = queryResult
  const record = data?.data

  const apiUrl = useApiUrl()

  const { data: identity } = useGetIdentity()

  const currency = identity?.default?.currency ?? "NPR"

  const ledgerEntries: ILedgerEntry[] = record?.ledger_entries
    ? [
        ...record?.ledger_entries,
        {
          id: "total",
          debit: record?.ledger_entries?.reduce(
            (acc, cur) => acc + cur.debit,
            0
          ),
          credit: record?.ledger_entries?.reduce(
            (acc, cur) => acc + cur.credit,
            0
          ),
        },
      ]
    : []

  return (
    <>
      <Helmet>
        <title>{`Transfer: TNF-${record?.transfer_number}`}</title>
      </Helmet>
      <Show
        isLoading={isLoading}
        headerButtons={({ defaultButtons }) => (
          <>
            {/* {defaultButtons} */}
            <EditButton />
            <RefreshButton />
            <Button
              href={`${apiUrl}/transfers/pdf/${record?.id}`}
              icon={<Icons.PrinterOutlined style={{ fontSize: 18 }} />}
            >
              Print
            </Button>
          </>
        )}
        title={`Transfer: Transfer-${record?.transfer_number}`}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <section style={{ marginBottom: "0.5em" }}>
              <Row gutter={16}>
                <Col span={12}>
                  <Card>
                    <Statistic
                      title={record?.from_account?.name + " (From Account) "}
                      value={record?.amount}
                      precision={2}
                      valueStyle={{ color: "#cf1322" }}
                      prefix={
                        <>
                          <ArrowDownOutlined /> {currency}
                        </>
                      }
                      suffix=""
                    />
                  </Card>
                </Col>
                <Col span={12}>
                  <Card>
                    <Statistic
                      title={record?.to_account?.name + " (To Account) "}
                      value={record?.amount}
                      precision={2}
                      valueStyle={{ color: "#3f8600" }}
                      prefix={
                        <>
                          <ArrowUpOutlined /> {currency}
                        </>
                      }
                      suffix=""
                    />
                  </Card>
                </Col>
              </Row>
            </section>
            <section>
              <Title level={5}>Journal</Title>
              <Table
                dataSource={ledgerEntries}
                columns={[
                  {
                    title: t("transfers.fields.coa_name"),
                    dataIndex: "coa",
                    key: "coa",
                    render: (_text, record, index) => (
                      <>
                        <Text>{record?.coa?.name}</Text>
                      </>
                    ),
                  },
                  {
                    title: t("transfers.fields.debit"),
                    dataIndex: "debit",
                    key: "debit",
                    render: (_text, record, index) => (
                      <>
                        <Text> {record?.debit?.toFixed(2)}</Text>
                      </>
                    ),
                  },
                  {
                    title: t("transfers.fields.credit"),
                    dataIndex: "credit",
                    key: "credit",
                    render: (_text, record, index) => (
                      <>
                        <Text> {record?.credit?.toFixed(2)}</Text>
                      </>
                    ),
                  },
                ]}
                pagination={false}
                size="small"
              />
            </section>
          </Col>
          <Col xs={24} sm={12}>
            {record?.id && (
              <div
                style={{
                  backgroundColor: "#f7f7f7",
                  display: "flex",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <Document
                  file={{
                    url: `${apiUrl}/transfers/pdf/${record?.id}`,
                    withCredentials: true,
                  }}
                  renderMode="canvas"
                  className="payment-pdf-section"
                >
                  <Page pageNumber={1} renderMode="canvas" width={560} />
                </Document>
              </div>
              // <div dangerouslySetInnerHTML={{ __html: record?.html }}></div>
            )}
          </Col>
        </Row>
      </Show>
    </>
  )
}
