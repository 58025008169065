import { Button, Drawer, Icons } from "@pankod/refine-antd"
import { IPurchaseReturn, IPurchaseReturnIn } from "interfaces"
import { PurchaseReturnPaymentCreate } from "pages/purchases/purchase-return-payment"
import { useState } from "react"

interface IPurchaseReturnPaymentButtonProps {
  record?: IPurchaseReturn | undefined
}

const PurchaseReturnPaymentButton: React.FC<
  IPurchaseReturnPaymentButtonProps
> = ({ record }) => {
  // const { modalProps, show, close } = useModal()
  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  if (!record) return null

  const purchase_returns: IPurchaseReturnIn[] = [
    {
      bill_date: record.bill_date,
      bill_number: record.bill_number,
      id: record.id,
      purchase_return_amount: record.total,
      due_amount: record.total - record.paid_amount,
      payment_amount: 0,
      account_id: record?.items[0]?.account_id,
      category_id: record?.category_id,
      created_at: record.created_at,
      purchase_return_number: record?.purchase_return_number,
    },
  ]

  return (
    <>
      <Button
        icon={<Icons.DollarOutlined style={{ fontSize: 18 }} />}
        onClick={showDrawer}
      >
        Record Payment
      </Button>
      <Drawer title="Payment" visible={open} onClose={onClose} width={"60vw"}>
        <PurchaseReturnPaymentCreate
          initialValues={{
            ...record,
            purchase_return_id: record.id,
            purchase_returns,
            paid_through_account_id: "",
            paid_through_account_name: "",
            payment_date: "",
            payment_number: 0,
            amount: 0,
            charge_amount: 0,
            payment_method: "",
            total_amount: 0,
          }}
          breadcrumb={false}
          title="Create Purchase Return Payment"
          // title={false}
          // goBack={false}
        />
      </Drawer>
    </>
  )
}

export default PurchaseReturnPaymentButton
