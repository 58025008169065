export const calcTax = (tax: number, amount: number): number => {
  return (amount * tax) / 100
}

export const calcDiscount = (
  amount: number,
  discount_setting: string,
  discount: number = 0
): number => {
  if (discount_setting === "flat") {
    return discount
  } else if (discount_setting === "percentage") {
    return (amount * discount) / 100
  } else {
    return 0
  }
}
