import {
  Col,
  Edit,
  Form,
  Input,
  InputNumber,
  RefreshButton,
  Row,
  Typography,
  useForm,
} from "@pankod/refine-antd"
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core"
import { IOvertime } from "interfaces"

const { Title } = Typography

export const OvertimeEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<IOvertime>({
    redirect: false,
  })

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      headerButtons={({ defaultButtons: _ }) => <RefreshButton />}
    >
      <Form {...formProps} layout="vertical">
        <Row>
          <Col xs={24} md={18} lg={16}>
            <Row gutter={[32, 0]}>
              <Col xs={24} md={12}>
                <Form.Item label={t("overtime.fields.name")} name="name">
                  <Input placeholder="Enter name" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("overtime.fields.description")}
                  name="description"
                >
                  <Input placeholder="Enter description" />
                </Form.Item>
              </Col>
            </Row>
            <Title level={5}>Settings</Title>
            <Row gutter={[32, 0]}>
              <Col xs={24} md={12}>
                <Form.Item label={t("overtime.fields.rate")} name="rate">
                  <InputNumber
                    placeholder="Enter rate"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("overtime.fields.per_hour")}
                  name="per_hour"
                >
                  <InputNumber
                    placeholder="Enter per hour overtime"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("overtime.fields.per_weekend")}
                  name="per_weekend"
                >
                  <InputNumber
                    placeholder="Enter per weekend overtime"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("overtime.fields.per_holiday")}
                  name="per_holiday"
                >
                  <InputNumber
                    placeholder="Enter per holiday overtime"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Edit>
  )
}
