import {
  Col,
  Edit,
  Form,
  Input,
  InputNumber,
  RefreshButton,
  Row,
  Select,
  Typography,
  useForm,
} from "@pankod/refine-antd"
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core"
import { IBonusIncentive } from "interfaces"

const { Title } = Typography

export const BonusIncentiveEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<IBonusIncentive>({
    redirect: false,
  })

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      headerButtons={({ defaultButtons: _ }) => <RefreshButton />}
    >
      <Form {...formProps} layout="vertical">
        <Row>
          <Col xs={24} md={18} lg={16}>
            <Row gutter={[32, 0]}>
              <Col xs={24} md={12}>
                <Form.Item label={t("bonus_incentive.fields.name")} name="name">
                  <Input placeholder="Enter name" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("bonus_incentive.fields.description")}
                  name="description"
                >
                  <Input placeholder="Enter description" />
                </Form.Item>
              </Col>
            </Row>
            <Title level={5}>Settings</Title>
            <Row gutter={[32, 0]}>
              <Col xs={24} md={12}>
                <Form.Item label={t("bonus_incentive.fields.type")} name="type">
                  <Select
                    options={[
                      { label: "Percentage", value: "percentage" },
                      { label: "Fixed", value: "fixed" },
                    ]}
                    placeholder="Select type"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("bonus_incentive.fields.amount")}
                  name="amount"
                >
                  <InputNumber
                    placeholder="Enter amount"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Edit>
  )
}
