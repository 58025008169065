import { NumberField, useSimpleList } from "@pankod/refine-antd"
import { useGetIdentity } from "@pankod/refine-core"
import { IExpenseSummary, IIncomeSummary } from "interfaces"
import { Column } from "@ant-design/plots"
import { Col, List, Row, Typography } from "antd"

const { Title } = Typography

export const IncomeVsExpenseReport: React.FC = () => {
  const { data: identity } = useGetIdentity()

  const currency = identity?.default?.currency ?? "NPR"

  const { listProps: incomeListProps } = useSimpleList<IIncomeSummary>({
    resource: "transactions/reports/income-summary",

    initialSorter: [
      {
        field: "updated_at",
        order: "desc",
      },
    ],
  })

  const { listProps: expenseListProps } = useSimpleList<IExpenseSummary>({
    resource: "transactions/reports/expense-summary",

    initialSorter: [
      {
        field: "updated_at",
        order: "desc",
      },
    ],
  })

  return (
    <div style={{ margin: "1em 2em" }}>
      <section>
        <Title level={5}>Income vs Expense</Title>
      </section>
      <Row gutter={[64, 32]}>
        <Col xs={24} sm={12}>
          <List
            {...incomeListProps}
            pagination={false}
            header={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "bold",
                }}
              >
                <div>Incomes</div>
                <div>
                  <NumberField
                    value={
                      incomeListProps?.dataSource?.reduce(
                        (acc, curr) => curr.total + acc,
                        0
                      ) ?? 0
                    }
                    options={{
                      style: "currency",
                      currency,
                    }}
                  />
                </div>
              </div>
            }
            renderItem={(item, index) => (
              <List.Item key={index}>
                <div>{item.category}</div>
                <div>
                  <NumberField
                    value={item.total}
                    options={{
                      style: "currency",
                      currency,
                    }}
                  />
                </div>
              </List.Item>
            )}
          />
        </Col>
        <Col xs={24} sm={12}>
          <Column
            data={incomeListProps?.dataSource ?? []}
            xField="category"
            yField="total"
            label={{
              position: "middle",
            }}
            meta={{
              category: {
                alias: "Category",
              },
              total: {
                alias: "Total",
                formatter: (v) => {
                  return new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency,
                  }).format(v)
                },
              },
            }}
          />
        </Col>
      </Row>
      <br />
      <br />
      <Row gutter={[64, 32]}>
        <Col xs={24} sm={12}>
          <List
            {...expenseListProps}
            pagination={false}
            header={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "bold",
                }}
              >
                <div>Expenses</div>
                <div>
                  <NumberField
                    value={
                      expenseListProps?.dataSource?.reduce(
                        (acc, curr) => curr.total + acc,
                        0
                      ) ?? 0
                    }
                    options={{
                      style: "currency",
                      currency,
                    }}
                  />
                </div>
              </div>
            }
            renderItem={(item, index) => (
              <List.Item key={index}>
                <div>{item.category}</div>
                <div>
                  <NumberField
                    value={item.total}
                    options={{
                      style: "currency",
                      currency,
                    }}
                  />
                </div>
              </List.Item>
            )}
          />
        </Col>
        <Col xs={24} sm={12}>
          <Column
            data={expenseListProps?.dataSource ?? []}
            xField="category"
            yField="total"
            label={{
              position: "middle",
            }}
            meta={{
              category: {
                alias: "Category",
              },
              total: {
                alias: "Total",
                formatter: (v) => {
                  return new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency,
                  }).format(v)
                },
              },
            }}
          />
        </Col>
      </Row>
    </div>
  )
}
