import {
  useTranslate,
  IResourceComponentsProps,
  useNavigation,
  HttpError,
  CrudFilters,
} from "@pankod/refine-core"
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  ShowButton,
  NumberField,
  DateField,
  Button,
  Select,
  Row,
  Col,
  Card,
  Form,
  DatePicker,
  Input,
  Typography,
} from "@pankod/refine-antd"
import { green } from "@ant-design/colors"
import { ITransfer, ITransferFilterVariables } from "interfaces"
import { humanize } from "utils/humanize"
import { dateStringify } from "utils/date"
import { makeFilterable } from "utils/filter"

const { Title } = Typography

export const TransferList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { show } = useNavigation()

  const { tableProps, sorter, searchFormProps } = useTable<
    ITransfer,
    HttpError,
    ITransferFilterVariables
  >({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { date, payment_method, transfer_number } = params

      filters.push(
        {
          field: "date",
          operator: "eq",
          value: date ? dateStringify(date) : undefined,
        },
        {
          field: "payment_method",
          operator: "eq",
          value: makeFilterable(payment_method),
        },
        {
          field: "transfer_number",
          operator: "eq",
          value: makeFilterable(transfer_number),
        }
      )

      return filters
    },
  })

  return (
    <List>
      <Row gutter={[12, 0]}>
        <Col span={5}>
          <Card size="small">
            <Title level={5}>Filters</Title>

            <Form {...searchFormProps}>
              <Form.Item name="date">
                <DatePicker style={{ width: "100%" }} placeholder="Date" />
              </Form.Item>
              <Form.Item name="transfer_number">
                <Input placeholder="Transfer Number" />
              </Form.Item>
              <Form.Item name="payment_method">
                <Select
                  allowClear
                  placeholder="Payment Method"
                  options={[
                    { label: "Cash", value: "cash" },
                    { label: "Cheque", value: "cheque" },
                    { label: "Bank Transfer", value: "bank_transfer" },
                    { label: "Other", value: "other" },
                  ]}
                />
              </Form.Item>

              <Button
                htmlType="submit"
                type="primary"
                style={{ width: "100%" }}
              >
                Search
              </Button>
            </Form>
          </Card>
        </Col>
        <Col span={19}>
          <Table {...tableProps} size="small" rowKey="id">
            <Table.Column
              dataIndex="date"
              key="date"
              title={t("transfers.fields.date")}
              render={(value) => <DateField value={value} />}
              defaultSortOrder={getDefaultSortOrder("date", sorter)}
              sorter
            />
            <Table.Column<ITransfer>
              dataIndex="transfer_number"
              key="transfer_number"
              title={t("transfers.fields.transfer_number")}
              render={(value, record) => (
                <div
                  onClick={() => show("transfers", record.id)}
                  style={{ cursor: "pointer" }}
                >
                  <NumberField
                    value={value ? value : ""}
                    style={{ color: green[4] }}
                  />
                </div>
              )}
              defaultSortOrder={getDefaultSortOrder("transfer_number", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="from_account"
              key="from_account"
              title={t("transfers.fields.from_account")}
              render={(value) => <TextField value={value ? value.name : ""} />}
              defaultSortOrder={getDefaultSortOrder("from_account_id", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="to_account"
              key="to_account"
              title={t("transfers.fields.to_account")}
              render={(value) => <TextField value={value ? value.name : ""} />}
              defaultSortOrder={getDefaultSortOrder("to_account_id", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="payment_method"
              key="payment_method"
              title={t("transfers.fields.payment_method")}
              render={(value) => (
                <TextField value={value ? humanize(value) : ""} />
              )}
              defaultSortOrder={getDefaultSortOrder("payment_method", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="amount"
              key="amount"
              title={t("transfers.fields.amount")}
              render={(value) => (
                <NumberField
                  value={value}
                  options={{
                    style: "currency",
                    currency: "NPR",
                  }}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("amount", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="reference"
              key="reference"
              title={t("transfers.fields.reference")}
              render={(value) => <TextField value={value ? value : ""} />}
            />
            <Table.Column<ITransfer>
              title={t("table.actions")}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <ShowButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
    </List>
  )
}
