import React from "react"

import {
  AntdLayout,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography,
} from "@pankod/refine-antd"
import { useCreate, useNavigation } from "@pankod/refine-core"
import { useSearchParams } from "react-router-dom"

const { Text, Title, Link } = Typography

export interface IResetPasswordForm {
  token: string
  password: string
}

export const ResetPassword: React.FC = () => {
  const [form] = Form.useForm<IResetPasswordForm>()

  const navigate = useNavigation()
  const [searchParams] = useSearchParams()

  const { mutate: reset } = useCreate<IResetPasswordForm>()

  const onFinish = (values: IResetPasswordForm) => {
    reset({
      resource: "users/reset-password",
      values: {
        token: searchParams.get("token"),
        password: values.password,
      },
      successNotification(_data, _values, _resource) {
        navigate.push("/login")
        return {
          type: "success",
          message: "Successfully reset password.",
        }
      },
    })
  }

  const CardTitle = (
    <Title level={3} className="title">
      Reset your password
    </Title>
  )

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <img src="./refine.svg" alt="Refine Logo" height={30} />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<IResetPasswordForm>
                layout="vertical"
                form={form}
                onFinish={(values) => onFinish(values)}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}
              >
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true }, { min: 8 }]}
                >
                  <Input.Password placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  label="Confirm Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        )
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                <Button type="primary" size="large" htmlType="submit" block>
                  Submit
                </Button>
              </Form>
              <div style={{ marginTop: 8 }}>
                <Text style={{ fontSize: 12 }}>
                  Don’t have an account?{" "}
                  <Link href="/register" style={{ fontWeight: "bold" }}>
                    Sign up
                  </Link>
                </Text>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  )
}
