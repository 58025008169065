import { IInvoice, IInvoicePayment, ICoa, IVendor } from "interfaces"

import {
  Card,
  Checkbox,
  Col,
  Create,
  DatePicker,
  Form,
  getValueFromEvent,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  Typography,
  Upload,
  useForm,
  useSelect,
} from "@pankod/refine-antd"
import axios from "axios"
import {
  IResourceComponentsProps,
  useApiUrl,
  useTranslate,
} from "@pankod/refine-core"
import dayjs from "dayjs"
import { orange } from "@ant-design/colors"

interface IProps extends IResourceComponentsProps {
  initialValues?: Omit<IInvoicePayment, "id">
  breadcrumb?: React.ReactNode
  title?: React.ReactNode
  goBack?: React.ReactNode
}

export const InvoicePaymentCreate: React.FC<IProps> = (props) => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<IInvoicePayment>({
    resource: "invoice-payments",
    action: "create",
  })

  const { selectProps: customerSelectProps, queryResult: customerQueryResult } =
    useSelect<IVendor>({
      resource: "customers",
      optionLabel: "name",
      optionValue: "id",
    })

  const { selectProps: paidToSelectProps, queryResult: paidToQueryResult } =
    useSelect<ICoa>({
      resource: "accounts",
      optionLabel: "name",
      optionValue: "id",
    })

  const apiUrl = useApiUrl()

  const handleCustomerChange = async (value: string) => {
    const customer = customerQueryResult?.data?.data.find((v) => v.id === value)
    if (customer) {
      formProps?.form?.setFieldsValue({
        customer_name: customer.name,
      })

      try {
        const { data } = await axios.get(
          `${apiUrl}/invoices?customer_id=${value}&status=sent&status=partially_paid`,
          { withCredentials: true }
        )
        const invoices = data?.docs?.map(
          (invoice: IInvoice, index: number) => ({
            invoice_date: invoice.invoice_date,
            invoice_number: invoice.invoice_number,
            id: invoice.id,
            invoice_amount: invoice.total,
            due_amount: invoice.total - invoice.paid_amount,
            payment_amount: 0,
            account_id: invoice?.items[index]?.account_id,
            category_id: invoice?.category_id,
          })
        )
        formProps?.form?.setFieldsValue({ invoices })
      } catch (err) {}
    }
  }

  const handlePaymentThroughChange = (value: string) => {
    const account = paidToQueryResult?.data?.data.find(
      (c) => c.id === value?.toString()
    )
    if (account) {
      formProps?.form?.setFieldsValue({
        paid_to_account_name: account.name,
      })
    }
  }

  const handlePayfullItemClick = (index: number) => {
    const invoices = formProps?.form?.getFieldValue("invoices")
    const newInvoices = invoices?.map((invoice: IInvoice, idx: number) => {
      if (idx === index) {
        return {
          ...invoice,
          payment_amount: invoice?.due_amount,
        }
      }
      return invoice
    })
    formProps?.form?.setFieldsValue({
      invoices: newInvoices,
    })
    return
  }

  const handlePayFullCheck = (checked: boolean) => {
    if (checked) {
      const invoices = formProps?.form?.getFieldValue("invoices")
      const newInvoices = invoices?.map((invoice: IInvoice) => {
        return {
          ...invoice,
          payment_amount: invoice?.due_amount,
        }
      })
      formProps?.form?.setFieldsValue({
        invoices: newInvoices,
        amount: invoices?.reduce(
          (acc: number, invoice: IInvoice) => acc + (invoice?.due_amount ?? 0),
          0
        ),
      })
    } else {
      const invoices = formProps?.form?.getFieldValue("invoices")
      const newInvoices = invoices?.map((invoice: IInvoice) => {
        return {
          ...invoice,
          payment_amount: 0,
        }
      })
      formProps?.form?.setFieldsValue({
        invoices: newInvoices,
        amount: 0,
      })
    }
  }

  const initialValues = {
    payment_date: dayjs().format("YYYY-MM-DD"),
    payment_method: "cash",
    // invoices: [{ invoice_amount: 0, payment_amount: 0 }],
    note: "Thanks for your business.",
  }

  if (props?.initialValues) {
    Object.assign(initialValues, props.initialValues)
  }

  return (
    <Create saveButtonProps={saveButtonProps} {...props}>
      <Form {...formProps} layout="vertical" initialValues={initialValues}>
        <Row gutter={[32, 0]}>
          <Col xs={24} sm={16} md={14} lg={10}>
            <Form.Item
              label={t("invoices.fields.customer_name")}
              name="customer_id"
              rules={[
                { required: true, message: t("invoices.errors.customer_name") },
              ]}
            >
              <Select
                {...customerSelectProps}
                showSearch
                allowClear
                onChange={(value) => {
                  handleCustomerChange(value?.toString())
                }}
                placeholder="Select a customer"
              />
            </Form.Item>
            <Form.Item name="customer_name" hidden>
              <Input />
            </Form.Item>
            <Row gutter={[8, 0]}>
              <Col xs={24} lg={16}>
                <Form.Item
                  label={t("invoices.fields.payment_amount")}
                  name="amount"
                  // labelCol={{ span: 6 }}
                  // wrapperCol={{ span: 10 }}
                >
                  <InputNumber
                    addonBefore="NPR"
                    placeholder="Payment amount."
                  />
                </Form.Item>
                <Form.Item name="full_check" labelCol={{ span: 6 }}>
                  <Checkbox
                    onChange={(e) => {
                      handlePayFullCheck(e.target.checked)
                    }}
                  >
                    {t("invoices.fields.payment_full_check")}
                  </Checkbox>
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={12}>
                <Form.Item
                  name="charge_amount"
                  label={t("invoices.fields.charge_amount")}
                  // labelCol={{ span: 6 }}
                  // wrapperCol={{ span: 10 }}
                >
                  <InputNumber />
                </Form.Item>
              </Col> */}
            </Row>

            <Form.Item
              label={t("invoices.fields.payment_reference")}
              name="reference"
            >
              <Input placeholder="Reference" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={16} md={14} lg={10}>
            <Form.Item
              label={t("invoices.fields.payment_date")}
              name="payment_date"
              getValueProps={(value) => {
                return {
                  value: value ? dayjs(value) : "",
                }
              }}
              rules={[{ required: true }]}
              // labelCol={{ span: 6 }}
              // wrapperCol={{ span: 10 }}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label={t("invoices.fields.payment_method")}
              name="payment_method"
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  { value: "bank_transfer", label: "Bank Transfer" },
                  { value: "cash", label: "Cash" },
                  { value: "cheque", label: "Cheque" },
                  { value: "credit", label: "Credit" },
                ]}
              />
            </Form.Item>
            <Form.Item
              label={t("invoices.fields.payment_to_account_name")}
              name="paid_to_account_id"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                allowClear
                {...paidToSelectProps}
                onChange={(value) => {
                  handlePaymentThroughChange(value?.toString())
                }}
                placeholder="Select a account"
              />
            </Form.Item>
            <Form.Item name="paid_to_account_name" hidden>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={22} md={20} lg={18}>
            <Form.List name="invoices">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    <Table
                      columns={[
                        {
                          title: t("invoices.fields.invoice_date"),
                          dataIndex: "invoice_date",
                          key: "invoice_date",
                          render: (value, record, index) => {
                            return (
                              <>
                                <Typography.Title
                                  level={5}
                                  style={{ margin: 0 }}
                                >
                                  {dayjs(value).format("DD MMM YYYY")}
                                </Typography.Title>

                                <Typography.Text>
                                  Due date:{" "}
                                  <strong>
                                    {dayjs(record[index]?.due_date).format(
                                      "DD MMM YYYY"
                                    )}
                                  </strong>
                                </Typography.Text>
                              </>
                            )
                          },
                        },
                        {
                          title: t("invoices.fields.invoice_number"),
                          dataIndex: "invoice_number",
                          key: "invoice_number",
                        },
                        {
                          title: t("invoices.fields.invoice_amount"),
                          dataIndex: "invoice_amount",
                          key: "invoice_amount",
                          render: (value) => <strong>{value}</strong>,
                        },
                        {
                          title: t("invoices.fields.due_amount"),
                          dataIndex: "due_amount",
                          key: "due_amount",
                          render: (value) => <strong>{value}</strong>,
                        },
                        {
                          title: t("invoices.fields.payment_amount"),
                          dataIndex: "payment_amount",
                          key: "payment_amount",
                          render: (value, record, index) => {
                            return (
                              <>
                                <Form.Item
                                  name={[index, "payment_amount"]}
                                  rules={[{ required: true }]}
                                  shouldUpdate={(prevValues, currentValues) => {
                                    return (
                                      prevValues?.invoices?.length !==
                                      currentValues?.invoices?.length
                                    )
                                  }}
                                  noStyle
                                >
                                  <InputNumber />
                                </Form.Item>
                                <br />
                                <Form.Item noStyle>
                                  <Typography.Link
                                    onClick={() =>
                                      handlePayfullItemClick(index)
                                    }
                                  >
                                    Pay in Full
                                  </Typography.Link>
                                </Form.Item>
                              </>
                            )
                          },
                        },
                        {
                          title: "",
                          dataIndex: "action",
                          key: "action",
                          render: (value, record, index) => {
                            return (
                              <Typography.Link
                                type="danger"
                                onClick={() => {
                                  remove(index)
                                }}
                              >
                                Remove
                              </Typography.Link>
                            )
                          },
                        },
                      ]}
                      dataSource={
                        formProps?.form?.getFieldValue("invoices") || []
                      }
                      pagination={false}
                      rowKey="id"
                    />
                  </div>
                )
              }}
            </Form.List>
          </Col>
        </Row>
        <br />
        <Row gutter={[64, 0]}>
          <Col xs={24} md={{ span: 10, offset: 5 }}>
            <Card
              style={{
                backgroundColor: orange[0],
                lineHeight: 2,
                maxWidth: 450,
              }}
            >
              <Row justify="space-between">
                <Col>
                  <Typography.Text style={{ color: orange[5] }}>
                    Amount used for payment
                  </Typography.Text>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues?.invoices !== currentValues?.invoices
                    }
                    noStyle
                  >
                    {({ getFieldValue }) => {
                      const invoices = getFieldValue("invoices") || []
                      const total = invoices.reduce(
                        (acc: number, cur: IInvoice) =>
                          acc + (cur?.payment_amount ?? 0),
                        0
                      )
                      return (
                        <Typography.Text style={{ color: orange[5] }}>
                          {total.toFixed(2)}
                        </Typography.Text>
                      )
                    }}
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row justify="space-between">
                <Col>
                  <Typography.Text style={{ color: orange[5] }}>
                    Additional Charges
                  </Typography.Text>
                </Col>
                <Col>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues?.charge_amount !== currentValues?.charge_amount
                    }
                    noStyle
                  >
                    {({ getFieldValue }) => {
                      const chargeAmount = getFieldValue("charge_amount") || 0
                      return (
                        <Typography.Text style={{ color: orange[5] }}>
                          {chargeAmount.toFixed(2)}
                        </Typography.Text>
                      )
                    }}
                  </Form.Item>
                </Col>
              </Row> */}
            </Card>
          </Col>
          <Col xs={24} sm={16} md={14} lg={10}>
            <Form.Item label="Attachments">
              <Form.Item
                name="files"
                valuePropName="attachments"
                getValueFromEvent={getValueFromEvent}
                noStyle
              >
                <Upload.Dragger
                  name="file"
                  action={`${apiUrl}/files/upload`}
                  listType="picture"
                  maxCount={5}
                  multiple
                  withCredentials
                >
                  <p className="ant-upload-text">
                    Drag & drop a file in this area
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          labelAlign="left"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 10 }}
          label={t("invoices.fields.note")}
          name="note"
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Create>
  )
}
