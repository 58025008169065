import "styles/antd.less"
import "styles/index.less"

import {
  Footer,
  Header,
  Layout,
  OffLayoutArea,
  Sider,
  Title,
} from "components/layout"
import { ItemCreate, ItemEdit, ItemList, ItemShow } from "pages/items"
import { useTranslation } from "react-i18next"

import {
  ErrorComponent,
  notificationProvider,
  ReadyPage,
  Icons,
} from "@pankod/refine-antd"
import { Refine } from "@pankod/refine-core"
import { RefineKbarProvider } from "@pankod/refine-kbar"
import routerProvider from "@pankod/refine-react-router-v6"

import { authProvider } from "./authProvider"
import { dataProvider } from "./dataProvider"
import { CoaCreate, CoaEdit, CoaList, CoaShow } from "pages/coas"
import { BillCreate, BillEdit, BillList, BillShow } from "pages/purchases/bills"
import {
  BillPaymentCreate,
  BillPaymentList,
  BillPaymentShow,
} from "pages/purchases/payment-made"
import { SettingList } from "pages/settings"
import { AccountCreate, AccountList, AccountShow } from "pages/banking/accounts"
import {
  TransactionCreate,
  TransactionList,
  TransactionShow,
} from "pages/banking/transactions"
import { TransferCreate, TransferList } from "pages/banking/transfers"
import { Login } from "pages/login"
import { UserCreate, UserEdit, UserList, UserShow } from "pages/users"
import { RoleCreate, RoleEdit, RoleList, RoleShow } from "pages/roles"
import { VendorCreate, VendorEdit, VendorList, VendorShow } from "pages/vendors"
import {
  CustomerCreate,
  CustomerEdit,
  CustomerList,
  CustomerShow,
} from "pages/customers"
import {
  InvoiceCreate,
  InvoiceEdit,
  InvoiceList,
  InvoiceShow,
} from "pages/sales/invoices"
import {
  InvoicePaymentCreate,
  InvoicePaymentList,
  InvoicePaymentShow,
} from "pages/sales/payment-receive"
import {
  OrganizationEdit,
  OrganizationList,
} from "pages/settings/organizations"
import { TaxCreate, TaxEdit, TaxList } from "pages/settings/tax"
import {
  EmployeeCreate,
  EmployeeEdit,
  EmployeeList,
  EmployeeShow,
} from "pages/hr/employees"
import {
  CategoryCreate,
  CategoryEdit,
  CategoryList,
} from "pages/settings/categories"
import { DefaultEdit } from "pages/settings/default"
import { LocalizationEdit } from "pages/settings/localization"
import { TdsEdit } from "pages/hr/tds"
import { ProvidentFundEdit } from "pages/hr/provident-fund"
import { BonusIncentiveEdit } from "pages/hr/bonus-incentive"
import { OvertimeEdit } from "pages/hr/overtime"
import {
  PayrollList,
  PayrollCreate,
  PayrollEdit,
  PayrollShow,
} from "pages/hr/payrolls"
import { PayItemCreate, PayItemEdit, PayItemList } from "pages/hr/payitems"
import Home from "pages/home"
import { SaleReturnCreate, SaleReturnList } from "pages/sales/sale-return"
import { SaleReturnShow } from "pages/sales/sale-return/sale_return_show"
import {
  SaleReturnPaymentCreate,
  SaleReturnPaymentList,
  SaleReturnPaymentShow,
} from "pages/sales/sale-return-payment"
import {
  PurchaseReturnCreate,
  PurchaseReturnList,
} from "pages/purchases/purchase-return"
import { PurchaseReturnShow } from "pages/purchases/purchase-return/purchase_return_show"
import {
  PurchaseReturnPaymentCreate,
  PurchaseReturnPaymentList,
  PurchaseReturnPaymentShow,
} from "pages/purchases/purchase-return-payment"
import { TransferShow } from "pages/banking/transfers/transfer_show"
import { ReportList } from "pages/reports/main"
import { TransactionSummaryReport } from "pages/reports/transaction-summary"
import { ExpenseSummaryReport } from "pages/reports/expense-summary"
import { IncomeSummaryReport } from "pages/reports/income-summary/income_summary_report"
import { IncomeVsExpenseReport } from "pages/reports/income-vs-expense/income_vs_expense_report"
import { ProfitVsLossReport } from "pages/reports/profit-vs-loss"
import { GeneralLedgerReport } from "pages/reports/general-ledger"
import {
  IntegrationCreate,
  IntegrationEdit,
  IntegrationList,
} from "pages/settings/integrations"
import Register from "pages/register"
import { Dashboard } from "components/dashboard"

import { AttendanceCreate, AttendanceList } from "pages/hr/attendances"
import { PosCreate } from "pages/pos"
import { TaxSummaryReport } from "pages/reports/tax-summary"
import { ForgotPassword } from "pages/forgot-password"
import { ResetPassword } from "pages/reset-password"
import { MailList } from "pages/notifications/mails"
import { SMSList } from "pages/notifications/sms"
import { ContactUsList } from "pages/contact-us"
import CustomerServiceList from "pages/customer-service/customer_service_list"

function App() {
  const { t, i18n } = useTranslation()

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  }

  const API_URL = process.env.REACT_APP_API_URL

  if (!API_URL) {
    throw new Error("REACT_APP_API_URL is not defined")
  }

  return (
    <RefineKbarProvider>
      <Refine
        notificationProvider={notificationProvider}
        ReadyPage={ReadyPage}
        catchAll={<ErrorComponent />}
        routerProvider={{
          ...routerProvider,
          routes: [
            {
              element: <Home />,
              path: "/",
            },
            {
              element: <Register />,
              path: "/register",
            },
            {
              element: <ForgotPassword />,
              path: "/forgot-password",
            },
            {
              element: <ResetPassword />,
              path: "/reset-password",
            },
            {
              element: <SettingList />,
              path: "/settings",
              layout: true,
            },
            {
              element: <TaxList />,
              path: "/tax",
              layout: true,
            },
            {
              element: <IntegrationList />,
              path: "/integrations",
              layout: true,
            },
            {
              element: <CategoryList />,
              path: "/categories",
              layout: true,
            },
            {
              element: <PayItemList />,
              path: "/pay-items",
              layout: true,
            },
            {
              element: <TransactionSummaryReport />,
              path: "/reports/transaction-summary",
              layout: true,
            },
            {
              element: <ExpenseSummaryReport />,
              path: "/reports/expense-summary",
              layout: true,
            },
            {
              element: <IncomeSummaryReport />,
              path: "/reports/income-summary",
              layout: true,
            },
            {
              element: <IncomeVsExpenseReport />,
              path: "/reports/income-vs-expense",
              layout: true,
            },
            {
              element: <ProfitVsLossReport />,
              path: "/reports/profit-vs-loss",
              layout: true,
            },
            {
              element: <GeneralLedgerReport />,
              path: "/reports/general-ledger",
              layout: true,
            },
            {
              element: <TaxSummaryReport />,
              path: "/reports/tax-summary",
              layout: true,
            },
            {
              element: <OrganizationList />,
              path: "/organizations",
              layout: true,
            },
            {
              element: <ContactUsList />,
              path: "/customer-service/contact-us",
              layout: true,
            },
            {
              element: <SMSList />,
              path: "/customer-service/sms",
              layout: true,
            },
            {
              element: <MailList />,
              path: "/customer-service/mails",
              layout: true,
            },
          ],
        }}
        dataProvider={{
          default: dataProvider(API_URL),
        }}
        authProvider={authProvider}
        LoginPage={Login}
        DashboardPage={Dashboard}
        resources={[
          {
            name: "items",
            list: ItemList,
            create: ItemCreate,
            edit: ItemEdit,
            show: ItemShow,
            icon: <Icons.ShopOutlined />,
          },
          {
            name: "sales",
            icon: <Icons.GoldOutlined />,
            options: {
              label: t("sales.sales"),
            },
          },
          {
            name: "customers",
            parentName: "sales",
            list: CustomerList,
            create: CustomerCreate,
            edit: CustomerEdit,
            show: CustomerShow,
          },
          {
            name: "invoices",
            parentName: "sales",
            list: InvoiceList,
            create: InvoiceCreate,
            edit: InvoiceEdit,
            show: InvoiceShow,
          },
          {
            name: "invoice-payments",
            parentName: "sales",
            list: InvoicePaymentList,
            create: InvoicePaymentCreate,
            show: InvoicePaymentShow,
          },
          {
            name: "sale-returns",
            parentName: "sales",
            list: SaleReturnList,
            create: SaleReturnCreate,
            show: SaleReturnShow,
            options: { label: "Return" },
          },
          {
            name: "sale-return-payments",
            parentName: "sales",
            list: SaleReturnPaymentList,
            create: SaleReturnPaymentCreate,
            show: SaleReturnPaymentShow,
            options: { label: "Return Payment" },
          },

          {
            name: "account-types",
          },

          {
            name: "purchases",
            icon: <Icons.ShoppingOutlined />,
          },
          {
            name: "vendors",
            parentName: "purchases",
            list: VendorList,
            create: VendorCreate,
            edit: VendorEdit,
            show: VendorShow,
          },
          {
            name: "bills",
            parentName: "purchases",
            list: BillList,
            create: BillCreate,
            edit: BillEdit,
            show: BillShow,
          },
          {
            name: "bill-payments",
            parentName: "purchases",
            options: {
              label: "Payment Made",
            },
            list: BillPaymentList,
            create: BillPaymentCreate,
            show: BillPaymentShow,
          },
          {
            name: "purchase-returns",
            parentName: "purchases",
            list: PurchaseReturnList,
            create: PurchaseReturnCreate,
            show: PurchaseReturnShow,
            options: { label: "Return" },
          },
          {
            name: "purchase-return-payments",
            parentName: "purchases",
            list: PurchaseReturnPaymentList,
            create: PurchaseReturnPaymentCreate,
            show: PurchaseReturnPaymentShow,
            options: { label: "Return Payment" },
          },
          {
            name: "banking",
            icon: <Icons.BankOutlined />,
          },
          {
            name: "accounts",
            parentName: "banking",
            list: AccountList,
            create: AccountCreate,
            show: AccountShow,
          },
          {
            name: "transactions",
            parentName: "banking",
            list: TransactionList,
            create: TransactionCreate,
            show: TransactionShow,
          },
          {
            name: "transfers",
            parentName: "banking",
            list: TransferList,
            create: TransferCreate,
            show: TransferShow,
          },
          {
            name: "hr",
            icon: <Icons.WalletOutlined />,
            options: {
              label: "HR",
            },
          },
          {
            name: "employees",
            parentName: "hr",
            list: EmployeeList,
            create: EmployeeCreate,
            edit: EmployeeEdit,
            show: EmployeeShow,
          },
          {
            name: "payrolls",
            parentName: "hr",
            list: PayrollList,
            create: PayrollCreate,
            edit: PayrollEdit,
            show: PayrollShow,
          },
          {
            name: "attendances",
            parentName: "hr",
            list: AttendanceList,
            edit: AttendanceCreate,
          },
          {
            name: "accountants",
            icon: <Icons.TeamOutlined />,
          },
          {
            name: "coas",
            parentName: "accountants",
            list: CoaList,
            create: CoaCreate,
            edit: CoaEdit,
            show: CoaShow,
          },
          {
            name: "reports",
            icon: <Icons.PieChartOutlined />,
            list: ReportList,
          },
          {
            name: "cutomer-service",
            icon: <Icons.CustomerServiceOutlined />,
            list: CustomerServiceList,
          },
          {
            name: "admin/users",
            list: UserList,
            show: UserShow,
            create: UserCreate,
            edit: UserEdit,
            icon: <Icons.UserOutlined />,
            options: {
              label: "Users",
            },
          },
          {
            name: "admin/roles",
            list: RoleList,
            show: RoleShow,
            create: RoleCreate,
            edit: RoleEdit,
            icon: <Icons.TeamOutlined />,
            options: {
              label: "Roles",
            },
          },
          {
            name: "tax",
            edit: TaxEdit,
            create: TaxCreate,
            options: {},
          },
          {
            name: "integrations",
            edit: IntegrationEdit,
            create: IntegrationCreate,
            options: {},
          },
          {
            name: "categories",
            edit: CategoryEdit,
            create: CategoryCreate,
            options: {},
          },
          {
            name: "settings",
          },
          {
            name: "organizations",
            edit: OrganizationEdit,
          },
          {
            name: "default",
            edit: DefaultEdit,
          },
          {
            name: "localization",
            edit: LocalizationEdit,
          },
          {
            name: "tds",
            edit: TdsEdit,
          },
          {
            name: "provident-fund",
            edit: ProvidentFundEdit,
          },
          {
            name: "bonus-incentive",
            edit: BonusIncentiveEdit,
          },
          {
            name: "overtime",
            edit: OvertimeEdit,
          },
          {
            name: "pay-items",
            edit: PayItemEdit,
            create: PayItemCreate,
            options: {},
          },
          {
            name: "invoices/pos",
            create: PosCreate,
            options: {
              label: "POS",
            },
          },
        ]}
        Title={Title}
        Header={Header}
        Sider={Sider}
        Footer={Footer}
        Layout={Layout}
        OffLayoutArea={OffLayoutArea}
        i18nProvider={i18nProvider}
      />
    </RefineKbarProvider>
  )
}

export default App
