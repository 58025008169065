import { Tag, TagProps } from "antd"
import { grey, orange, blue, green, volcano, red } from "@ant-design/colors"
import { humanize } from "utils/humanize"

interface StatusTagFieldProps extends TagProps {
  status?: string
}

const color: { [key: string]: string } = {
  draft: grey[5],
  open: orange[5],
  pending: blue[5],
  paid: green[5],
  partially_paid: volcano[5],
  cancelled: red[5],
}

export const StatusTagField: React.FC<StatusTagFieldProps> = ({
  status,
  ...props
}) => {
  if (!status) return null
  return (
    <Tag color={color[status]} {...props} style={{ fontSize: "1rem" }}>
      {humanize(status)}
    </Tag>
  )
}
