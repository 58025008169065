import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core"
import {
  Edit,
  Form,
  Input,
  Select,
  useForm,
  Row,
  Col,
  Checkbox,
} from "@pankod/refine-antd"

import { IVendor } from "interfaces"

export const VendorEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<IVendor>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row>
          <Col span={14}>
            <Form.Item
              label={t("vendors.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
            <Row gutter={[16, 0]}>
              <Col xs={12}>
                <Form.Item label={t("vendors.fields.email")} name="email">
                  <Input placeholder="Enter Email" />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label={t("vendors.fields.phone")}
                  name="phone"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Enter Phone" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={12}>
                <Form.Item label={t("vendors.fields.website")} name="website">
                  <Input placeholder="Enter Website" />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label={t("vendors.fields.reference")}
                  name="reference"
                >
                  <Input placeholder="Enter Reference" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 0]}>
              <Col xs={12}>
                <Form.Item
                  label={t("vendors.fields.tax_number")}
                  name="tax_number"
                >
                  <Input placeholder="Enter Tax Number" />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label={t("vendors.fields.currency")}
                  name="currency"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    options={[{ value: "NPR", label: "Nepali Rupees" }]}
                    placeholder="Select Currency"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label={t("vendors.fields.address")} name="address">
              <Input.TextArea placeholder="Enter Address" />
            </Form.Item>
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={12}>
                <Form.Item label={t("vendors.fields.city")} name="city">
                  <Input placeholder="Enter City" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t("vendors.fields.postal_code")}
                  name="postal_code"
                >
                  <Input placeholder="Enter Postal Code" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label={t("vendors.fields.state")} name="state">
                  <Input placeholder="Enter State" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label={t("vendors.fields.country")} name="country">
                  <Input placeholder="Enter Country" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="activated"
              label={t("vendors.fields.activated")}
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  )
}
