import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
} from "@pankod/refine-core"
import { Show, Typography } from "@pankod/refine-antd"

import { ICategory } from "interfaces"

const { Title, Text } = Typography

export const CategoryShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { queryResult } = useShow<ICategory>()
  const { data, isLoading } = queryResult
  const record = data?.data

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{t("categories.fields.name")}</Title>
      <Text>{record?.name}</Text>
    </Show>
  )
}
