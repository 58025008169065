import {
  useTranslate,
  IResourceComponentsProps,
  useGetIdentity,
} from "@pankod/refine-core"
import {
  Create,
  Form,
  Input,
  Select,
  useSelect,
  useForm,
  Radio,
  Checkbox,
  Typography,
  Row,
  Col,
  InputNumber,
  DatePicker,
  Tooltip,
  Icons,
} from "@pankod/refine-antd"
import dayjs from "dayjs"

import "react-mde/lib/styles/css/react-mde-all.css"

import { IItem, ICoa, ITax } from "interfaces"

const { Title } = Typography
const { QuestionCircleOutlined } = Icons

export const ItemCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<IItem>()

  const { selectProps: coasSelectProps, queryResult: coasQueryResult } =
    useSelect<ICoa>({
      resource: "coas",
      optionLabel: "name",
      optionValue: "id",
      onSearch: (value) => [
        {
          field: "q",
          operator: "eq",
          value,
        },
      ],
      fetchSize: 500,
    })

  const { selectProps: taxSelectProps, queryResult: taxQueryResult } =
    useSelect<ITax>({
      resource: "tax",
      optionLabel: "name",
      optionValue: "id",
    })

  const { data: identity } = useGetIdentity()

  const manageInventory = identity?.default?.manage_inventory

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        // layout="vertical"
        initialValues={{
          has_sales_info: true,
          has_purchase_info: true,
        }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        labelAlign="left"
      >
        <Row gutter={[32, 0]}>
          <Col xs={24} md={12} lg={10}>
            <Form.Item
              label={t("items.fields.type.title")}
              name="type"
              initialValue="goods"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value="goods">{t("items.fields.type.goods")}</Radio>
                <Radio value="service">{t("items.fields.type.service")}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={t("items.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={t("items.fields.unit.title")} name="unit">
              <Select
                showSearch
                options={[
                  {
                    label: t("items.fields.unit.box"),
                    value: "box",
                  },
                  {
                    label: t("items.fields.unit.cm"),
                    value: "cm",
                  },
                  {
                    label: t("items.fields.unit.m"),
                    value: "m",
                  },
                  {
                    label: t("items.fields.unit.in"),
                    value: "in",
                  },
                  {
                    label: t("items.fields.unit.ft"),
                    value: "ft",
                  },
                  {
                    label: t("items.fields.unit.kg"),
                    value: "kg",
                  },
                  {
                    label: t("items.fields.unit.km"),
                    value: "km",
                  },
                  {
                    label: t("items.fields.unit.mg"),
                    value: "mg",
                  },
                  {
                    label: t("items.fields.unit.gm"),
                    value: "gm",
                  },
                  {
                    label: t("items.fields.unit.ml"),
                    value: "ml",
                  },

                  {
                    label: t("items.fields.unit.l"),
                    value: "",
                  },
                  {
                    label: t("items.fields.unit.pcs"),
                    value: "pcs",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item label={t("items.fields.tags")} name="tags">
              <Select showSearch mode="tags" />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t("items.fields.stock")}{" "}
                  <Tooltip title="Visit default settings whether to manage inventory or not.">
                    <QuestionCircleOutlined
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                </span>
              }
              name="stock"
              rules={manageInventory ? [{ required: true }] : []}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Enter Stock Quantity"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={10}>
            <Form.Item label={t("items.fields.barcode")} name="barcode">
              <Input placeholder="Enter barcode" />
            </Form.Item>
            <Form.Item label={t("items.fields.sku")} name="sku">
              <Input placeholder="Enter SKU" />
            </Form.Item>
            <Form.Item label={t("items.fields.brand")} name="brand">
              <Input placeholder="Enter brand" />
            </Form.Item>
            <Form.Item
              label={t("items.fields.manufactured_date")}
              name="manufactured_date"
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label={t("items.fields.expiry_date")}
              name="expiry_date"
              dependencies={["manufactured_date"]}
              getValueProps={(value) => {
                return {
                  value: value ? dayjs(value) : "",
                }
              }}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      getFieldValue("manufactured_date") &&
                      value &&
                      dayjs(value).isBefore(getFieldValue("manufactured_date"))
                    ) {
                      return Promise.reject(
                        "Expiry date should be after manufactured date"
                      )
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[32, 0]}>
          <Col xs={24} md={12} lg={10}>
            <Form.Item name="has_sales_info" valuePropName="checked">
              <Checkbox>
                <Title level={5}>{t("items.fields.has_sales_info")}</Title>
              </Checkbox>
            </Form.Item>
            <Form.Item
              label={t("items.fields.sales_price")}
              shouldUpdate={(prevValues, curValues) =>
                prevValues.has_sales_info !== curValues.has_sales_info
              }
            >
              {({ getFieldValue }) => {
                return (
                  <Form.Item name="sales_price" style={{ margin: 0 }}>
                    <InputNumber
                      addonBefore="NPR"
                      disabled={!getFieldValue("has_sales_info")}
                      style={{ width: "100%" }}
                      min={0}
                    />
                  </Form.Item>
                )
              }}
            </Form.Item>
            <Form.Item
              label={t("items.fields.sales_account_id")}
              shouldUpdate={(prevValues, curValues) =>
                prevValues.has_sales_info !== curValues.has_sales_info
              }
            >
              {({ getFieldValue }) => {
                return (
                  <Form.Item name="sales_account_id" style={{ margin: 0 }}>
                    <Select
                      showSearch
                      {...coasSelectProps}
                      disabled={!getFieldValue("has_sales_info")}
                      onChange={(value) => {
                        const salesAccount = coasQueryResult?.data?.data.find(
                          (item) => item.id === value?.toString()
                        )
                        if (salesAccount) {
                          formProps?.form?.setFieldsValue({
                            sales_account_access_name: salesAccount.access_name,
                            sales_account_name: salesAccount.name,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                )
              }}
            </Form.Item>
            <Form.Item name="sales_account_access_name" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="sales_account_name" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              label={t("items.fields.sales_description")}
              shouldUpdate={(prevValues, curValues) =>
                prevValues.has_sales_info !== curValues.has_sales_info
              }
            >
              {({ getFieldValue }) => {
                return (
                  <Form.Item name="sales_description" style={{ margin: 0 }}>
                    <Input.TextArea
                      disabled={!getFieldValue("has_sales_info")}
                    />
                  </Form.Item>
                )
              }}
            </Form.Item>
            <Form.Item
              label={t("items.fields.sales_tax")}
              shouldUpdate={(prevValues, curValues) =>
                prevValues.has_sales_info !== curValues.has_sales_info
              }
              rules={[{ required: true }]}
            >
              {({ getFieldValue }) => {
                return (
                  <Form.Item name="sales_tax_id" style={{ margin: 0 }}>
                    <Select
                      {...taxSelectProps}
                      allowClear
                      disabled={!getFieldValue("has_sales_info")}
                      onChange={(value) => {
                        const tax = taxQueryResult?.data?.data.find(
                          (tax) => tax.id === value?.toString()
                        )
                        if (tax) {
                          formProps?.form?.setFieldsValue({
                            sales_tax_name: tax.name,
                            sales_tax_rate: tax.rate,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                )
              }}
            </Form.Item>
            <Form.Item name="sales_tax_name" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="sales_tax_rate" hidden>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={10}>
            <Form.Item name="has_purchase_info" valuePropName="checked">
              <Checkbox>
                <Title level={5}>{t("items.fields.has_purchase_info")}</Title>
              </Checkbox>
            </Form.Item>
            <Form.Item
              label={t("items.fields.purchase_price")}
              shouldUpdate={(prevValues, curValues) =>
                prevValues.has_purchase_info !== curValues.has_purchase_info
              }
            >
              {({ getFieldValue }) => {
                return (
                  <Form.Item name="purchase_price" style={{ margin: 0 }}>
                    <InputNumber
                      addonBefore="NPR"
                      disabled={!getFieldValue("has_purchase_info")}
                      style={{ width: "100%" }}
                      min={0}
                    />
                  </Form.Item>
                )
              }}
            </Form.Item>
            <Form.Item
              label={t("items.fields.purchase_account_id")}
              shouldUpdate={(prevValues, curValues) =>
                prevValues.has_purchase_info !== curValues.has_purchase_info
              }
            >
              {({ getFieldValue }) => {
                return (
                  <Form.Item name="purchase_account_id" style={{ margin: 0 }}>
                    <Select
                      showSearch
                      {...coasSelectProps}
                      disabled={!getFieldValue("has_purchase_info")}
                      onChange={(value) => {
                        const purchaseAccount =
                          coasQueryResult?.data?.data.find(
                            (item) => item.id === value?.toString()
                          )

                        if (purchaseAccount) {
                          formProps?.form?.setFieldsValue({
                            purchase_account_access_name:
                              purchaseAccount.access_name,
                            purchase_account_name: purchaseAccount.name,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                )
              }}
            </Form.Item>
            <Form.Item name="purchase_account_access_name" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="purchase_account_name" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              label={t("items.fields.purchase_description")}
              shouldUpdate={(prevValues, curValues) =>
                prevValues.has_purchase_info !== curValues.has_purchase_info
              }
            >
              {({ getFieldValue }) => (
                <Form.Item name="purchase_description" style={{ margin: 0 }}>
                  <Input.TextArea
                    disabled={!getFieldValue("has_purchase_info")}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              label={t("items.fields.purchase_tax")}
              shouldUpdate={(prevValues, curValues) =>
                prevValues.has_purchase_info !== curValues.has_purchase_info
              }
              rules={[{ required: true }]}
            >
              {({ getFieldValue }) => (
                <Form.Item name="purchase_tax_id" style={{ margin: 0 }}>
                  <Select
                    {...taxSelectProps}
                    allowClear
                    disabled={!getFieldValue("has_purchase_info")}
                    onChange={(value) => {
                      const tax = taxQueryResult?.data?.data.find(
                        (tax) => tax.id === value?.toString()
                      )
                      if (tax) {
                        formProps?.form?.setFieldsValue({
                          purchase_tax_name: tax.name,
                          purchase_tax_rate: tax.rate,
                        })
                      }
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item name="purchase_tax_name" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="purchase_tax_rate" hidden>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  )
}
