import {
  useTranslate,
  IResourceComponentsProps,
  useApiUrl,
} from "@pankod/refine-core"
import {
  Edit,
  Form,
  Input,
  Select,
  useSelect,
  useForm,
  Typography,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Table,
  Button,
  Icons,
  Tooltip,
  Space,
  Upload,
  getValueFromEvent,
} from "@pankod/refine-antd"
import { red, blue } from "@ant-design/colors"
import dayjs from "dayjs"
import "react-mde/lib/styles/css/react-mde-all.css"

import {
  IBill,
  ICategory,
  ICoa,
  IItem,
  IBillItem,
  ITax,
  IVendor,
} from "interfaces"
import { calcDiscount, calcTax } from "utils/calculator"

const { Title, Text } = Typography

export const BillEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const apiUrl = useApiUrl()

  const { formProps, saveButtonProps, queryResult } = useForm<IBill>()

  const { selectProps: vendorsSelectProps } = useSelect<IVendor>({
    resource: "vendors",
    optionLabel: "name",
    optionValue: "id",
  })

  const {
    selectProps: discountCoasSelectProps,
    queryResult: discountCoasQueryResult,
  } = useSelect<ICoa>({
    resource: "coas",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "account_type",
        operator: "eq",
        value: "income",
      },
    ],
  })

  const { selectProps: billAccountSelectProps } = useSelect<ICategory>({
    resource: "coas",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "account_type",
        operator: "eq",
        value: "expenses",
      },
    ],
    fetchSize: 500,
  })

  const { selectProps: itemSelectProps, queryResult: itemQueryResult } =
    useSelect<IItem>({
      resource: "items",
      optionLabel: "name",
      optionValue: "id",
      onSearch: (value) => [
        {
          field: "q",
          operator: "eq",
          value,
        },
      ],
      defaultValue: queryResult?.data?.data?.items?.map((item) => item.item_id),
    })

  const { selectProps: taxSelectProps, queryResult: taxQueryResult } =
    useSelect<ITax>({
      resource: "tax",
      optionLabel: "name",
      optionValue: "id",
    })

  const { selectProps: categoriesSelectProps } = useSelect<ICategory>({
    resource: "categories",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data?.category_id,
    filters: [
      {
        field: "type",
        operator: "eq",
        value: "expense",
      },
    ],
  })

  const selectAfter = (
    <Form.Item name="discount_setting" noStyle>
      <Select style={{ width: 75 }}>
        <Select.Option value="percentage">%</Select.Option>
        <Select.Option value="flat">NRS</Select.Option>
      </Select>
    </Form.Item>
  )

  const handleItemIdChange = (value: string) => {
    const itemData = itemQueryResult?.data?.data.find(
      (item) => item.id === value
    )
    const items = formProps?.form?.getFieldsValue(["items"])

    const updatedItems = items.items.map((item: IBillItem, idx: number) => {
      if (item.item_id === value) {
        const tax_rate = itemData?.purchase_tax_rate ?? 0
        const item_total = itemData?.purchase_price! * 1
        const tax = calcTax(tax_rate, item_total)
        const item_total_inclusive_of_tax = item_total + tax
        return {
          ...item,
          item_id: itemData?.id,
          item_order: idx,
          account_id: itemData?.purchase_account_id,
          rate: itemData?.purchase_price,
          item_unit: itemData?.unit,
          tax_id: itemData?.purchase_tax_id,
          tax_name: itemData?.purchase_tax_name,
          tax_rate,
          tax,
          discount: 0,
          item_total,
          item_total_with_discount: item_total,
          item_total_tax: tax,
          item_total_inclusive_of_tax,
        }
      }
      return item
    })
    const sub_total = updatedItems.reduce(
      (acc: number, item: IBillItem) => acc + item.item_total_with_discount,
      0
    )
    formProps?.form?.setFieldsValue({
      items: updatedItems,
      sub_total,
    })
  }

  const handleItemQuantityChange = (value: string, index: number) => {
    const { items } = formProps?.form?.getFieldsValue(["items"])
    const updatedItems = items.map((item: IBillItem, idx: number) => {
      if (index === idx) {
        const discountSetting =
          formProps?.form?.getFieldValue("discount_setting") ?? "percentage"
        const discount = formProps?.form?.getFieldValue("discount") ?? 0
        const item_total = Number(value) * item.rate
        const tax_rate = item.tax_rate ?? 0
        const discount_value = calcDiscount(
          item_total,
          discountSetting,
          discount
        )
        const item_total_with_discount = item_total - discount_value

        const item_total_tax = calcTax(tax_rate, item_total_with_discount)

        const item_total_inclusive_of_tax =
          item_total_with_discount + item_total_tax

        return {
          ...item,
          item_total,
          discount: discount_value,
          item_total_with_discount,
          item_total_tax,
          item_total_inclusive_of_tax,
        }
      }
      return item
    })
    formProps?.form?.setFieldsValue({
      items: updatedItems,
    })
  }

  const handleItemRateChange = (value: string, index: number) => {
    const { items } = formProps?.form?.getFieldsValue(["items"])
    const updatedItems = items.map((item: IBillItem, idx: number) => {
      if (index === idx) {
        const discountSetting =
          formProps?.form?.getFieldValue("discount_setting") ?? "percentage"
        const discount = formProps?.form?.getFieldValue("discount") ?? 0
        const item_total = Number(value) * item.quantity
        const tax_rate = item.tax_rate ?? 0
        const discount_value = calcDiscount(
          item_total,
          discountSetting,
          discount
        )
        const item_total_with_discount = item_total - discount_value

        const item_total_tax = calcTax(tax_rate, item_total_with_discount)

        const item_total_inclusive_of_tax =
          item_total_with_discount + item_total_tax

        return {
          ...item,
          item_total,
          discount: discount_value,
          item_total_with_discount,
          item_total_tax,
          item_total_inclusive_of_tax,
        }
      }
      return item
    })
    const sub_total = updatedItems.reduce(
      (acc: number, item: IBillItem) => acc + item.item_total_with_discount,
      0
    )
    formProps?.form?.setFieldsValue({
      items: updatedItems,
      sub_total,
    })
  }

  const handleItemTaxIdChange = (value: string, index: number) => {
    const taxData = taxQueryResult?.data?.data.find((tax) => tax.id === value)
    const items = formProps?.form?.getFieldsValue(["items"])
    const updatedItems = items.items.map((item: IBillItem, idx: number) => {
      if (index === idx) {
        if (!value) {
          return {
            ...item,
            tax_id: null,
            tax_rate: 0,
            tax_name: null,
            item_total_tax: 0,
            item_total_inclusive_of_tax: item.item_total_with_discount,
          }
        } else {
          const tax_rate = taxData?.rate ?? 0
          const tax = calcTax(tax_rate, item.item_total_with_discount)
          const item_total_inclusive_of_tax =
            item.item_total_with_discount + tax
          return {
            ...item,
            tax_id: taxData?.id,
            tax_name: taxData?.name,
            tax_rate: taxData?.rate,
            item_total_tax: tax,
            item_total_inclusive_of_tax,
          }
        }
      }
      return item
    })
    const sub_total = updatedItems.reduce(
      (acc: number, item: IBillItem) => acc + item.item_total_with_discount,
      0
    )
    formProps?.form?.setFieldsValue({
      items: updatedItems,
      sub_total,
    })
  }

  const handleDiscountChange = (value: string) => {
    const discount = Number(value) ?? 0
    const discountSetting =
      formProps?.form?.getFieldValue("discount_setting") ?? "percentage"
    const items = formProps?.form?.getFieldValue("items") ?? []

    const updatedItems = items.map((item: IBillItem) => {
      // Tax, Discount, Total, Total Inclusive of Tax, Total Inclusive of Tax and Discount
      const item_total = item.item_total
      const discount_value = calcDiscount(item_total, discountSetting, discount)
      const item_total_with_discount = item_total - discount_value

      const tax_rate = item.tax_rate ?? 0
      const item_total_tax = calcTax(tax_rate, item_total_with_discount)

      const item_total_inclusive_of_tax =
        item_total_with_discount + item_total_tax
      return {
        ...item,
        item_total,
        item_total_tax,
        discount: discount_value,
        item_total_with_discount,
        item_total_inclusive_of_tax,
      }
    })

    const sub_total = updatedItems.reduce(
      (acc: number, item: IBillItem) => acc + item.item_total_with_discount,
      0
    )

    formProps?.form?.setFieldsValue({
      items: updatedItems,
      sub_total,
    })
  }

  const handleDiscountAccountIdChange = (value: string) => {
    const coa = discountCoasQueryResult?.data?.data?.find(
      (coa) => coa.access_name === value.toString()
    )
    if (coa) {
      formProps?.form?.setFieldsValue({
        discount_account_name: coa.name,
      })
    }
  }

  const _taxTotal = (items: IBillItem[]): number => {
    const taxTotal = items.reduce((acc: number, curr: IBillItem) => {
      return acc + (curr?.item_total_tax ?? 0)
    }, 0)
    return taxTotal
  }

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          discount_setting: "percentage",
          ...formProps?.initialValues,
        }}
      >
        <Row gutter={[32, 0]}>
          <Col xs={24} md={12} lg={10}>
            <Form.Item
              label={t("bills.fields.vendor_name")}
              name="vendor_id"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select {...vendorsSelectProps} />
            </Form.Item>

            <Form.Item
              label={t("bills.fields.order_number")}
              name="order_number"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("bills.fields.category_id")}
              name="category_id"
              rules={[{ required: true }]}
            >
              <Select {...categoriesSelectProps} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={10}>
            <Form.Item
              label={t("bills.fields.bill_date")}
              name="bill_date"
              getValueProps={(value) => {
                return {
                  value: value ? dayjs(value) : "",
                }
              }}
              rules={[{ required: true }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label={t("bills.fields.due_date")}
              name="due_date"
              getValueProps={(value) => {
                return {
                  value: value ? dayjs(value) : "",
                }
              }}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Title level={4}>{t("bills.item_list")}</Title>
        <Row>
          <Form.List name="items">
            {(_fields, { add, remove }) => {
              return (
                <div>
                  <Table
                    bordered
                    columns={[
                      {
                        title: t("bills.fields.item_name"),
                        dataIndex: "item_id",
                        key: "item_id",
                        width: "40%",
                        render: (_text, _record, index) => (
                          <>
                            <Space size="small" direction="vertical">
                              <Form.Item
                                name={[index, "item_id"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Item is required.",
                                  },
                                ]}
                                noStyle
                              >
                                <Select
                                  showSearch
                                  allowClear
                                  {...itemSelectProps}
                                  placeholder="Item or service name"
                                  style={{ width: 240 }}
                                  onChange={(value) => {
                                    handleItemIdChange(value?.toString())
                                  }}
                                />
                              </Form.Item>

                              <Form.Item name={[index, "description"]} noStyle>
                                <Input.TextArea placeholder="Description" />
                              </Form.Item>
                            </Space>
                          </>
                        ),
                      },
                      {
                        title: t("bills.fields.sale_account_name"),
                        dataIndex: "account_id",
                        key: "account_id",
                        width: "30%",
                        render: (_text, _record, index) => (
                          <Form.Item
                            name={[index, "account_id"]}
                            rules={[
                              {
                                required: true,
                                message: "Account is required.",
                              },
                            ]}
                            noStyle
                          >
                            <Select
                              showSearch
                              allowClear
                              {...billAccountSelectProps}
                              placeholder="Account name"
                              style={{ width: 180 }}
                            />
                          </Form.Item>
                        ),
                      },
                      {
                        title: t("bills.fields.item_quantity"),
                        dataIndex: "quantity",
                        key: "quantity",
                        width: "20%",
                        render: (_text, _record, index) => (
                          <Form.Item
                            name={[index, "quantity"]}
                            rules={[
                              {
                                required: true,
                                message: "Quantity is required",
                              },
                            ]}
                            noStyle
                          >
                            <InputNumber
                              placeholder="Qty."
                              style={{ width: 80 }}
                              onChange={(value) => {
                                handleItemQuantityChange(
                                  value?.toString(),
                                  index
                                )
                              }}
                            />
                          </Form.Item>
                        ),
                      },
                      {
                        title: t("bills.fields.item_unit_price"),
                        dataIndex: "rate",
                        key: "rate",
                        width: "25%",
                        render: (_text, _record, index) => (
                          <Form.Item
                            name={[index, "rate"]}
                            rules={[
                              {
                                required: true,
                                message: "Unit price is required",
                              },
                            ]}
                            shouldUpdate={(prevValues, currentValues) => {
                              return (
                                prevValues.items[index]?.item_id !==
                                currentValues.items[index]?.item_id
                              )
                            }}
                            noStyle
                          >
                            <InputNumber
                              addonBefore="NRS"
                              placeholder="Unit Price"
                              onChange={(value) => {
                                handleItemRateChange(value?.toString(), index)
                              }}
                              style={{ width: 150 }}
                            />
                          </Form.Item>
                        ),
                      },
                      {
                        title: t("bills.fields.item_tax"),
                        dataIndex: "tax_id",
                        key: "tax_id",
                        width: "40%",
                        render: (_text, _record, index) => (
                          <Form.Item
                            name={[index, "tax_id"]}
                            // rules={[{ required: true, message: "Tax is required." }]}
                            noStyle
                          >
                            <Select
                              showSearch
                              allowClear
                              {...taxSelectProps}
                              placeholder="Tax"
                              style={{ width: 150 }}
                              onChange={(value) => {
                                handleItemTaxIdChange(value?.toString(), index)
                              }}
                            />
                          </Form.Item>
                        ),
                      },
                      {
                        title: t("bills.fields.item_total"),
                        dataIndex: "item_total",
                        key: "item_total",
                        width: "20%",
                      },
                      {
                        title: "",
                        dataIndex: "",
                        key: "action",
                        width: "10%",
                        render: (_text, _record, index) => (
                          <Tooltip
                            title={t("bills.fields.remove_item")}
                            color={red[4]}
                          >
                            <Icons.MinusCircleOutlined
                              style={{ fontSize: 16, color: red[5] }}
                              onClick={() => remove(index)}
                            />
                          </Tooltip>
                        ),
                      },
                    ]}
                    dataSource={formProps?.form?.getFieldValue("items") || []}
                    pagination={false}
                    rowKey="id"
                    size="small"
                  />
                  <Form.Item style={{ marginTop: "0.4rem" }}>
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: "#f7f7f7",
                        color: "#000",
                      }}
                      onClick={() => {
                        add({
                          name: "",
                          quantity: 1,
                          rate: 0,
                          item_total: 0,
                        })
                      }}
                    >
                      <Icons.PlusCircleFilled
                        style={{ color: blue[5], fontSize: 17 }}
                      />{" "}
                      {t("bills.fields.add_item")}
                    </Button>
                  </Form.Item>
                </div>
              )
            }}
          </Form.List>
        </Row>
        <Row gutter={[64, 0]}>
          <Col xs={24} md={2} lg={4}></Col>
          <Col xs={24} md={16} lg={10} className="total-section">
            {/* TOTAL SECTION */}
            {/* SUB TOTAL */}
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.items.length !== curValues.items.length
              }
              noStyle
            >
              {({ getFieldValue }) => {
                const items = getFieldValue("items") || []
                const subTotal: number = items.reduce(
                  (acc: number, curr: IBillItem) => {
                    return acc + curr.item_total
                  },
                  0
                )
                return (
                  <Row>
                    <Col xs={12}>
                      <Title level={5}>Sub total</Title>
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      <Text>NRS. {subTotal.toFixed(2)}</Text>
                    </Col>
                  </Row>
                )
              }}
            </Form.Item>
            {/* DISCOUNT */}
            <Row>
              <Col xs={8}>
                <Title level={5}>{t("bills.fields.discount")}</Title>
              </Col>
              <Col xs={8}>
                <Form.Item
                  name="discount"
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      max: 100,
                      message: "Discount must be between 0 and 100",
                    },
                    { required: true },
                  ]}
                  // noStyle
                  style={{ width: "200px" }}
                >
                  <InputNumber
                    addonAfter={selectAfter}
                    onChange={(value) => {
                      handleDiscountChange(value?.toString())
                    }}
                    placeholder="Discount"
                  />
                </Form.Item>
                <Form.Item name="discount_amount" hidden>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={8} style={{ textAlign: "right" }}>
                <Form.Item
                  shouldUpdate={(prevValues, currValues) =>
                    prevValues?.discount !== currValues?.discount
                  }
                  noStyle
                >
                  {({ getFieldValue }) => {
                    const items = getFieldValue("items") || []
                    const discount_amount = items.reduce(
                      (acc: number, item: IBillItem) =>
                        acc + (item.discount ?? 0),
                      0
                    )
                    return (
                      <Text>
                        {discount_amount ? "-" : ""} NRS.{" "}
                        {discount_amount ?? 0.0}
                      </Text>
                    )
                  }}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              shouldUpdate={(prevValues, curValues) => {
                return prevValues.discount !== curValues.discount
              }}
              noStyle
            >
              {({ getFieldValue }) => {
                const discount = getFieldValue("discount") || 0
                return discount ? (
                  <Form.Item
                    name="discount_account_id"
                    label={t("bills.fields.discount_account_name")}
                    rules={[
                      {
                        required: true,
                        message: "Discount account is required.",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      {...discountCoasSelectProps}
                      onChange={(value) => {
                        handleDiscountAccountIdChange(value?.toString())
                      }}
                    />
                  </Form.Item>
                ) : null
              }}
            </Form.Item>
            <Form.Item
              shouldUpdate={(prevValues, currValues) =>
                prevValues?.discount_account_id !==
                currValues?.discount_account_id
              }
              name="discount_account_name"
              hidden
            >
              <Input />
            </Form.Item>
            {/* Adjustment */}
            <Row>
              <Col xs={8}>
                <Title level={5}>{t("bills.fields.adjustment")}</Title>
              </Col>
              <Col xs={8}>
                <Form.Item
                  name="adjustment"
                  // label={t("bills.fields.discount")}
                  noStyle
                  style={{ width: "200px" }}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Adjustment"
                  />
                </Form.Item>
              </Col>
              <Col xs={8} style={{ textAlign: "right" }}>
                <Form.Item
                  shouldUpdate={(prevValues, currValues) =>
                    prevValues?.adjustment !== currValues?.adjustment
                  }
                  noStyle
                >
                  {({ getFieldValue }) => {
                    const adjustment = getFieldValue("adjustment") || 0
                    return <Text>NRS. {adjustment?.toFixed(2)}</Text>
                  }}
                </Form.Item>
              </Col>
            </Row>
            {/* TAX */}
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.items !== curValues.items.length
              }
              noStyle
            >
              {({ getFieldValue }) => {
                const items = getFieldValue("items") || []
                const taxTotal: number = _taxTotal(items)

                return (
                  <Row>
                    <Col xs={12}>
                      <Title level={5}>Tax Total:</Title>
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      <Title level={5}>NRS. {taxTotal.toFixed(2)}</Title>
                    </Col>
                  </Row>
                )
              }}
            </Form.Item>
            {/* TOTAL */}
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.items.length !== curValues.items.length ||
                prevValues.adjustment !== curValues.adjustment
              }
              noStyle
            >
              {({ getFieldValue }) => {
                const items = getFieldValue("items") || []
                let total = items.reduce((acc: number, curr: IBillItem) => {
                  return acc + (curr.item_total_inclusive_of_tax ?? 0)
                }, 0)
                const adjustment = getFieldValue("adjustment") || 0
                total = total + adjustment
                return (
                  <Row>
                    <Col xs={12}>
                      <Title level={4}>Total:</Title>
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      <Form.Item name="sub_total_inclusive_of_tax" noStyle>
                        <Title level={4}>NRS. {total.toFixed(2)}</Title>
                      </Form.Item>
                    </Col>
                  </Row>
                )
              }}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Attachments">
          <Form.Item
            name="files"
            valuePropName="attachments"
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/files/upload`}
              listType="picture"
              maxCount={5}
              multiple
              withCredentials
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Text>{t("bills.fields.note")}</Text>
        <Form.Item name="note" labelAlign="left">
          <Input.TextArea placeholder="Note here." />
        </Form.Item>
      </Form>
    </Edit>
  )
}
