import {
  Checkbox,
  Col,
  Create,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  useForm,
} from "@pankod/refine-antd"
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core"
import { IAccount } from "interfaces"

const AccountCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IAccount>()
  const t = useTranslate()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          opening_balance: 0.0,
        }}
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        labelAlign="left"
      >
        <Row>
          <Col xs={24} md={16} lg={14} xl={12}>
            <Row gutter={[16, 0]}>
              <Col xs={24}>
                <Form.Item
                  name="type"
                  label={t("accounts.fields.type")}
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Radio value="bank">Bank</Radio>
                    <Radio value="cash">Cash</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="name"
                  label={t("accounts.fields.name")}
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Enter Account Name" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="account_number"
                  label={t("accounts.fields.account_number")}
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Enter Account Number"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="currency"
                  label={t("accounts.fields.currency")}
                  rules={[{ required: true }]}
                >
                  <Select
                    options={[
                      { label: "Nepali Rupees", value: "NPR" },
                      { label: "American Dollar", value: "USD" },
                    ]}
                    placeholder="Select Currency"
                  ></Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="opening_balance"
                  label={t("accounts.fields.opening_balance")}
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    prefix="NPR"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="is_default_account"
                  label={t("accounts.fields.is_default_account")}
                  valuePropName="checked"
                >
                  <Checkbox>Default</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="bank_name"
                  label={t("accounts.fields.bank_name")}
                >
                  <Input placeholder="Enter Bank Name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="bank_phone"
                  label={t("accounts.fields.bank_phone")}
                >
                  <Input placeholder="Enter Bank Phone" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="bank_address"
                  label={t("accounts.fields.bank_address")}
                >
                  <Input.TextArea placeholder="Enter Bank Address" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Create>
  )
}

export { AccountCreate }
