import { DualAxes, DualAxesConfig, G2 } from "@ant-design/plots"
import { Typography } from "@pankod/refine-antd"
import { useOne } from "@pankod/refine-core"
import { green, red, blue } from "@ant-design/colors"

const { Title } = Typography

export const CashFlow: React.FC = () => {
  const { data } = useOne<any>({
    resource: "dashboard/cashflow",
    id: "",
  })

  const { registerTheme } = G2
  registerTheme("custom-theme", {
    colors10: [green[3], red[4], blue[3]],

    colors20: [green[3], red[4], blue[3]],
  })

  const config: DualAxesConfig = {
    data: [data?.data?.data ?? [], data?.data?.net ?? []],
    xField: "month",
    yField: ["value", "net"],
    legend: {
      itemName: {
        formatter: (text: string) => {
          return text.charAt(0).toUpperCase() + text.slice(1)
        },
      },
    },
    geometryOptions: [
      {
        geometry: "column",
        isStack: true,
        seriesField: "type",
      },
      {
        geometry: "line",
      },
    ],
    autoFit: true,
    height: 280,
    theme: "custom-theme",
  }

  return (
    <div style={{ padding: "2em" }}>
      <Title level={5}>Cash Flow</Title>
      <DualAxes {...config} />
    </div>
  )
}
