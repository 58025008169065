import { Button, Drawer, Icons } from "@pankod/refine-antd"
import { IInvoice } from "interfaces"
import { SaleReturnCreate } from "pages/sales/sale-return"
import { useState } from "react"

interface ISaleReturnButtonProps {
  record: IInvoice | undefined
}

const SaleReturnButton: React.FC<ISaleReturnButtonProps> = ({ record }) => {
  // const { modalProps, show, close } = useModal()
  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  if (!record) return null

  return (
    <>
      <Button
        icon={<Icons.RollbackOutlined style={{ fontSize: 18 }} />}
        onClick={showDrawer}
      >
        Return
      </Button>
      <Drawer
        title="Sale Return"
        visible={open}
        onClose={onClose}
        width={"70vw"}
      >
        <SaleReturnCreate
          initialValues={{
            ...record,
            category_id: "",
            invoice_date: undefined,
          }}
          breadcrumb={false}
          title="Create Sale Return"
          // title={false}
          // goBack={false}
        />
      </Drawer>
    </>
  )
}

export default SaleReturnButton
