import {
  Button,
  Col,
  Form,
  Row,
  Select,
  useTable,
  Typography,
  Icons,
  Table,
  Avatar,
} from "@pankod/refine-antd"
import {
  CrudFilters,
  getDefaultFilter,
  HttpError,
  useApiUrl,
  useGetIdentity,
  useNavigation,
} from "@pankod/refine-core"
import { green } from "@ant-design/colors"

import React from "react"
import { humanize } from "utils/humanize"
import { formatNumber } from "utils/format-number"

const { Text } = Typography
const { CalendarOutlined } = Icons

const KEYS = ["sales", "purchases", "sales_returns", "purchase_returns"]

export const TaxSummaryReport: React.FC = () => {
  const { goBack } = useNavigation()

  const { data: identity } = useGetIdentity()

  const organization = identity?.organization
  const currency = identity?.default?.currency ?? "NPR"

  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<
    any,
    HttpError,
    { date: string }
  >({
    resource: "tax/report",
    onSearch: ({ date }) => {
      const ledgerEntriesFilters: CrudFilters = []

      ledgerEntriesFilters.push({
        field: "date",
        operator: "eq",
        value: date ? date : undefined,
      })

      return ledgerEntriesFilters
    },
    initialFilter: [
      {
        field: "date",
        operator: "eq",
        value: "today",
      },
    ],
    syncWithLocation: true,
    hasPagination: false,
  })

  const apiUrl = useApiUrl()

  // @ts-ignore
  const meta = tableQueryResult?.data?.meta

  return (
    <div style={{ margin: "1em 2em" }}>
      <Form
        {...searchFormProps}
        onValuesChange={() => {
          searchFormProps.form?.submit()
        }}
        initialValues={{
          date: getDefaultFilter("date", filters, "eq"),
        }}
      >
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item name="date" noStyle>
              <Select
                options={[
                  { value: "today", label: "Today" },
                  { value: "yesterday", label: "Yesterday" },
                  { value: "this-week", label: "This Week" },
                  { value: "this-month", label: "This Month" },
                  { value: "last-month", label: "Last Month" },
                  { value: "this-year", label: "This Year" },
                ]}
                style={{ width: "50%" }}
                defaultValue="today"
                suffixIcon={<CalendarOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ display: "flex", justifyContent: "right" }}>
            {/* <ExportButton onClick={triggerExport} loading={isLoading} /> */}
            <Button
              href={`${apiUrl}/tax/pdf?date=${filters?.[0]?.value ?? "today"}`}
              style={{ marginLeft: "1em" }}
              icon={<Icons.PrinterOutlined style={{ fontSize: 18 }} />}
            >
              Print
            </Button>
            <Button onClick={goBack} style={{ marginLeft: "1em" }} type="text">
              <Icons.CloseOutlined />
            </Button>
          </Col>
        </Row>
      </Form>
      <br />
      <section style={{ textAlign: "center", marginBottom: "2em" }}>
        <Text style={{ fontSize: "1.5em", color: "#000", fontWeight: "300" }}>
          {organization?.name}
        </Text>
        <br />
        <Text style={{ fontSize: "1.5em", color: "#000", fontWeight: "350" }}>
          Tax Summary
        </Text>
        <br />
        <Text>
          From {meta?.from_bs} To {meta?.to_bs}
        </Text>
      </section>

      <Row justify="center">
        <Col xs={24} md={22} lg={20}>
          {/* <pre>{JSON.stringify(tableProps, null, 2)}</pre> */}

          {KEYS.map((key) => {
            return (
              <>
                <section
                  style={{
                    textAlign: "center",
                    marginBottom: "1em",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "1em",
                      color: "#000",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    VAT on {humanize(key)}
                  </Text>
                </section>
                <Table
                  size="small"
                  {...tableProps}
                  dataSource={tableProps?.dataSource?.[0]?.[key]?.data ?? []}
                  columns={[
                    {
                      title: "Date",
                      key: "date_bs",
                      dataIndex: "date_bs",
                      // render: (date: string) => <Text>{date}</Text>,
                    },
                    {
                      title: humanize(key) + " Number",
                      key: "id_number",
                      dataIndex: "id_number",
                      render: (id_number: string) => (
                        <Text>
                          {id_number === "Total" ? (
                            <strong>{id_number}</strong>
                          ) : (
                            id_number
                          )}
                        </Text>
                      ),
                    },
                    {
                      title:
                        key === ("sales" || "sales_return")
                          ? "Customer"
                          : "Supplier",
                      key: "user",
                      dataIndex: "user",
                      render: (user: any) => <Text>{user?.name}</Text>,
                    },
                    {
                      title: "VAT Number",
                      key: "user",
                      dataIndex: "user",
                      render: (user: any) => <Text>{user?.tax_number}</Text>,
                    },
                    {
                      title: "Exempt Amount",
                      key: "exempt_amount",
                      dataIndex: "exempt_amount",
                      render: (exempt_amount: number) =>
                        formatNumber(exempt_amount),
                    },
                    {
                      title: "Taxable Amount",
                      key: "taxable_amount",
                      dataIndex: "taxable_amount",
                      render: (taxable_amount: number) =>
                        formatNumber(taxable_amount),
                    },
                    {
                      title: "VAT Amount",
                      key: "tax_amount",
                      dataIndex: "tax_amount",
                      render: (tax_amount: number) => formatNumber(tax_amount),
                    },
                    {
                      title: "Shipping Amount",
                      key: "shipping_charge",
                      dataIndex: "shipping_charge",
                      render: (shipping_charge: number) =>
                        formatNumber(shipping_charge),
                    },
                    {
                      title: "Adjustment",
                      key: "adjustment",
                      dataIndex: "adjustment",
                      render: (adjustment: number) => formatNumber(adjustment),
                    },
                    {
                      title: "Total Amount",
                      key: "total_amount",
                      dataIndex: "total_amount",
                      render: (total_amount: number) =>
                        formatNumber(total_amount),
                    },
                  ]}
                  pagination={false}
                />
                <br />
              </>
            )
          })}
        </Col>
      </Row>

      <br />
      <Text>
        ***Amount is displayed in your base currency{" "}
        <Avatar
          shape="square"
          size="small"
          style={{ backgroundColor: green[5] }}
        >
          {currency}
        </Avatar>
      </Text>
      <br />
    </div>
  )
}
