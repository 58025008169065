import { Col, Image, Layout, Row, Typography, Card, Avatar, Space } from "antd";
import {
  EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined,
} from "@ant-design/icons";

const { Meta } = Card;
const { Footer: AntdFooter } = Layout;
const { Title, Text } = Typography;

export default function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer
      className="footer"
      style={{
        paddingInline: "2rem",
        paddingTop: "4rem",
        backgroundColor: "#313642",
      }}
    >
      <div style={{ backgroundColor: "#313642", padding: "0" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "2rem",
            flexWrap: "wrap",
            maxWidth: "1280px",
            marginInline: "auto",
          }}
        >
          <div className="footerLeft">
            <div>
              <Image src="./refine.svg" preview={false} height={40} />
            </div>
            <br />
            <Title level={5} style={{ color: "#fff", opacity: 0.8 }}>
              Powered By,
            </Title>
            <Text style={{ color: "#fff", opacity: 0.7 }}>
              Evolving Technology was founded in 2013 with an aim of
              establishing the company as one of the premier technical service
              providers in the country.
            </Text>
          </div>
          <div className="footerRight">
            <div>
              <Title level={4} style={{ color: "#fff", opacity: 0.9 }}>
                Contact
              </Title>
              <Space direction="vertical" size="middle">
                <Meta
                  avatar={
                    <Avatar
                      shape="square"
                      size="small"
                      icon={<EnvironmentOutlined />}
                    />
                  }
                  // title={<Text>Jhamsikhel Lalitpur Nepal</Text>}
                  description={
                    <Text style={{ color: "#fff", opacity: 0.7 }}>
                      Jhamsikhel Lalitpur Nepal
                    </Text>
                  }
                />

                <a href="tel:9851053926" className="footer-link">
                  <Meta
                    avatar={
                      <Avatar
                        shape="square"
                        size="small"
                        icon={<PhoneOutlined />}
                      />
                    }
                    // title={<Text>9851053926</Text>}
                    description={
                      <Text style={{ color: "#fff", opacity: 0.7 }}>
                        9851053926
                      </Text>
                    }
                  />
                </a>
                <a href="mailto:byaparkhata@gmail.com" className="footer-link">
                  <Meta
                    avatar={
                      <Avatar
                        shape="square"
                        size="small"
                        icon={<MailOutlined />}
                      />
                    }
                    // title={<Text>9851053926</Text>}
                    description={
                      <Text style={{ color: "#fff", opacity: 0.7 }}>
                        byaparkhata@gmail.com
                      </Text>
                    }
                  />
                </a>
              </Space>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center", paddingBlock: "1.5rem" }}>
          <Text style={{ color: "#fff", opacity: 0.7 }}>
            Copyright © {year} . All Rights Reserved
          </Text>
        </div>
      </div>
    </footer>
  );
}
