import { IOrganization, IRole, IUser } from "interfaces"

import {
  Col,
  Create,
  Form,
  Input,
  Row,
  Select,
  Typography,
  useForm,
  useSelect,
} from "@pankod/refine-antd"
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core"

const { Title } = Typography

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<IUser>({
    successNotification: () => ({
      message: "User created successfully",
      description: "Successful",
      type: "success",
    }),
  })

  const { selectProps: roleSelectProps } = useSelect<IRole>({
    resource: "admin/roles",
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
    defaultValue: formProps.initialValues?.role_ids,
  })

  const { selectProps: organizationSelectProps } = useSelect<IOrganization>({
    resource: "organizations",
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
    defaultValue: formProps?.initialValues?.organization_id,
  })

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        // labelCol={{ span: 6 }}
        // wrapperCol={{ span: 28 }}
        // labelAlign="left"
        layout="vertical"
      >
        <Row>
          <Col xs={24} md={18} lg={16}>
            <Title level={5}>Personal Information</Title>
            <Row gutter={[32, 0]}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("users.fields.name")}
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="User's Name" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("users.fields.phone")}
                  name="phone"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="User's Phone" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("users.fields.email")}
                  name="email"
                  rules={[{ type: "email" }, { required: true }]}
                >
                  <Input placeholder="User's Email" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Password"
                  name="new_password"
                  rules={[{ required: true }]}
                >
                  <Input.Password placeholder="User's Password" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Confirm Password"
                  name="password"
                  dependencies={["new_password"]}
                  rules={[
                    { required: true },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("new_password") === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        )
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="User's Password" />
                </Form.Item>
              </Col>
            </Row>
            <Title level={5}>Role and Organization</Title>
            <Row gutter={[32, 0]}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("users.fields.organization")}
                  name="organization_id"
                  rules={[{ required: true }]}
                >
                  <Select {...organizationSelectProps} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="role_ids" label={t("users.fields.roles")}>
                  <Select
                    mode="multiple"
                    {...roleSelectProps}
                    placeholder={t("users.fields.roles")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Create>
  )
}
