import { Typography } from "@pankod/refine-antd"
import { Avatar, List } from "antd"
import { IAttachment } from "interfaces"

const { Title, Text } = Typography

interface PropType {
  attachments?: IAttachment[]
}

export const Attachments: React.FC<PropType> = ({ attachments }) => {
  if (!attachments) return null
  return (
    <div>
      <Title level={5}>Attachments</Title>
      <List>
        {attachments.map((attachment) => (
          <a
            key={attachment.id}
            href={attachment.url}
            rel="noreferrer"
            target="_blank"
          >
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={attachment.url} shape="square" />}
                title={attachment.name}
                // description={attachment.url}
              />
            </List.Item>
          </a>
        ))}
      </List>
      {attachments.length === 0 && <Text>No attachments</Text>}
    </div>
  )
}
