import {
  useTranslate,
  IResourceComponentsProps,
  useNavigation,
  CrudFilters,
  HttpError,
} from "@pankod/refine-core"
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  ShowButton,
  NumberField,
  DateField,
  TagField,
  Row,
  Col,
  Card,
  Form,
  Typography,
  DatePicker,
  Input,
  useSelect,
  Select,
  Button,
} from "@pankod/refine-antd"
import {
  ICategory,
  ICustomer,
  ISaleReturn,
  ISaleReturnFilterVariables,
} from "interfaces"
import { blue, green, grey, red, orange, volcano } from "@ant-design/colors"
import { humanize } from "utils/humanize"
import { dateStringify } from "utils/date"
import { makeFilterable } from "utils/filter"

const { Title } = Typography

const color: { [key: string]: string } = {
  draft: grey[5],
  open: orange[5],
  pending: blue[5],
  paid: green[5],
  partially_paid: volcano[5],
  cancelled: red[5],
}

export const SaleReturnList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { show } = useNavigation()

  const { tableProps, sorter, searchFormProps } = useTable<
    ISaleReturn,
    HttpError,
    ISaleReturnFilterVariables
  >({
    initialSorter: [
      {
        field: "updated_at",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = []
      const {
        sale_return_number,
        invoice_number,
        customer_id,
        category_id,
        status,
        sale_return_date,
        invoice_date,
        due_date,
      } = params

      filters.push(
        {
          field: "sale_return_date",
          operator: "eq",
          value: sale_return_date ? dateStringify(sale_return_date) : undefined,
        },
        {
          field: "sale_return_number",
          operator: "eq",
          value: makeFilterable(sale_return_number),
        },
        {
          field: "invoice_date",
          operator: "eq",
          value: invoice_date ? dateStringify(invoice_date) : undefined,
        },
        {
          field: "due_date",
          operator: "eq",
          value: due_date ? dateStringify(due_date) : undefined,
        },
        {
          field: "invoice_number",
          operator: "eq",
          value: makeFilterable(invoice_number),
        },
        {
          field: "customer_id",
          operator: "eq",
          value: customer_id,
        },
        {
          field: "category_id",
          operator: "eq",
          value: category_id,
        },
        {
          field: "status",
          operator: "eq",
          value: status,
        }
      )

      return filters
    },
  })

  const { selectProps: customerSelectProps } = useSelect<ICustomer>({
    resource: "customers",
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
  })

  const { selectProps: categoriesSelectProps } = useSelect<ICategory>({
    resource: "categories",
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
  })

  return (
    <List>
      <Row gutter={[12, 0]}>
        <Col span={5}>
          <Card size="small">
            <Title level={5}>Filters</Title>

            <Form {...searchFormProps}>
              <Form.Item name="sale_return_date">
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Sale Return Date"
                />
              </Form.Item>
              <Form.Item name="invoice_date">
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Invoice Date"
                />
              </Form.Item>
              <Form.Item name="due_date">
                <DatePicker style={{ width: "100%" }} placeholder="Due Date" />
              </Form.Item>
              <Form.Item name="sale_return_number">
                <Input placeholder="Sale return Number" />
              </Form.Item>
              <Form.Item name="invoice_number">
                <Input placeholder="Invoice Number" />
              </Form.Item>
              <Form.Item name="customer_id">
                <Select
                  allowClear
                  {...customerSelectProps}
                  placeholder="Customer"
                />
              </Form.Item>
              <Form.Item name="category_id">
                <Select
                  allowClear
                  {...categoriesSelectProps}
                  placeholder="Category"
                />
              </Form.Item>
              <Form.Item name="status">
                <Select
                  allowClear
                  placeholder="Status"
                  options={[
                    { label: "Draft", value: "draft" },
                    { label: "Open", value: "open" },
                    { label: "Pending", value: "pending" },
                    { label: "Paid", value: "paid" },
                    { label: "Partially Paid", value: "partially_paid" },
                    { label: "Cancelled", value: "cancelled" },
                  ]}
                />
              </Form.Item>

              <Button
                htmlType="submit"
                type="primary"
                style={{ width: "100%" }}
              >
                Search
              </Button>
            </Form>
          </Card>
        </Col>
        <Col span={19}>
          <Table {...tableProps} size="small" rowKey="id">
            <Table.Column
              dataIndex="sale_return_date"
              key="sale_return_date"
              title={t("sale_returns.fields.sale_return_date")}
              render={(value: string) => (
                <DateField value={value} format="DD MMM YYYY" />
              )}
              defaultSortOrder={getDefaultSortOrder("sale_return_date", sorter)}
              sorter
            />

            <Table.Column<ISaleReturn>
              dataIndex="sale_return_number"
              key="sale_return_number"
              title={t("sale_returns.fields.sale_return_number")}
              render={(value: string, record) => (
                <TextField
                  value={value}
                  onClick={() => show("sale-returns", record.id)}
                  style={{ color: green[4], cursor: "pointer" }}
                />
              )}
              defaultSortOrder={getDefaultSortOrder(
                "sale_return_number",
                sorter
              )}
              sorter
            />
            <Table.Column<ISaleReturn>
              dataIndex="invoice_number"
              key="invoice_number"
              title={t("sale_returns.fields.invoice_number")}
              render={(value: string, record) => (
                <TextField
                  value={value}
                  onClick={() => show("invoices", record?.invoice?.id ?? "")}
                  style={{ cursor: "pointer" }}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("invoice_number", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="reference"
              key="reference"
              title={t("sale_returns.fields.reference")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("reference", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="customer_name"
              key="customer_name"
              title={t("sale_returns.fields.customer_name")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("customer_name", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="status"
              key="status"
              title={t("sale_returns.fields.status.title")}
              render={(value: string) => (
                <TagField
                  value={humanize(value)}
                  color={value ? color[value] : "blue"}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("status", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="due_date"
              key="due_date"
              title={t("sale_returns.fields.due_date")}
              render={(value) => (
                <DateField value={value} format="DD MMM YYYY" />
              )}
              defaultSortOrder={getDefaultSortOrder("due_date", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="sub_total_inclusive_of_tax"
              key="sub_total_inclusive_of_tax"
              title={t("sale_returns.fields.sub_total_inclusive_of_tax")}
              render={(value) => (
                <NumberField
                  value={value}
                  options={{
                    style: "currency",
                    currency: "NPR",
                  }}
                />
              )}
              defaultSortOrder={getDefaultSortOrder(
                "sub_total_inclusive_of_tax",
                sorter
              )}
              sorter
            />
            <Table.Column<ISaleReturn>
              title={t("table.actions")}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  {/* <EditButton hideText size="small" recordItemId={record.id} /> */}
                  <ShowButton hideText size="small" recordItemId={record.id} />
                  {/* <DeleteButton hideText size="small" recordItemId={record.id} /> */}
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
    </List>
  )
}
