import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core"
import {
  Checkbox,
  Col,
  Edit,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  useForm,
} from "@pankod/refine-antd"

import { ICategory } from "interfaces"

export const CategoryEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<ICategory>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row>
          <Col xs={24} sm={16} md={12}>
            <Form.Item
              label={t("categories.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Enter categories name" />
            </Form.Item>
            <Form.Item
              label={t("categories.fields.type")}
              name="type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={[
                  { label: "Income", value: "income" },
                  { label: "Expense", value: "expense" },
                  { label: "Item", value: "item" },
                  { label: "Other", value: "other" },
                ]}
                placeholder="Enter categories type"
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item
              label={t("categories.fields.description")}
              name="description"
            >
              <Input.TextArea placeholder="Enter categories description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  )
}
