import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  Checkbox,
  Row,
  Col,
} from "@pankod/refine-antd"

import "react-mde/lib/styles/css/react-mde-all.css"

import { IAccountType, ICoa } from "../../interfaces"
import {
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useTranslate,
} from "@pankod/refine-core"
import { humanize } from "utils/humanize"

export const CoaCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const apiUrl = useApiUrl()

  const { formProps, saveButtonProps } = useForm<ICoa>()

  const { data: accountTypes } = useCustom<IAccountType[]>({
    url: `${apiUrl}/account-types`,
    method: "get",
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  })

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        initialValues={{}}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        labelAlign="left"
      >
        <Row>
          <Col xs={24} md={12} lg={10}>
            <Form.Item
              label={t("coas.fields.account_type")}
              name="account_type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select showSearch placeholder="Select Account Type">
                {accountTypes?.data?.map((accountType) => (
                  <>
                    <Select.OptGroup label={humanize(accountType.group_name)}>
                      {accountType.account_types.map((accType) => (
                        <Select.Option value={accType.id}>
                          {accType.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("coas.fields.name")}
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Account Name" />
            </Form.Item>
            <Form.Item label={t("coas.fields.description")} name="description">
              <Input.TextArea placeholder="Description" />
            </Form.Item>
            <Form.Item
              label={t("coas.fields.is_enabled")}
              name="is_enabled"
              initialValue={true}
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  )
}
