import {
  Card,
  Col,
  DateField,
  NumberField,
  Row,
  Show,
  Statistic,
  Table,
  Typography,
} from "@pankod/refine-antd"
import {
  IResourceComponentsProps,
  useGetIdentity,
  useShow,
  useTranslate,
} from "@pankod/refine-core"

import { ITransaction } from "interfaces"
import { humanize } from "utils/humanize"

import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons"

const { Title, Text } = Typography
export const TransactionShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { queryResult } = useShow<ITransaction>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const { data: identity } = useGetIdentity()

  const currency = identity?.default?.currency ?? "NPR"

  return (
    <Show
      title={`Receipt - ${record?.transaction_number}`}
      isLoading={isLoading}
      canDelete={false}
    >
      <Row>
        <Col span={14}>
          <Row gutter={16}>
            <Col span={12}>
              <Card>
                <Statistic
                  title={
                    <>
                      {record?.transaction_type}
                      {" - "}
                      {humanize(record?.payment_method)}
                    </>
                  }
                  value={record?.amount}
                  precision={2}
                  valueStyle={
                    record?.transaction_type === "Income"
                      ? { color: "#3f8600" }
                      : { color: "#cf1322" }
                  }
                  prefix={
                    record?.transaction_type === "Income" ? (
                      <>
                        {" "}
                        <ArrowUpOutlined /> {currency}
                      </>
                    ) : (
                      <>
                        {" "}
                        <ArrowDownOutlined /> {currency}
                      </>
                    )
                  }
                  suffix=""
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <Statistic
                  title="Category"
                  value={record?.category?.name}
                  precision={2}
                  valueStyle={
                    record?.transaction_type === "Income"
                      ? { color: "#3f8600" }
                      : { color: "#cf1322" }
                  }
                  prefix=""
                  suffix=""
                />
              </Card>
            </Col>
          </Row>
          <br />
          <Title level={5}>Details</Title>

          {/* Payroll */}
          {record?.payrolls?.length ? (
            <Table
              dataSource={record?.payrolls}
              columns={[
                {
                  title: t("payrolls.fields.payroll_number"),
                  dataIndex: "payroll_number",
                  key: "payroll_number",
                  render: (_text, record, index) => (
                    <>
                      <Text>{record?.payroll_number}</Text>
                    </>
                  ),
                },
                {
                  title: t("payrolls.fields.payment_date"),
                  dataIndex: "payment_date",
                  key: "payment_date",
                  render: (value, record, index) => (
                    <>
                      <DateField value={value} />
                    </>
                  ),
                },
                {
                  title: t("payrolls.fields.total_payable"),
                  dataIndex: "total_payable",
                  key: "total_payable",
                  render: (value, record, index) => (
                    <>
                      <NumberField
                        value={value}
                        options={{
                          style: "currency",
                          currency: currency,
                        }}
                      />
                    </>
                  ),
                },
              ]}
              pagination={false}
              size="small"
            />
          ) : null}

          {/* Purchase Return */}
          {record?.purchase_returns?.length ? (
            <Table
              dataSource={record?.purchase_returns}
              columns={[
                {
                  title: t("purchase_returns.fields.purchase_return_number"),
                  dataIndex: "purchase_return_number",
                  key: "purchase_return_number",
                  render: (_text, record, index) => (
                    <>
                      <Text>{record?.purchase_return_number}</Text>
                    </>
                  ),
                },
                {
                  title: t("purchase_returns.fields.purchase_return_date"),
                  dataIndex: "purchase_return_date",
                  key: "purchase_return_date",
                  render: (value, record, index) => (
                    <>
                      <DateField value={value} />
                    </>
                  ),
                },
                {
                  title: t("purchase_returns.fields.total"),
                  dataIndex: "total",
                  key: "total",
                  render: (value, record, index) => (
                    <>
                      <NumberField
                        value={value}
                        options={{
                          style: "currency",
                          currency: currency,
                        }}
                      />
                    </>
                  ),
                },
              ]}
              pagination={false}
              size="small"
            />
          ) : null}

          {/* Sale Return */}
          {record?.sale_returns?.length ? (
            <Table
              dataSource={record?.sale_returns}
              columns={[
                {
                  title: t("sale_returns.fields.sale_return_number"),
                  dataIndex: "sale_return_number",
                  key: "sale_return_number",
                  render: (_text, record, index) => (
                    <>
                      <Text>{record?.sale_return_number}</Text>
                    </>
                  ),
                },
                {
                  title: t("sale_returns.fields.sale_return_date"),
                  dataIndex: "sale_return_date",
                  key: "sale_return_date",
                  render: (value, record, index) => (
                    <>
                      <DateField value={value} />
                    </>
                  ),
                },
                {
                  title: t("sale_returns.fields.total"),
                  dataIndex: "total",
                  key: "total",
                  render: (value, record, index) => (
                    <>
                      <NumberField
                        value={value}
                        options={{
                          style: "currency",
                          currency: currency,
                        }}
                      />
                    </>
                  ),
                },
              ]}
              pagination={false}
              size="small"
            />
          ) : null}

          {/* Invoices */}
          {record?.invoices?.length ? (
            <Table
              dataSource={record?.invoices}
              columns={[
                {
                  title: t("invoices.fields.invoice_number"),
                  dataIndex: "invoice_number",
                  key: "invoice_number",
                  render: (_text, record, index) => (
                    <>
                      <Text>{record?.invoice_number}</Text>
                    </>
                  ),
                },
                {
                  title: t("invoices.fields.invoice_date"),
                  dataIndex: "invoice_date",
                  key: "invoice_date",
                  render: (value, record, index) => (
                    <>
                      <DateField value={value} />
                    </>
                  ),
                },
                {
                  title: t("invoices.fields.total"),
                  dataIndex: "total",
                  key: "total",
                  render: (value, record, index) => (
                    <>
                      <NumberField
                        value={value}
                        options={{
                          style: "currency",
                          currency: currency,
                        }}
                      />
                    </>
                  ),
                },
              ]}
              pagination={false}
              size="small"
            />
          ) : null}

          {/* Bills  */}
          {record?.bills?.length ? (
            <Table
              dataSource={record?.bills}
              columns={[
                {
                  title: t("bills.fields.bill_number"),
                  dataIndex: "bill_number",
                  key: "bill_number",
                  render: (_text, record, index) => (
                    <>
                      <Text>{record?.bill_number}</Text>
                    </>
                  ),
                },
                {
                  title: t("bills.fields.bill_date"),
                  dataIndex: "bill_date",
                  key: "bill_date",
                  render: (value, record, index) => (
                    <>
                      <DateField value={value} />
                    </>
                  ),
                },
                {
                  title: t("bills.fields.total"),
                  dataIndex: "total",
                  key: "total",
                  render: (value, record, index) => (
                    <>
                      <NumberField
                        value={value}
                        options={{
                          style: "currency",
                          currency: currency,
                        }}
                      />
                    </>
                  ),
                },
              ]}
              pagination={false}
              size="small"
            />
          ) : null}
          {/* <pre>{JSON.stringify(record, null, 2)}</pre> */}
        </Col>
      </Row>
    </Show>
  )
}
