import mqtt from "mqtt"

export const mqttConfig = {
  url: process.env.REACT_APP_MQTT_URL!,
  options: {
    port: parseInt(process.env.REACT_APP_MQTT_PORT!),
    username: process.env.REACT_APP_MQTT_USERNAME!,
    password: process.env.REACT_APP_MQTT_PASSWORD!,
  } as mqtt.IClientOptions,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  TOPIC: "attendance",
  ENROLL_PAYLOAD: "ENROLL",
  REGISTER_PAYLOAD: "REGISTER",
}
