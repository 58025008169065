import { Layout } from "antd";
// import ContactUs from "components/home/ContactUs";
import FeatureSection from "components/home/Features";
import Footer from "components/home/Footer";
import Header from "components/home/Header";
// import InvoiceSection from "components/home/Invoice";
import Ournetworks from "components/home/ournetworks";

const { Content } = Layout;

export default function Home() {
  return (
    <Layout className="layout">
      <Content>
        <Header />
        <Ournetworks />
        <div className="site-layout-content">
          {/* <InvoiceSection /> */}
          <FeatureSection />
          {/* <ContactUs /> */}
        </div>
      </Content>
      <Footer />
    </Layout>
  );
}
