import { makeFilterable } from "utils/filter"

import {
  Button,
  Card,
  Col,
  DeleteButton,
  EditButton,
  EmailField,
  Form,
  getDefaultSortOrder,
  Input,
  List,
  NumberField,
  Row,
  ShowButton,
  Space,
  Table,
  TextField,
  Typography,
  useTable,
} from "@pankod/refine-antd"
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core"

import { IEmployee, IEmployeeFilterVariables } from "../../../interfaces"

const { Title } = Typography

export const EmployeeList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { show } = useNavigation()

  const { tableProps, sorter, searchFormProps } = useTable<
    IEmployee,
    HttpError,
    IEmployeeFilterVariables
  >({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { q, email, phone, department } = params

      filters.push(
        {
          field: "q",
          operator: "eq",
          value: q,
        },
        {
          field: "email",
          operator: "eq",
          value: makeFilterable(email),
        },
        {
          field: "phone",
          operator: "eq",
          value: makeFilterable(phone),
        },
        {
          field: "department",
          operator: "eq",
          value: makeFilterable(department),
        }
      )

      return filters
    },
  })

  return (
    <List>
      <Row gutter={[12, 0]}>
        <Col span={5}>
          <Card size="small">
            <Title level={5}>Filters</Title>

            <Form {...searchFormProps}>
              <Form.Item name="q">
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item name="email">
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item name="phone">
                <Input placeholder="Phone" />
              </Form.Item>
              <Form.Item name="department">
                <Input placeholder="Department" />
              </Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                style={{ width: "100%" }}
              >
                Search
              </Button>
            </Form>
          </Card>
        </Col>
        <Col span={19}>
          <Table {...tableProps} size="small" rowKey="id">
            <Table.Column<IEmployee>
              dataIndex="name"
              key="name"
              title={t("employees.fields.name")}
              render={(value: string, record) => (
                <TextField
                  value={value}
                  onClick={() => show("employees", record.id)}
                  style={{ cursor: "pointer" }}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("name", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="email"
              key="email"
              title={t("employees.fields.email")}
              render={(value: string) => <EmailField value={value} />}
              defaultSortOrder={getDefaultSortOrder("email", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="phone"
              key="phone"
              title={t("employees.fields.phone")}
              render={(value: string) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("phone", sorter)}
              sorter
            />

            <Table.Column
              dataIndex="basic_salary"
              key="basic_salary"
              title={t("employees.fields.salary")}
              render={(value) => (
                <NumberField
                  value={value}
                  options={{
                    style: "currency",
                    currency: "NPR",
                  }}
                />
              )}
            />
            <Table.Column<IEmployee>
              title={t("table.actions")}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                  <ShowButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
    </List>
  )
}
