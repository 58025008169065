import { Avatar, Card, Col, Icons, Row, Typography } from "@pankod/refine-antd"
import { Authenticated, useRouterContext } from "@pankod/refine-core"
import { green, red, orange, blue } from "@ant-design/colors"

const { Title } = Typography

export const ReportList = () => {
  const { Link } = useRouterContext()

  const incomeExpenseReports = [
    {
      title: "Expense Summary",
      description: "Monthly expense summary by category",
      icon: <Icons.ShoppingCartOutlined style={{ color: red[5] }} />,
      path: `/reports/expense-summary`,
      bgcolor: red[1],
    },
    {
      title: "Income Summary",
      description: "Monthly income summary by category",
      icon: <Icons.DollarCircleOutlined style={{ color: green[5] }} />,
      path: `/reports/income-summary`,
    },
    {
      title: "Income vs Expense",
      description: "Monthly income vs expense by category",
      icon: <Icons.BarChartOutlined style={{ color: orange[5] }} />,
      path: `/reports/income-vs-expense`,
      bgcolor: orange[1],
    },
    // {
    //   title: "Transaction Summary",
    //   description: "Monthly transaction summary by category",
    //   icon: <Icons.FileProtectOutlined style={{ color: blue[5] }} />,
    //   path: "/reports/transaction-summary",
    //   bgcolor: blue[1],
    // },
  ]

  const accountingReports = [
    {
      title: "General Ledger",
      description: "General ledger by coa",
      icon: <Icons.FileOutlined style={{ color: green[5] }} />,
      path: `/reports/general-ledger`,
      bgcolor: green[1],
    },
    {
      title: "Profit and Loss",
      description: "Profit and loss by category",
      icon: <Icons.PercentageOutlined style={{ color: blue[5] }} />,
      path: `/reports/profit-vs-loss`,
      bgcolor: blue[1],
    },
    {
      title: "Tax Summary Report",
      description: "Detailed Tax Summary",
      icon: <Icons.ReconciliationOutlined style={{ color: red[5] }} />,
      path: `/reports/tax-summary`,
      bgcolor: red[1],
    },
  ]

  return (
    <Authenticated>
      <div style={{ margin: "0.5em", marginTop: "1em" }}>
        <section style={{ marginLeft: "1em" }}>
          <Title level={5}>Income and Expense</Title>
        </section>
        <Row gutter={[24, 16]} style={{ margin: "0" }}>
          {incomeExpenseReports.map((report) => (
            <Col xs={12} sm={6}>
              <Link to={report.path}>
                <Card size="small" hoverable>
                  <Card.Meta
                    avatar={
                      <Avatar
                        icon={report.icon}
                        size="large"
                        shape="square"
                        style={{ backgroundColor: report?.bgcolor ?? green[1] }}
                      />
                    }
                    title={report.title}
                    description={report.description}
                  />
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
        <section style={{ marginLeft: "1em", marginTop: "1em" }}>
          <Title level={5}>Accounting</Title>
        </section>
        <Row gutter={[24, 16]} style={{ margin: "0" }}>
          {accountingReports.map((report) => (
            <Col xs={12} sm={6}>
              <Link to={report.path}>
                <Card size="small" hoverable>
                  <Card.Meta
                    avatar={
                      <Avatar
                        icon={report.icon}
                        size="large"
                        shape="square"
                        style={{ backgroundColor: report?.bgcolor ?? green[1] }}
                      />
                    }
                    title={report.title}
                    description={report.description}
                  />
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </Authenticated>
  )
}
