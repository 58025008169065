import { Space, Image, Typography } from "@pankod/refine-antd";

export default function Ournetworks() {
  const { Title, Text } = Typography;

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "2rem 2rem",
      }}
      className="networkWrapper"
    >
      <div style={{ textAlign: "center", paddingBottom: "2rem" }}>
        <Space direction="vertical" size="small">
          <Title level={2} style={{ color: "#52C41A", fontWeight: "bolder" }}>
            Digital Business Solutions @ One Place!!!
          </Title>
          <Text>
            Leading with innovation, integrating advanced tech, and inspiring
            progress. We shape the future today with excellence, vision, and
            seamless solutions.
          </Text>
        </Space>
      </div>

      <div className="childNetwork">
        <a
          href="https://evolvingtechnology.com.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src="./images/networks/evolving.png" preview={false} />
          <p>All IT solutions needed for any business</p>
        </a>
        <a
          href="https://bhotahiti.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src="./images/networks/bhotahity.png" preview={false} />
          <p>Wholesale Market place (buy and sell wholesale)</p>
        </a>

        <a
          href="https://www.gadikoparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src="./images/networks/gadiko-parts.png" preview={false} />
          <p>Best options to maintain your fleet of vehicle</p>
        </a>
        <a
          href="https://rocketshippings.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src="./images/networks/rocket-shipping.png" preview={false} />
          <p>Deliver your goods all over Nepal at best price </p>
        </a>
        <a
          href="http://openjobs.services/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src="./images/networks/open-jobs.png" preview={false} />
          <p>Human resource solution (hire staff)</p>
        </a>
        <a
          href="https://alwaysdeal.store/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src="./images/networks/always-deals.png" preview={false} />
          <p>Sell retail, online</p>
        </a>
        <a
          href="https://geopasal.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src="./images/networks/geo-pasal.png" preview={false} />
          <p>
            List store and sell directly to local customer ( 45min delivery).{" "}
          </p>
        </a>
        <a
          href="https://digitalokil.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src="./images/networks/digital-skills.png" preview={false} />
          <p>AI based . Legal help</p>
        </a>
        <a
          href="https://byaparkhata.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src="./images/networks/byapar-khata.png" preview={false} />
          <p>Online free accounting solution</p>
        </a>
        <a
          href="https://udhyoghub.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src="./images/networks/udhyog-hub.png" preview={false} />
          <p>Industrial machinery , tools and raw materials</p>
        </a>
        <a
          href="https://officesupplynepal.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src="./images/networks/office-supply.png" preview={false} />
          <p>Office and Stationary supply</p>
        </a>
        <a
          href="https://byaparnews.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src="./images/networks/byapar-news.png" preview={false} />
          <p>Business new for growing business</p>
        </a>
        <a
          href="http://digiattend.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src="./images/networks/digiattend.png" preview={false} />
          <p>For online attendance</p>
        </a>
        <a
          href="http://exportkendra.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src="./images/networks/export-kendra.png" preview={false} />
          <p>For Exporting your goods, overseas</p>
        </a>
        <a
          href="http://tekutools.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src="./images/networks/teku-tools.png" preview={false} />
          <p>Get Tools as your preference</p>
        </a>
      </div>
    </div>
  );
}
