import { IUser } from "interfaces"
import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Typography,
  useModal,
} from "@pankod/refine-antd"
import { useCreate, useLogout } from "@pankod/refine-core"

interface IUserFieldProps {
  user?: IUser
}

interface IChansePassword {
  oldPassword: string
  newPassword: string
}

const { Text } = Typography

export const UserField: React.FC<IUserFieldProps> = ({ user }): JSX.Element => {
  const [form] = Form.useForm<IChansePassword>()

  const { mutate: logout } = useLogout()

  const { show, close, modalProps } = useModal()

  const { mutate: changePassword } = useCreate<IChansePassword>()

  if (!user) return <Text ellipsis>Anonymous</Text>

  const onFinish = (values: IChansePassword) => {
    changePassword({
      resource: "users/change-password",
      values: {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      },
      successNotification(_data, _values, _resource) {
        close()
        return {
          type: "success",
          message: "Successfully change password.",
        }
      },
    })
  }

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={show}>
        Change Password
      </Menu.Item>
      <Menu.Item key="2" onClick={() => logout()}>
        Logout
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <Dropdown overlay={menu}>
        <Text ellipsis style={{ cursor: "pointer" }}>
          {user.name}
        </Text>
      </Dropdown>
      <Modal
        {...modalProps}
        title="Change Password"
        footer={null}
        onCancel={close}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true }, { min: 8 }]}
          >
            <Input.Password placeholder="●●●●●●●●" size="large" />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  )
                },
              }),
            ]}
          >
            <Input.Password placeholder="●●●●●●●●" size="large" />
          </Form.Item>
          <Button type="primary" size="large" htmlType="submit" block>
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  )
}
