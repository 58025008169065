import { useTranslate } from "@pankod/refine-core"
import {
  DateField,
  List,
  NumberField,
  Table,
  TextField,
} from "@pankod/refine-antd"
import { ITransaction } from "interfaces"
import { humanize } from "utils/humanize"

export default function VendorTransaction({
  transactions,
}: {
  transactions?: ITransaction[]
}) {
  const t = useTranslate()

  return (
    <List breadcrumb={false} canCreate={false} title="">
      <Table dataSource={transactions} rowKey="id" size="small">
        {/* <Table.Column
          dataIndex="due_date"
          key="due_date"
          title={t("transactions.fields.due_date")}
          render={(value: string) => <DateField value={value} />}
        /> */}
        <Table.Column
          dataIndex="date"
          key="date"
          title={t("transactions.fields.date")}
          render={(value: string) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex="transaction_number"
          key="transaction_number"
          title={t("transactions.fields.transaction_number")}
          render={(value: string) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="category"
          key="category"
          title={t("transactions.fields.category")}
          render={(value: { name: string }) => (
            <TextField value={value ? humanize(value.name) : ""} />
          )}
        />
        <Table.Column
          dataIndex="amount"
          key="amount"
          title={t("transactions.fields.amount")}
          render={(value: string) => (
            <NumberField
              value={value}
              options={{
                style: "currency",
                currency: "NPR",
              }}
            />
          )}
        />
      </Table>
    </List>
  )
}
