import {
  Create,
  Form,
  Input,
  useStepsForm,
  Steps,
  SaveButton,
  Row,
  Col,
  DatePicker,
  Select,
} from "@pankod/refine-antd"
import { useTranslate } from "@pankod/refine-core"
import { Layout, Button } from "antd"
import Footer from "components/home/Footer"
import Header from "components/home/Header"
import dayjs from "dayjs"

const { Content } = Layout

export default function Register() {
  const {
    current,
    gotoStep,
    stepsProps,
    formProps,
    saveButtonProps,
    // queryResult,
    submit,
  } = useStepsForm<any>({
    resource: "organizations/register",
  })

  const t = useTranslate()

  const date = new Date()
  const year = date.getFullYear()

  const formList = [
    // Organization
    <Row gutter={[16, 0]}>
      <Col xs={24} sm={12}>
        <Form.Item
          label={t("registers.organization.name")}
          name={["organization", "name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Organization's Name" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label={t("registers.organization.email")}
          name={["organization", "email"]}
          rules={[{ required: true }, { type: "email" }]}
        >
          <Input placeholder={t("registers.organization.email")} />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item
          label={t("organization.fields.phone")}
          name={["organization", "phone"]}
          rules={[
            {
              required: true,
              message: "Please input organization phone!",
            },
          ]}
        >
          <Input placeholder="Enter organization phone" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          label={t("registers.organization.tax_number")}
          name={["organization", "tax_number"]}
        >
          <Input placeholder="Enter organization's tax number" />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label={t("registers.organization.address")}
          name={["organization", "address"]}
        >
          <Input.TextArea placeholder="Enter Address" />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12}>
        <Form.Item
          label={t("registers.organization.city")}
          name={["organization", "city"]}
        >
          <Input placeholder="Enter City" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label={t("registers.organization.postal_code")}
          name={["organization", "postal_code"]}
        >
          <Input placeholder="Enter Postal Code" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label={t("registers.organization.state")}
          name={["organization", "state"]}
        >
          <Input placeholder="Enter State" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label={t("registers.organization.country")}
          name={["organization", "country"]}
          rules={[{ required: true, message: "Please select an country" }]}
        >
          <Input placeholder="Enter Country" />
        </Form.Item>
      </Col>
    </Row>,

    // Localization
    <Row gutter={[16, 0]}>
      <Col xs={24} md={12}>
        <Form.Item
          label={t("localization.fields.financial_year_start_date")}
          name={["localization", "financial_year_start_date"]}
          dependencies={["localization", "financial_year_end_date"]}
          getValueProps={(value) => {
            return {
              value: value ? dayjs(value) : "",
            }
          }}
          rules={[
            {
              required: true,
              message: "Please select an financial year start date",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  dayjs(value).isBefore(
                    dayjs(
                      getFieldValue(["localization", "financial_year_end_date"])
                    )
                  )
                ) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error(
                    "Financial year start date must be before financial year end date"
                  )
                )
              },
            }),
          ]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          label={t("localization.fields.financial_year_end_date")}
          name={["localization", "financial_year_end_date"]}
          dependencies={["localization", "financial_year_start_date"]}
          getValueProps={(value) => {
            return {
              value: value ? dayjs(value) : "",
            }
          }}
          rules={[
            {
              required: true,
              message: "Please select an financial year end date",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  dayjs(value).isAfter(
                    dayjs(
                      getFieldValue([
                        "localization",
                        "financial_year_start_date",
                      ])
                    )
                  )
                ) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error(
                    "Financial year end date must be after financial year start date"
                  )
                )
              },
            }),
          ]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          label={t("localization.fields.date_format")}
          name={["localization", "date_format"]}
          rules={[
            {
              required: true,
              message: "Please select date format!",
            },
          ]}
        >
          <Select
            options={[
              { label: "November 17th 2022", value: "MMMM Do YYYY" },
              { label: "17 Nov 2022", value: "DD MMM YYYY" },
            ]}
            placeholder="Select date format"
          />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          label={t("localization.fields.timezone")}
          name={["localization", "timezone"]}
          rules={[
            {
              required: true,
              message: "Please select timezone!",
            },
          ]}
        >
          <Select
            options={[
              { label: "America/New_York", value: "America/New_York" },
              {
                label: "America/Los_Angeles",
                value: "America/Los_Angeles",
              },
              { label: "Asia/Kathmandu", value: "Asia/Kathmandu" },
            ]}
            placeholder="Select timezone"
          />
        </Form.Item>
      </Col>
    </Row>,

    // User
    <Row gutter={[16, 0]}>
      <Col xs={24} md={12}>
        <Form.Item
          label={t("users.fields.name")}
          name={["user", "name"]}
          rules={[{ required: true }]}
        >
          <Input placeholder="User's Name" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          label={t("users.fields.phone")}
          name={["user", "phone"]}
          rules={[{ required: true }]}
        >
          <Input placeholder="User's Phone" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          label={t("users.fields.email")}
          name={["user", "email"]}
          rules={[{ required: true }, { type: "email" }]}
        >
          <Input placeholder="User's Email" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          label={t("users.fields.password")}
          name={["user", "password"]}
          rules={[
            { required: true },
            {
              min: 8,
              message: "Password must be at least 8 characters long",
            },
          ]}
        >
          <Input.Password placeholder="User's Password" />
        </Form.Item>
      </Col>
    </Row>,
  ]

  return (
    <Layout className="layout">
      <Content style={{ padding: "0 50px" }}>
        <Header />
        <div className="register-layout-content">
          <Row justify="center">
            {/* <Col xs={0} sm={6} md={8}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <Image src="./images/Accounting-Services.jpg" />
                </div>
              </div>
            </Col> */}
            <Col xs={24} sm={18} md={16}>
              <Create
                footerButtons={
                  <>
                    {current > 0 && (
                      <Button
                        onClick={() => {
                          gotoStep(current - 1)
                        }}
                      >
                        Previous
                      </Button>
                    )}
                    {current < formList.length - 1 && (
                      <Button
                        onClick={() => {
                          gotoStep(current + 1)
                        }}
                      >
                        Next
                      </Button>
                    )}
                    {current === formList.length - 1 && (
                      <SaveButton
                        {...saveButtonProps}
                        style={{ marginRight: 10 }}
                        onClick={() => submit()}
                      />
                    )}
                  </>
                }
                saveButtonProps={saveButtonProps}
                title=""
              >
                <Row justify="center">
                  <Col xs={24} sm={20} md={16} lg={14}>
                    <Steps {...stepsProps}>
                      <Steps.Step title="Organization" />
                      <Steps.Step title="Localization" />
                      <Steps.Step title="Admin User" />
                    </Steps>
                    <Form
                      {...formProps}
                      layout="vertical"
                      style={{ margin: "1em 0" }}
                    >
                      {formList[current]}
                    </Form>
                  </Col>
                </Row>
              </Create>
            </Col>
          </Row>
        </div>
      </Content>
      <Footer />
    </Layout>
  )
}
