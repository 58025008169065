import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core"
import {
  Create,
  Form,
  Input,
  Select,
  useSelect,
  useForm,
  Typography,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Table,
  Button,
  Icons,
  Tooltip,
  Space,
  Divider,
  Card,
} from "@pankod/refine-antd"
import { red, blue, orange } from "@ant-design/colors"
import dayjs from "dayjs"

import "react-mde/lib/styles/css/react-mde-all.css"

import {
  IInvoice,
  ICategory,
  IItem,
  IInvoiceItem,
  ITax,
  ICustomer,
  ICoa,
} from "interfaces"
import { calcDiscount, calcTax } from "utils/calculator"
import { ItemSearch } from "./item_search"

const { Title, Text } = Typography

export const PosCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<IInvoice>()

  const { selectProps: itemSelectProps, queryResult: itemQueryResult } =
    useSelect<IItem>({
      resource: "items",
      optionLabel: "name",
      optionValue: "id",
      onSearch: (value) => [
        {
          field: "q",
          operator: "eq",
          value,
        },
      ],
    })

  const { selectProps: taxSelectProps, queryResult: taxQueryResult } =
    useSelect<ITax>({
      resource: "tax",
      optionLabel: "name",
      optionValue: "id",
    })

  const { queryResult: categoriesQueryResult } = useSelect<ICategory>({
    resource: "categories",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "type",
        operator: "eq",
        value: "income",
      },
    ],
  })

  const selectAfter = (name: string | (string | number)[]) => (
    <Form.Item name={name} noStyle>
      <Select style={{ width: 60 }}>
        <Select.Option value="percentage">%</Select.Option>
        <Select.Option value="flat">NRS</Select.Option>
      </Select>
    </Form.Item>
  )

  const handleSearch = (itemData: IItem | undefined) => {
    if (!itemData) return

    const items = formProps?.form?.getFieldValue("items") ?? []

    const tax_rate = itemData?.sales_tax_rate ?? 0
    const item_total = itemData?.sales_price! * 1
    const tax = calcTax(tax_rate, item_total)
    const item_total_inclusive_of_tax = item_total + tax

    items.push({
      ...itemData,
      item_id: itemData?.id,
      item_order: items?.length + 1,
      account_id: itemData?.sales_account_id,
      rate: itemData?.sales_price,
      item_unit: itemData?.unit,
      tax_id: itemData?.sales_tax_id,
      tax_name: itemData?.sales_tax_name,
      tax_rate,
      quantity: 1,
      tax,
      discount: 0,
      discount_setting: "percentage",
      discount_value: 0,
      item_total,
      item_total_with_discount: item_total,
      item_total_tax: tax,
      item_total_inclusive_of_tax,
    })

    const sub_total = items?.reduce(
      (acc: number, item: IInvoiceItem) => acc + item?.item_total_with_discount,
      0
    )
    formProps?.form?.setFieldsValue({
      items,
      sub_total,
    })
  }

  const handleItemIdChange = (value: string) => {
    const itemData = itemQueryResult?.data?.data.find(
      (item) => item.id === value
    )
    const items = formProps?.form?.getFieldsValue(["items"])

    const updatedItems = items.items.map((item: IInvoiceItem, idx: number) => {
      if (item.item_id === value) {
        const tax_rate = itemData?.sales_tax_rate ?? 0
        const item_total = itemData?.sales_price! * 1
        const tax = calcTax(tax_rate, item_total)
        const item_total_inclusive_of_tax = item_total + tax
        return {
          ...item,
          item_id: itemData?.id,
          item_order: idx,
          account_id: itemData?.sales_account_id,
          rate: itemData?.sales_price,
          item_unit: itemData?.unit,
          tax_id: itemData?.sales_tax_id,
          tax_name: itemData?.sales_tax_name,
          tax_rate,
          tax,
          discount: 0,
          discount_setting: "percentage",
          discount_value: 0,
          item_total,
          item_total_with_discount: item_total,
          item_total_tax: tax,
          item_total_inclusive_of_tax,
        }
      }
      return item
    })
    const sub_total = updatedItems.reduce(
      (acc: number, item: IInvoiceItem) => acc + item.item_total_with_discount,
      0
    )
    formProps?.form?.setFieldsValue({
      items: updatedItems,
      sub_total,
    })
  }

  const handleItemQuantityChange = (value: string, index: number) => {
    const { items } = formProps?.form?.getFieldsValue(["items"])
    const updatedItems = items.map((item: IInvoiceItem, idx: number) => {
      if (index === idx) {
        const discountSetting = item.discount_setting
        const discount = item?.discount ?? 0
        const item_total = Number(value) * item.rate
        const tax_rate = item.tax_rate ?? 0
        const discount_value = calcDiscount(
          item_total,
          discountSetting,
          discount
        )
        const item_total_with_discount = item_total - discount_value

        const item_total_tax = calcTax(tax_rate, item_total_with_discount)

        const item_total_inclusive_of_tax =
          item_total_with_discount + item_total_tax

        return {
          ...item,
          item_total,
          discount_value,
          item_total_with_discount,
          item_total_tax,
          item_total_inclusive_of_tax,
        }
      }
      return item
    })
    formProps?.form?.setFieldsValue({
      items: updatedItems,
    })
  }

  const handleItemRateChange = (value: string, index: number) => {
    const { items } = formProps?.form?.getFieldsValue(["items"])
    const updatedItems = items.map((item: IInvoiceItem, idx: number) => {
      if (index === idx) {
        const discountSetting =
          formProps?.form?.getFieldValue("discount_setting") ?? "percentage"
        const discount = formProps?.form?.getFieldValue("discount") ?? 0
        const item_total = Number(value) * item.quantity
        const tax_rate = item.tax_rate ?? 0
        const discount_value = calcDiscount(
          item_total,
          discountSetting,
          discount
        )
        const item_total_with_discount = item_total - discount_value

        const item_total_tax = calcTax(tax_rate, item_total_with_discount)

        const item_total_inclusive_of_tax =
          item_total_with_discount + item_total_tax

        return {
          ...item,
          item_total,
          discount: discount_value,
          item_total_with_discount,
          item_total_tax,
          item_total_inclusive_of_tax,
        }
      }
      return item
    })
    const sub_total = updatedItems.reduce(
      (acc: number, item: IInvoiceItem) => acc + item.item_total_with_discount,
      0
    )
    formProps?.form?.setFieldsValue({
      items: updatedItems,
      sub_total,
    })
  }

  const handleItemTaxIdChange = (value: string, index: number) => {
    const taxData = taxQueryResult?.data?.data.find((tax) => tax.id === value)
    const items = formProps?.form?.getFieldsValue(["items"])
    const updatedItems = items.items.map((item: IInvoiceItem, idx: number) => {
      if (index === idx) {
        if (!value) {
          return {
            ...item,
            tax_id: null,
            tax_rate: 0,
            tax_name: null,
            item_total_inclusive_of_tax: item.item_total_with_discount,
          }
        } else {
          const tax_rate = taxData?.rate ?? 0
          const tax = calcTax(tax_rate, item.item_total_with_discount)
          const item_total_inclusive_of_tax =
            item.item_total_with_discount + tax
          return {
            ...item,
            tax_id: taxData?.id,
            tax_name: taxData?.name,
            tax_rate: taxData?.rate,
            item_total_inclusive_of_tax,
          }
        }
      }
      return item
    })
    const sub_total = updatedItems.reduce(
      (acc: number, item: IInvoiceItem) => acc + item.item_total_with_discount,
      0
    )
    formProps?.form?.setFieldsValue({
      items: updatedItems,
      sub_total,
    })
  }

  const handleItemDiscountChange = (value: string, index: number) => {
    const { items } = formProps?.form?.getFieldsValue(["items"])
    const updatedItems = items.map((item: IInvoiceItem, idx: number) => {
      if (index === idx) {
        const discountSetting = item?.discount_setting ?? "percentage"
        const discount_value = calcDiscount(
          item.item_total,
          discountSetting,
          Number(value)
        )
        const item_total_with_discount = item.item_total - discount_value
        const item_total_tax = calcTax(item.tax_rate, item_total_with_discount)
        const item_total_inclusive_of_tax =
          Number((item_total_with_discount + item_total_tax).toFixed(2)) ?? 0
        return {
          ...item,
          discount_value,
          item_total_with_discount,
          item_total_tax,
          item_total_inclusive_of_tax,
        }
      }
      return item
    })
    const sub_total = updatedItems.reduce(
      (acc: number, item: IInvoiceItem) => acc + item.item_total_with_discount,
      0
    )
    formProps?.form?.setFieldsValue({
      items: updatedItems,
      sub_total,
    })
  }

  const _taxTotal = (items: IInvoiceItem[]): number => {
    const taxTotal = items.reduce((acc: number, curr: IInvoiceItem) => {
      return acc + (curr?.item_total_tax ?? 0)
    }, 0)
    return taxTotal
  }

  // Payment
  const { selectProps: paidToSelectProps, queryResult: paidToQueryResult } =
    useSelect<ICoa>({
      resource: "accounts",
      optionLabel: "name",
      optionValue: "id",
    })

  const handlePaymentThroughChange = (value: string) => {
    const account = paidToQueryResult?.data?.data.find(
      (c) => c.id === value?.toString()
    )
    if (account) {
      // formProps?.form?.setFieldsValue({
      //   "payment.paid_to_account_name": account.name,
      // })

      formProps?.form?.setFieldsValue({
        payment: {
          ...formProps?.form?.getFieldValue("payment"),
          paid_to_account_name: account.name,
        },
      })
    }
  }

  const { selectProps: customerSelectProps, queryResult: customerQueryResult } =
    useSelect<ICustomer>({
      resource: "customers",
      optionLabel: "name",
      optionValue: "id",
      onSearch: (value) => [
        {
          field: "q",
          operator: "eq",
          value,
        },
      ],
    })

  const handleCustomerChange = async (value: string) => {
    const customer = customerQueryResult?.data?.data.find((v) => v.id === value)
    if (customer) {
      formProps?.form?.setFieldsValue({
        customer_name: customer.name,
        customer_phone: customer.phone,
        customer_email: customer.email,
      })
    }
  }

  return (
    <Create saveButtonProps={saveButtonProps} breadcrumb={false} title="POS">
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          payment: {
            payment_date: dayjs().format("YYYY-MM-DD"),
            payment_method: "cash",
            note: "Thanks for your business.",
          },
          invoice_date: dayjs().format("YYYY-MM-DD"),
          invoice_number: `INV-${new Date().getTime()}`,
          order_number: `ORD-${new Date().getTime()}`,
          discount_setting: "percentage",
          category_id: categoriesQueryResult?.data?.data.find(
            (cat) => cat.access_name === "sale"
          )?.id,
          shipping_charge: 0,
          adjustment: 0,
          note: "Thanks for your business.",
        }}
        size="small"
      >
        <Row gutter={[24, 24]}>
          <Form.Item
            label={t("invoices.fields.invoice_date")}
            name="invoice_date"
            getValueProps={(value) => {
              return {
                value: value ? dayjs(value) : "",
              }
            }}
            rules={[{ required: true }]}
            hidden
          >
            <DatePicker style={{ width: "100%" }} size="small" />
          </Form.Item>
          <Form.Item
            label={t("invoices.fields.invoice_number")}
            name="invoice_number"
            rules={[{ required: true }]}
            hidden
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("invoices.fields.order_number")}
            name="order_number"
            rules={[{ required: true }]}
            hidden
          >
            <Input />
          </Form.Item>
          <Col xs={24} sm={6}>
            <Card>
              <Title level={5}>Payment</Title>
              <Form.Item
                label={t("invoices.fields.payment_date")}
                name={["payment", "payment_date"]}
                getValueProps={(value) => {
                  return {
                    value: value ? dayjs(value) : "",
                  }
                }}
                rules={[{ required: true }]}
              >
                <DatePicker style={{ width: "100%" }} size="small" />
              </Form.Item>
              <Form.Item
                label={t("invoices.fields.payment_method")}
                name={["payment", "payment_method"]}
                rules={[{ required: true }]}
              >
                <Select
                  options={[
                    { value: "bank_transfer", label: "Bank Transfer" },
                    { value: "cash", label: "Cash" },
                    { value: "cheque", label: "Cheque" },
                    { value: "credit", label: "Credit" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={t("invoices.fields.payment_to_account_name")}
                name={["payment", "paid_to_account_id"]}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  allowClear
                  {...paidToSelectProps}
                  onChange={(value) => {
                    handlePaymentThroughChange(value?.toString())
                  }}
                  placeholder="Paid to Account"
                />
              </Form.Item>
              <Form.Item name={["payment", "paid_to_account_name"]} hidden>
                <Input />
              </Form.Item>
              <Divider />
              <Form.Item
                label={t("invoices.fields.customer_name")}
                name="customer_id"
                rules={[
                  {
                    required: true,
                    message: t("invoices.errors.customer_name"),
                  },
                ]}
              >
                <Select
                  {...customerSelectProps}
                  showSearch
                  allowClear
                  onChange={(value) => {
                    handleCustomerChange(value?.toString())
                  }}
                  placeholder="Customer name/phone/email"
                />
              </Form.Item>
              <Form.Item name="customer_name" hidden>
                <Input />
              </Form.Item>
              <Text>{formProps?.form?.getFieldValue("customer_name")}</Text>
            </Card>
          </Col>
          <Col xs={24} sm={18}>
            <Card>
              <Title level={5}>Invoice</Title>
              <ItemSearch onItemOk={handleSearch} />
              <Form.List name="items">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      <Table
                        scroll={{ x: 100 }}
                        bordered
                        size="small"
                        columns={[
                          {
                            title: "S.N.",
                            dataIndex: "sn",
                            key: "sn",
                            width: "5%",
                            render: (_text, _record, index) => index + 1,
                          },
                          {
                            title: "Item/Tax",
                            dataIndex: "item_id",
                            key: "item_id",
                            width: "25%",
                            render: (_text, _record, index) => (
                              <>
                                <Space size="small" direction="vertical">
                                  <Form.Item
                                    name={[index, "item_id"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Item is required.",
                                      },
                                    ]}
                                    noStyle
                                  >
                                    <Select
                                      showSearch
                                      allowClear
                                      {...itemSelectProps}
                                      placeholder="Item or service name"
                                      style={{ minWidth: 320 }}
                                      onChange={(value) => {
                                        handleItemIdChange(value?.toString())
                                      }}
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    name={[index, "tax_id"]}
                                    // rules={[{ required: true, message: "Tax is required." }]}
                                    noStyle
                                  >
                                    <Select
                                      showSearch
                                      allowClear
                                      {...taxSelectProps}
                                      placeholder="Tax"
                                      style={{ width: "100%" }}
                                      onChange={(value) => {
                                        handleItemTaxIdChange(
                                          value?.toString(),
                                          index
                                        )
                                      }}
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    name={[index, "description"]}
                                    noStyle
                                    hidden
                                  >
                                    <Input.TextArea placeholder="Description" />
                                  </Form.Item>
                                </Space>
                              </>
                            ),
                          },
                          {
                            title: t("invoices.fields.item_quantity"),
                            dataIndex: "quantity",
                            key: "quantity",
                            width: "8%",
                            render: (_text, _record, index) => (
                              <Form.Item
                                name={[index, "quantity"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Quantity is required",
                                  },
                                ]}
                                noStyle
                              >
                                <InputNumber
                                  placeholder="Qty."
                                  // style={{ width: "100%" }}
                                  onChange={(value) => {
                                    handleItemQuantityChange(
                                      value?.toString(),
                                      index
                                    )
                                  }}
                                />
                              </Form.Item>
                            ),
                          },
                          {
                            title: t("invoices.fields.item_unit_price"),
                            dataIndex: "rate",
                            key: "rate",
                            width: "15%",
                            render: (_text, _record, index) => (
                              <Form.Item
                                name={[index, "rate"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Unit price is required",
                                  },
                                ]}
                                shouldUpdate={(prevValues, currentValues) => {
                                  return (
                                    prevValues.items[index]?.item_id !==
                                    currentValues.items[index]?.item_id
                                  )
                                }}
                                noStyle
                              >
                                <InputNumber
                                  // addonBefore="NRS"
                                  placeholder="Unit Price"
                                  onChange={(value) => {
                                    handleItemRateChange(
                                      value?.toString(),
                                      index
                                    )
                                  }}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            ),
                          },
                          {
                            title: t("invoices.fields.discount"),
                            dataIndex: "discount",
                            key: "discount",
                            width: "19%",
                            render: (_text, _record, index) => (
                              <Form.Item
                                name={[index, "discount"]}
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => {
                                  return (
                                    prevValues.items[index]?.item_id !==
                                    currentValues.items[index]?.item_id
                                  )
                                }}
                                rules={[
                                  {
                                    min: 0,
                                  },
                                ]}
                              >
                                <InputNumber
                                  placeholder="Discount"
                                  addonAfter={selectAfter([
                                    index,
                                    "discount_setting",
                                  ])}
                                  onChange={(value) => {
                                    handleItemDiscountChange(
                                      value?.toString(),
                                      index
                                    )
                                  }}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            ),
                          },
                          // {
                          //   title: t("invoices.fields.item_tax"),
                          //   dataIndex: "tax_id",
                          //   key: "tax_id",
                          //   width: "40%",
                          //   render: (_text, _record, index) => (
                          //     <Form.Item
                          //       name={[index, "tax_id"]}
                          //       // rules={[{ required: true, message: "Tax is required." }]}
                          //       noStyle
                          //     >
                          //       <Select
                          //         showSearch
                          //         allowClear
                          //         {...taxSelectProps}
                          //         placeholder="Tax"
                          //         style={{ width: 150 }}
                          //         onChange={(value) => {
                          //           handleItemTaxIdChange(
                          //             value?.toString(),
                          //             index
                          //           )
                          //         }}
                          //       />
                          //     </Form.Item>
                          //   ),
                          // },
                          {
                            title: t("invoices.fields.amount"),
                            dataIndex: "item_total_with_discount",
                            key: "item_total_with_discount",
                            width: "5%",
                          },
                          {
                            title: "",
                            dataIndex: "",
                            key: "action",
                            width: "4%",
                            render: (_text, _record, index) => (
                              <Tooltip
                                title={t("invoices.fields.remove_item")}
                                color={red[4]}
                              >
                                <Icons.MinusCircleOutlined
                                  style={{ fontSize: 16, color: red[5] }}
                                  onClick={() => remove(index)}
                                />
                              </Tooltip>
                            ),
                          },
                        ]}
                        dataSource={
                          formProps?.form?.getFieldValue("items") || []
                        }
                        pagination={false}
                        rowKey="id"
                      />
                      <Form.Item style={{ marginTop: "0.4rem" }}>
                        <Button
                          type="primary"
                          style={{
                            backgroundColor: "#f7f7f7",
                            color: "#000",
                          }}
                          onClick={() => {
                            add({
                              name: "",
                              quantity: 1,
                              rate: 0,
                              item_total: 0,
                            })
                          }}
                        >
                          <Icons.PlusCircleFilled
                            style={{ color: blue[5], fontSize: 17 }}
                          />{" "}
                          {t("invoices.fields.add_item")}
                        </Button>
                      </Form.Item>
                    </div>
                  )
                }}
              </Form.List>
            </Card>
            {/* <Card>

            </Card> */}

            <Row gutter={[64, 0]} justify="end" style={{ marginTop: "1rem" }}>
              <Col xs={24} md={16} lg={14}>
                <Card bodyStyle={{ backgroundColor: orange[1] }}>
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues?.items?.length !== curValues?.items?.length
                    }
                    noStyle
                  >
                    {({ getFieldValue }) => {
                      const items = getFieldValue("items") || []
                      const subTotal: number = items.reduce(
                        (acc: number, curr: IInvoiceItem) => {
                          return acc + (curr.item_total_with_discount ?? 0)
                        },
                        0
                      )
                      return (
                        <Row>
                          <Col xs={12}>
                            <Title level={5}>Sub total</Title>
                          </Col>
                          <Col xs={12} style={{ textAlign: "right" }}>
                            <Text>NRS. {subTotal.toFixed(2)}</Text>
                          </Col>
                        </Row>
                      )
                    }}
                  </Form.Item>

                  <Row>
                    <Col xs={8}>
                      <Title level={5}>
                        {t("invoices.fields.shipping_charge")}
                      </Title>
                    </Col>
                    <Col xs={8}>
                      <Form.Item
                        name="shipping_charge"
                        noStyle
                        style={{ width: "200px" }}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          placeholder="Shipping Charge"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={8} style={{ textAlign: "right" }}>
                      <Form.Item
                        shouldUpdate={(prevValues, currValues) =>
                          prevValues?.shipping_charge !==
                          currValues?.shipping_charge
                        }
                        noStyle
                      >
                        {({ getFieldValue }) => {
                          const shippingCharge =
                            getFieldValue("shipping_charge") || 0
                          return <Text>NRS. {shippingCharge?.toFixed(2)}</Text>
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <Title level={5}>{t("invoices.fields.adjustment")}</Title>
                    </Col>
                    <Col xs={8}>
                      <Form.Item
                        name="adjustment"
                        noStyle
                        style={{ width: "200px" }}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          placeholder="Adjustment"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={8} style={{ textAlign: "right" }}>
                      <Form.Item
                        shouldUpdate={(prevValues, currValues) =>
                          prevValues?.adjustment !== currValues?.adjustment
                        }
                        noStyle
                      >
                        {({ getFieldValue }) => {
                          const adjustment = getFieldValue("adjustment") || 0
                          return <Text>NRS. {adjustment?.toFixed(2)}</Text>
                        }}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues?.items?.length !== curValues?.items?.length
                    }
                    noStyle
                  >
                    {({ getFieldValue }) => {
                      const items = getFieldValue("items") || []
                      const taxTotal: number = _taxTotal(items)
                      return (
                        <Row>
                          <Col xs={12}>
                            <Title level={5}>Tax Total:</Title>
                          </Col>
                          <Col xs={12} style={{ textAlign: "right" }}>
                            <Title level={5}>NRS. {taxTotal.toFixed(2)}</Title>
                          </Col>
                        </Row>
                      )
                    }}
                  </Form.Item>

                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues?.items?.length !== curValues?.items?.length ||
                      prevValues.shipping_charge !==
                        curValues.shipping_charge ||
                      prevValues.adjustment !== curValues.adjustment
                    }
                    noStyle
                  >
                    {({ getFieldValue }) => {
                      const items = getFieldValue("items") || []
                      let total = items.reduce(
                        (acc: number, curr: IInvoiceItem) => {
                          return acc + (curr.item_total_inclusive_of_tax ?? 0)
                        },
                        0
                      )
                      const shippingCharge = getFieldValue("shipping_charge")
                      const adjustment = getFieldValue("adjustment")
                      total += shippingCharge ?? 0
                      total += adjustment ?? 0
                      return (
                        <Row>
                          <Col xs={12}>
                            <Title level={4}>Total:</Title>
                          </Col>
                          <Col xs={12} style={{ textAlign: "right" }}>
                            <Form.Item
                              name="sub_total_inclusive_of_tax"
                              noStyle
                            >
                              <Title level={4}>NRS. {total.toFixed(2)}</Title>
                            </Form.Item>
                          </Col>
                        </Row>
                      )
                    }}
                  </Form.Item>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Create>
  )
}
