import { useState } from "react"
import {
  IResourceComponentsProps,
  useMany,
  useTranslate,
} from "@pankod/refine-core"
import {
  Checkbox,
  Col,
  Edit,
  Form,
  Input,
  Row,
  useForm,
} from "@pankod/refine-antd"
import ReactMarkdown from "react-markdown"
import ReactMde from "react-mde"

import "react-mde/lib/styles/css/react-mde-all.css"
import { IRole } from "interfaces"

export const RoleEdit: React.FC<IResourceComponentsProps> = () => {
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write")

  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<IRole>({
    successNotification: {
      message: "Role updated successfully",
      description: "Successful",
      type: "success",
    },
  })

  const { data: permissions } = useMany<any>({
    resource: "admin/permissions/all",
    ids: [],
  })

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 0]}>
          <Col md={12}>
            <Row gutter={[16, 0]}>
              <Col md={12}>
                <Form.Item
                  label={t("roles.fields.name")}
                  name="name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={t("roles.fields.code")}
                  name="code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label={t("roles.fields.description")} name="description">
              <ReactMde
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                }
                maxEditorHeight={60}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={20}>
            <Form.Item
              // label={t("roles.fields.permissions")}
              // name="permissions"
              // valuePropName="checked"
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.permissions?.length !==
                currentValues.permissions?.length
              }
            >
              {({ getFieldValue, setFieldsValue }) => {
                return (
                  <>
                    <Form.Item
                      label={t("roles.fields.permissions")}
                      name="permissions"
                    >
                      <Checkbox.Group style={{ width: "100%" }}>
                        {permissions?.data.map((permission: any) => (
                          <Row>
                            {permission.options.map((option: any) => (
                              <Col span={4}>
                                <Checkbox
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                        ))}
                      </Checkbox.Group>
                    </Form.Item>
                  </>
                )
              }}
              {/* <Checkbox.Group {...checkboxGroupProps} /> */}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  )
}
